import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

/**
 * Chevron Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function ChevronIcon({
  isLeft, isUp, isDown, isRight, ...props
}) {
  let path = 'M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z'

  if (isLeft) {
    path = 'M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z'
  } else if (isUp) {
    path = 'M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z'
  } else if (isDown) {
    path = 'M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z'
  }

  return (
    <Icon icon="chevron" {...props}>
      <g>
        <path d={path} />
      </g>
    </Icon>
  )
}

ChevronIcon.defaultProps = {
  isLeft: false,
  isUp: false,
  isDown: false,
  isRight: false,
}

ChevronIcon.propTypes = {
  /** Point chevron to the left. */
  isLeft: PropTypes.bool,
  /** Point chevron up. */
  isUp: PropTypes.bool,
  /** Point chevron down. */
  isDown: PropTypes.bool,
  /** Point chevron to the right (default). */
  isRight: PropTypes.bool,
}

export default ChevronIcon
