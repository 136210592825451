import React from 'react'
import { Button, Icon, Level } from '@deseretbook/react-ui'
import propTypes from 'prop-types'
import { shareOnFacebook, shareOnPinterest, shareOnEmail } from '../../../utils/media'

/**
 * Social media sharing links.
 *
 * @param {object} media - Media object
 * @returns {function} React Component
 */
const SocialLinks = ({ media }) => (
  <Level>
    <Level.Left>
      <Level.Item>
        <Button
          type="button"
          aria-label="Share media on Facebook"
          onClick={() => shareOnFacebook(media.audiobook.productUrl)}
        >
          <Icon name="facebook" />
        </Button>
      </Level.Item>
      <Level.Item>
        <Button
          type="button"
          aria-label="Share media with Email"
          onClick={() =>
            shareOnEmail({
              title: media.title,
              url: media.audiobook.productUrl,
            })
          }
        >
          <Icon name="email" />
        </Button>
      </Level.Item>
      <Level.Item>
        <Button
          type="button"
          aria-label="Share media on Pinterest"
          onClick={() =>
            shareOnPinterest({
              pageUrl: media.audiobook.productUrl,
              imageUrl: media.cover.fullUrl,
              quote: `I found ${media.title} on DeseretBook. Go check it out!`,
            })
          }
        >
          <Icon name="pinterest" />
        </Button>
      </Level.Item>
    </Level.Left>
  </Level>
)

SocialLinks.defaultProps = {
  media: {},
}

SocialLinks.propTypes = {
  media: propTypes.shape({
    title: propTypes.string,
    audiobook: propTypes.shape({
      productUrl: propTypes.string,
    }),
    cover: propTypes.shape({
      fullUrl: propTypes.string,
    }),
  }),
}

export default SocialLinks
