import { getAudiobookManifest as sdkGetAudiobookManifest } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Retrieves a manifest of chapters and audio files for the given audiobook owned
 * by the logged in user.
 *
 * @example
 * getAudiobookManifest({ id, sku })
 *   .then(manifest => console.log(manifest))
 *
 * @see https://deseretbookwiki.atlassian.net/wiki/spaces/DB/pages/33030/Deseret+Bookshelf+API+Reference#DeseretBookshelfAPIReference-Getfilemanifestforanaudiobookownedbytheuser
 *
 * @param {object} params - audiobook manifest request params
 * @param {string} params.id - media id
 * @param {string} params.sku - audiobook sku
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getAudiobookManifest(params = {}) {
  return sdkGetAudiobookManifest(params, requestOptions())
}
