import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/layout/section/
 * @todo add documentation
 */
const Section = glamorous.section('section', propsToHelperClasses)

export default Section
