import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

import './Columns.css'


/**
 * Columns infrastructure.
 * @see https://bulma.io/documentation/columns/basics/
 */
const Columns = glamorous.div('columns', propsToHelperClasses)

Columns.Column = glamorous.div('column', propsToHelperClasses)

export default Columns
