/**
 * Singleton class that manages injecting of the GTM script into the head, as well
 * as provies helpers for adding data to the dataLayer.
 */
class TagManager {
  /**
   * Registers GTM in the app by adding the GTM script to the head.
   *
   * @param {object} options - Default options
   * @param {string} options.id - GTM id
   * @param {window} [options.contextWindow=window] - Window object where script is loaded.
   * @param {document} [options.contextDocument=document] - Document object where script is loaded.
   */
  register({
    id,
    contextWindow = window,
    contextDocument = document,
  }) {
    this.id = id
    this.contextWindow = contextWindow
    this.contextDocument = contextDocument

    // Add the dataLayer to the window.
    this.contextWindow.dataLayer = this.contextWindow.dataLayer || []
    this.contextWindow.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })

    // Inject the GTM script into the context document
    const firstScript = this.contextDocument.getElementsByTagName('script')[0]
    const gtmScript = this.contextDocument.createElement('script')

    gtmScript.async = true
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${id}`
    firstScript.parentNode.insertBefore(gtmScript, firstScript)
  }
}

export default new TagManager()
