import React from 'react'
import propTypes from 'prop-types'
import { reportUsage } from '../../../api/media'
import { minutesToMilliseconds, throttle } from '../../../utils/base'

/**
 * UsageTracker is a helper class that measures how much time a user has spent
 * consuming a particular product from the media library.
 *
 * @todo Here are some ways we could improve accuracy:
 * - Attempt to watch for closing window/tab and track usage. Most browsers won't
 * send requests at this point though, but it looks like there might be other
 * solutions.
 * - Attempt to watch for hidden/inactive tabs/windows, but it is possible to have
 * the media be in a separate window that is inactive while still consuming the
 * media.
 */
class UsageTracker extends React.Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    // An additional context document for tracking usage inside an iframe.
    this.contextDocument = null
    // If the media has been reported as being opened in this block of tracking.
    this.hasReportedOpen = false
    // If tracking is currently paused.
    this.isPaused = false
    // If the `UsageTracker` component has been un-mounted.
    this.isUnmounted = false
    // Timeout for tracking the last activity performed by the user.
    this.lastActivityTimer = null
    // Timeout for tracking when the last time useage was reported to the server.
    this.currentUsageTimeout = null
    // Start time of current usage tracking block.
    this.durationBeginTime = null
    // Total usage time that hasn't been reported yet.
    this.totalTime = 0

    this.initializeCurrentUsageReporting = this.initializeCurrentUsageReporting.bind(this)
    this.modifyEventListeners = this.modifyEventListeners.bind(this)
    this.resetInactivityTimeout = this.resetInactivityTimeout.bind(this)
    this.handleReportUsage = this.handleReportUsage.bind(this)
    this.updateLastActivity = throttle(this.updateLastActivity.bind(this), 5000)
    this.inactivateReporter = this.inactivateReporter.bind(this)
    this.startStatistics = this.startStatistics.bind(this)
    this.pauseStatistics = this.pauseStatistics.bind(this)
    this.stopStatistics = this.stopStatistics.bind(this)
  }

  /**
   * Initializes reporting once the component is setup.
   */
  componentDidMount() {
    // As long as the component is active, check periodically to report usage.
    this.initializeCurrentUsageReporting()
  }

  /**
   * When the component updates we need to decide why and what we should do about reporting
   * statistics.
   */
  async componentDidUpdate({ mediaData }) {
    if (
      mediaData.id !== this.props.mediaData.id
      || mediaData.sku !== this.props.mediaData.sku
    ) {
      // If the media has changed, then we need to stop reporting the old one
      // and begin reporting the new one.
      await this.stopStatistics()

      this.hasReportedOpen = false

      /**
       * if the component is not set to start on mount, and the id and sku were null
       * in the previous render, then this is the first time the component has received the data.
       * Therefore, the statistics should not be started. This places responsibility
       * for starting statistics on the child component
       */
      if (!mediaData.id && !mediaData.sku && !this.props.startOnMount) {
        return
      }
      // @todo we should probably have a prop that automatically starts tracking
      // after the media has changed, this might be an unexpected behavior for
      // audio based media.
      this.startStatistics()
    }
  }

  /**
   * Cleanup before component is removed. Reports any last minute statistics.
   */
  async componentWillUnmount() {
    // Once we've stopped recording statistics, we have to set a variable saying
    // the component has unmounted and no more reporting can happen. We have to
    // do this because for some reason random events are still getting through.
    // I believe it might have something to do with trying to throttle the `mousemove`
    // event.
    await this.stopStatistics()
    this.isUnmounted = true
  }

  /**
   * Begins a repeated cycle of intermitent usage reporting as long as the component
   * is mounted. Basically every n (ms) the server is informed of what usage has
   * happened in that period of time. The time between reports can be specified with
   * the `reportInterval` prop.
   */
  initializeCurrentUsageReporting() {
    clearInterval(this.currentUsageTimeout)
    this.currentUsageTimeout = setInterval(() => {
      this.handleReportUsage()
    }, this.props.reportInterval)
  }

  /**
   * modifyEventListeners will add or remove event listeners provided
   * @param {boolean} [addEventListeners=true] - If event listeners should be added.
   */
  modifyEventListeners(addEventListeners = true) {
    if (this.props.isManual) return

    // Add/Remove the event listeners from the context document if there is one.
    if (this.contextDocument) {
      if (addEventListeners) {
        this.contextDocument.addEventListener(
          'mousemove',
          this.updateLastActivity,
          false,
        )
        this.contextDocument.addEventListener(
          'onscroll',
          this.updateLastActivity,
          false,
        )
        this.contextDocument.addEventListener(
          'onkeypress',
          this.updateLastActivity,
          false,
        )
      } else {
        this.contextDocument.removeEventListener(
          'mousemove',
          this.updateLastActivity,
        )
        this.contextDocument.removeEventListener(
          'onscroll',
          this.updateLastActivity,
        )
        this.contextDocument.removeEventListener(
          'onkeypress',
          this.updateLastActivity,
        )
      }
    }

    // Add/Remove the event listeners from the parent document.
    if (addEventListeners) {
      document.addEventListener('mousemove', this.updateLastActivity, false)
      document.addEventListener('onscroll', this.updateLastActivity, false)
      document.addEventListener('onkeypress', this.updateLastActivity, false)
    } else {
      document.removeEventListener('mousemove', this.updateLastActivity)
      document.removeEventListener('onscroll', this.updateLastActivity)
      document.removeEventListener('onkeypress', this.updateLastActivity)
    }
  }

  /**
   * A function that returns the standard timeout timer with the timeout
   * length specified and the inactivateReporter function.
   */
  resetInactivityTimeout() {
    clearTimeout(this.lastActivityTimer)
    this.lastActivityTimer = setTimeout(
      this.inactivateReporter,
      this.props.timeoutLength,
    )
  }

  /**
   * handleReportUsage() submits the usage statistics and resets the inactivity timer.
   * On the first time the media is opened, it reports that the media has been opened once.
   */
  async handleReportUsage() {
    if ((!this.durationBeginTime && !this.totalTime) || this.isUnmounted) {
      return
    }

    // If this is the first time we report usage for the media, then record it as
    // having been opened.
    let opened = 0

    if (!this.hasReportedOpen) {
      opened = 1
      this.hasReportedOpen = true
    }

    // Calculate the total time used between reports.
    let duration = this.totalTime
    const currentTime = Date.now()

    if (this.durationBeginTime) {
      duration += Math.floor((currentTime - this.durationBeginTime) / 1000)
    }

    // Stop if there isn't anything to report.
    if (!duration && !opened) {
      return
    }

    // Reset values used in tracking.
    this.totalTime = 0
    this.durationBeginTime = !this.isPaused
      ? Date.now()
      : null

    console.info('UsageTracker', {
      ...this.props.mediaData,
      duration,
      opened,
    })
    await reportUsage({
      ...this.props.mediaData,
      duration,
      opened,
    }).catch(() => {
      // add the time that wasn't reported back to the amount to be reported next time
      this.totalTime += duration
    })
  }

  /**
   * This method is executed every time there is activity on the page and resets the
   * inactivity timeout timer to the amount of time specified in props.
   */
  updateLastActivity() {
    if (!this.durationBeginTime) {
      this.durationBeginTime = Date.now()
    }
    this.isPaused = false
    this.resetInactivityTimeout()
  }

  /**
   * inactivateReporter() clears the inactivity timer on the page and reports the usage statistics.
   */
  inactivateReporter() {
    if (!this.props.isManual) {
      this.pauseStatistics({ stopEventListeners: false })
      clearTimeout(this.lastActivityTimer)
    }
  }

  /**
   * Begins tracking statistics
   * @param {Node} [context] - element to which events should be added
   */
  startStatistics(context) {
    // If we have a new context, then we'll need to add the event listeners to it.
    if (context) {
      this.contextDocument = context
    }

    // Set a new begin time if one hasn't been set, if there is already a begin time
    // that means the usage is already being tracked, so we don't need to start
    // tracking again.

    this.durationBeginTime = Date.now()
    this.isPaused = false
    this.resetInactivityTimeout()
    this.initializeCurrentUsageReporting()
    this.modifyEventListeners(true)
  }

  /**
   * Pauses the current tracking of usage with the intention of possibly restarting
   * it in the near future.
   *
   * @param {object} [params={}] - Pause tracking options
   * @param {boolean} [params.stopEventListeners=false] - Removes event listeners while paused.
   */
  pauseStatistics({ stopEventListeners = false } = {}) {
    // If we have a start time, then calculate the current usage time and store it
    // so it can be recorded later.
    if (this.durationBeginTime) {
      this.totalTime += Math.floor((Date.now() - this.durationBeginTime) / 1000)
      this.durationBeginTime = null
    }

    this.handleReportUsage()

    // Remove event listeners if requested.
    if (stopEventListeners) {
      this.modifyEventListeners(false)
    }
    clearTimeout(this.lastActivityTimer)
    clearInterval(this.currentUsageTimeout)
  }

  /**
   * Removes event listeners for activity on the page and reports statistics
   * @returns {boolean} Returns true if the statistics were successfully reported,
   *  and false if there was an error while reporting
   */
  async stopStatistics() {
    this.pauseStatistics({ stopEventListeners: true })

    try {
      await this.handleReportUsage()

      return true
    } catch (error) {
      return false
    }
  }

  /**
   * Returns a function with accessors to the start and stop methods for reporting statistics
   * @returns {function({start: function, stop: function, pause: function})} a function
   * with an object that accesses the start and stop methods for the report timer
   */
  render() {
    return this.props.children({
      start: this.startStatistics,
      pause: this.pauseStatistics,
      stop: this.stopStatistics,
    })
  }
}

UsageTracker.propTypes = {
  /** Render function. */
  children: propTypes.func.isRequired,
  /** Media being tracked. */
  mediaData: propTypes.shape({
    sku: propTypes.string.isRequired,
    id: propTypes.oneOfType([propTypes.number, propTypes.string]).isRequired,
    subscribed: propTypes.bool.isRequired,
  }).isRequired,
  /** Max time (ms) of inactivity before tracking is paused. */
  timeoutLength: propTypes.number,
  /** Time (ms) between usage reporting. */
  reportInterval: propTypes.number,
  /** Should the component start tracking usage as soon as it mounts  */
  startOnMount: propTypes.bool,
  /** Are the tracker control functions being manually controlled */
  isManual: propTypes.bool,
}

UsageTracker.defaultProps = {
  timeoutLength: minutesToMilliseconds(2),
  reportInterval: minutesToMilliseconds(0.5),
  startOnMount: true,
  isManual: false,
}

export default UsageTracker
