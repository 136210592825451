import React from 'react'
import glamorous from 'glamorous'
import { Link } from 'react-router-dom'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/components/card/
 * @todo figure out how to support dropdowns in the Header.IconButton component.
 * @todo add documentation
 */

const Card = glamorous.div('card', propsToHelperClasses)

Card.Header = glamorous.header('card-header', propsToHelperClasses)
Card.Header.Title = glamorous.p('card-header-title has-ellipsis', propsToHelperClasses)

const IconButton = glamorous.a('card-header-icon', propsToHelperClasses)

Card.Header.IconButton = props => <IconButton role="button" tabIndex="0" {...props} />
Card.Image = glamorous.div('card-image', propsToHelperClasses)
Card.Content = glamorous.div('card-content', propsToHelperClasses)
Card.Footer = glamorous.footer('card-footer', propsToHelperClasses)

const FooterButton = glamorous.a('card-footer-item', propsToHelperClasses)

Card.Footer.Button = props => <FooterButton role="button" tabIndex="0" {...props} />
Card.Footer.Link = FooterButton.withComponent(Link)
Card.Footer.Item = FooterButton.withComponent('p')

export default Card
