import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Navbar, Container, IconWrapper, ChevronIcon, BookmarkIcon } from '../../../dbWebUI'

import './TopToolbar.css'

/**
 * Top Toolbar component for use in the Reader Page.
 *
 * @returns {function} Component
 */
function TopToolbar({
  ebook,
  chapter,
  className,
  onMenuClick,
  onPrevious,
  onNext,
  onToggleBookmarks,
  pageCountLabel,
  ...rest
}) {
  const classes = classNames('TopToolbar', className)

  return (
    <Navbar hasShadow isMobile className={classes} {...rest}>
      <Container>
        <Navbar.Brand>
          <Navbar.Burger isDesktop onClick={onMenuClick} />
          <Navbar.StaticItem>
            <div className="title-group">
              <p className="title is-6 has-ellipsis">{ebook.package.metadata.title}</p>
              <p className="subtitle is-7 has-ellipsis">{pageCountLabel}</p>
            </div>
          </Navbar.StaticItem>
        </Navbar.Brand>
        <Navbar.Menu>
          <Navbar.End>
            <Navbar.Dropdown isHoverable isHiddenDesktop>
              <Navbar.Dropdown.Label />
              <Navbar.Dropdown.Menu isRight>
                <Navbar.Item onClick={onToggleBookmarks}>
                  <IconWrapper>
                    <BookmarkIcon />
                  </IconWrapper>
                  <span>Bookmarks</span>
                </Navbar.Item>
              </Navbar.Dropdown.Menu>
            </Navbar.Dropdown>
            <Navbar.Item
              onClick={onToggleBookmarks}
              isHiddenTouch
              hasTooltip
              hasTooltipBottom
              data-tooltip="Bookmarks"
            >
              <IconWrapper>
                <BookmarkIcon />
              </IconWrapper>
            </Navbar.Item>
            <Navbar.Item
              onClick={onPrevious}
              isHiddenTouch
              hasTooltip
              hasTooltipBottom
              data-tooltip="Previous Page"
            >
              <IconWrapper>
                <ChevronIcon isLeft />
              </IconWrapper>
            </Navbar.Item>
            <Navbar.Item
              onClick={onNext}
              isHiddenTouch
              hasTooltip
              hasTooltipBottom
              data-tooltip="Next Page"
            >
              <IconWrapper>
                <ChevronIcon isRight />
              </IconWrapper>
            </Navbar.Item>
          </Navbar.End>
        </Navbar.Menu>
      </Container>
    </Navbar>
  )
}

TopToolbar.defaultProps = {
  ebook: {},
  chapter: {},
  className: '',
  onMenuClick: () => {},
  onNext: () => {},
  onPrevious: () => {},
  onToggleBookmarks: () => {},
  pageCountLabel: '',
}

TopToolbar.propTypes = {
  /** Current ebook object */
  ebook: PropTypes.shape({}),
  /** Current chapter object */
  chapter: PropTypes.shape({}),
  /** Additional class names. */
  className: PropTypes.string,
  /** Callback for menu button */
  onMenuClick: PropTypes.func,
  /** Callback for next button */
  onNext: PropTypes.func,
  /** Callback for previous button */
  onPrevious: PropTypes.func,
  /** Callback to toggle the display of bookmarks */
  onToggleBookmarks: PropTypes.func,
  /** The current page of total pages label */
  pageCountLabel: PropTypes.string,
}

export default TopToolbar
