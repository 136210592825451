import React from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { Link } from 'react-router-dom'
import { propsToHelperClasses } from '../helpers'
import { Button } from '../elements'

/**
 * @see https://bulma.io/documentation/components/dropdown/
 * @todo add documentation
 */
const Dropdown = glamorous.div('dropdown', propsToHelperClasses)

// Dropdown trigger components
Dropdown.Trigger = glamorous.div('dropdown-trigger', propsToHelperClasses)
Dropdown.Trigger.Button = props => <Button aria-haspopup="true" {...props} />

// Dropdown menu components
const MenuWrapper = glamorous.div('dropdown-menu', propsToHelperClasses)

Dropdown.Menu = ({ children, ...rest }) => (
  <MenuWrapper role="menu" {...rest}>
    <div className="dropdown-content">
      {children}
    </div>
  </MenuWrapper>
)


Dropdown.Menu.defaultProps = {
  children: false,
}

Dropdown.Menu.propTypes = {
  /** Dropdown menu contents */
  children: PropTypes.node,
}

Dropdown.Content = ({ children, className, ...rest }) => (
  <div className={`dropdown-content ${className}`} {...rest} >
    {children}
  </div>
)

Dropdown.Content.propTypes = {
  className: PropTypes.string,
}

Dropdown.Content.defaultProps = {
  className: '',
}

Dropdown.Content.defaultProps = {
  children: false,
}

Dropdown.Content.propTypes = {
  children: PropTypes.node,
}

// Dropdown menu content components
const MenuItemWrapper = glamorous.a('dropdown-item', propsToHelperClasses)

Dropdown.MenuItem = props => <MenuItemWrapper role="button" tabIndex="0" {...props} />
Dropdown.StaticMenuItem = MenuItemWrapper.withComponent('div')
Dropdown.MenuItemLink = MenuItemWrapper.withComponent(Link)
Dropdown.Divider = glamorous.hr('dropdown-divider', propsToHelperClasses)

export default Dropdown
