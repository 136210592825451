/* eslint import/prefer-default-export: 0 */
import { getToken } from './users'
import { uuid } from '../utils/base'

/**
 * Base set of extra options that should be sent to all Bookshelf SDK requests.
 * @returns {object} - default sdk settings
 */
export const requestOptions = () => ({
  token: getToken(),
  deviceId: uuid(),
  baseUrl: process.env.REACT_APP_API_BASE,
  headers: {
    Origin: window.location
      ? window.location.origin
      : null,
  },
})
