import React, { Component } from 'react'
import PropTypes from 'prop-types'
import URI from 'urijs'
import Mousetrap from 'mousetrap'
import Iframe from './Iframe'
import WithAnnotations from './WithAnnotations'
import { handlePageScroll } from '../../../utils/base'

/**
 * Displays annotatable html content inside an iFrame.
 */
class BookContent extends Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.setupIframe = this.setupIframe.bind(this)
    this.initializeKeyboardControls = this.initializeKeyboardControls.bind(this)
    this.initializeLinks = this.initializeLinks.bind(this)
    this.initializeStartingPosition = this.initializeStartingPosition.bind(this)
    this.linkClickHandler = this.linkClickHandler.bind(this)
    this.clearLoading = this.clearLoading.bind(this)
    this.goToBlr = this.goToBlr.bind(this)

    this.state = {
      /** Temporary state variable that allows us to fully reload the book content. */
      isLoading: false,
    }
  }

  /**
   * Figures out state based on changing props.
   *
   * @param {object} nextProps - Next component props
   */
  componentWillReceiveProps(nextProps) {
    const { content } = this.props

    // If the book content is going to change, we need to add a loading phase
    // so we can completely remove the iframe from the dom before readding it
    // with the new content. The reason for doing this is when we just opened
    // and wrote the new content to the existing iframe, React wasn't able to
    // keep track of event handlers properly. This means that annotations
    // wouldn't be clickable if they were added after the content changed.
    // If you really want to know more about why, you'll have to look at how
    // React handles event handlers.
    if (nextProps.content !== content) {
      this.setState({ isLoading: true })
    }
  }

  /**
   * We don't want to keep setting up the iframe every time a prop changes, so
   * we want to make sure only a change in either content or annotations triggers
   * a re-render.
   *
   * @param {object} nextProps - new component properties
   * @param {object} nextState - new component state
   * @returns {boolean} If the component should update or not
   */
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.content !== this.props.content || nextState.isLoading !== this.state.isLoading
  }

  /**
   * Clears loading once the component has actually updated.
   */
  componentDidUpdate() {
    this.clearLoading()
  }

  /**
   * Adds eBook content to the iframe document, and then setups annotation support.
   *
   * @param {HTMLElement} iframe - iframe element
   * @param {document} iframeDocument - document of iframe
   */
  setupIframe(iframe, iframeDocument) {
    const {
      initializeAnnotations,
      ebook,
      content,
      onLoadAnnotations,
      onSetup,
      getFirstVisibleBlr,
      updateAnnotations,
    } = this.props

    // Append a container for annotations inside the iframe.
    const annotationContainer = iframeDocument.createElement('div')

    annotationContainer.id = 'annotation-container'
    iframeDocument.body.appendChild(annotationContainer)

    // Setup annotations to render inside the container.
    initializeAnnotations(
      {
        iframe,
        context: annotationContainer,
        bookId: ebook.bookId,
        documentId: content.documentId,
      },
      onLoadAnnotations,
    )

    this.initializeKeyboardControls(iframeDocument)

    // Now hijack link clicks so we can parse them ourselves if we need to.
    this.initializeLinks(iframeDocument)

    // If this is the first time the page has loaded and there is a starting
    // position then jump to that.
    this.initializeStartingPosition(iframeDocument)

    // Call the setup callback now and expose useful data/functions.
    onSetup({ getFirstVisibleBlr, updateAnnotations, iframeDocument })
  }

  /**
   * Adds keyboard shortcut support for navigating the book's content.
   *
   * @param {node} context - document context
   */
  initializeKeyboardControls(context) {
    const { onNext, onPrevious } = this.props

    // Add the keyboard shortcuts to both the parent and iframe context so
    // they can be used whether the user is focused in the iframe or not.
    Mousetrap.bind('left', onPrevious)
    Mousetrap(context).bind('left', onPrevious)

    Mousetrap.bind('right', onNext)
    Mousetrap(context).bind('right', onNext)

    const mq = window.matchMedia('(min-width: 1088px)')
    const heightOfNavbar = 53
    const heightOfToolbar = 53
    const scrollOffset =
      window.innerHeight - (mq.matches ? heightOfToolbar + heightOfNavbar : heightOfToolbar)

    Mousetrap.bind('space', (e) => handlePageScroll(e, { offset: scrollOffset }))
    Mousetrap(context).bind('space', (e) => handlePageScroll(e, { offset: scrollOffset }))

    Mousetrap.bind('shift+space', (e) => handlePageScroll(e, { offset: -scrollOffset }))
    Mousetrap(context).bind('shift+space', (e) => handlePageScroll(e, { offset: -scrollOffset }))
  }

  /**
   * Adds a listener to links in book content so we can figure out what the best
   * way to load the link's content is.
   *
   * @param {node} context - document context
   */
  initializeLinks(context) {
    const relativeLinks = context.querySelectorAll('a[href]')

    Array.from(relativeLinks).map((relativeLink) => {
      relativeLink.addEventListener('click', this.linkClickHandler, false)

      return relativeLink
    })
  }

  /**
   * Scrolls to the appropriate starting point of the book contents based on
   * the blr object received.
   *
   * @param {element} context - book content's context element (iframe document)
   * @param {object} blr - Parsed BLR object.
   * @todo It might be worthwhile to remove the event listeners at somepoint, but
   * I'm not really sure how.
   */
  initializeStartingPosition(context) {
    // Add listeners to all resources on the page, that way if assets get loaded
    // and modify the dom somehow, we can jump back to the original starting position.
    // This creates an issue where the user might start scrolling down the page and a
    // super long request loads and forces the user back to their original position.
    // Annoying, but there isn't really a good way for us to prevent that kind of thing,
    // and it probably won't happen much anyway.
    const resources = context.querySelectorAll('link, img')

    Array.from(resources).map((resource) => {
      resource.addEventListener('load', () => this.goToBlr(context), false)

      return resource
    })
    this.goToBlr(context)
  }

  /**
   * Handles a click event on a link inside the book's content to determine
   * what the best way to load the link would be.
   *
   * @param {type} e - event
   */
  linkClickHandler(e) {
    const href = e.target.getAttribute('href')
    const uri = new URI(href)

    // Load external links in a new window.
    if (!uri.is('relative')) {
      e.preventDefault()
      window.open(href)
      // If the link is relative, then we'll want to load the content ourselves
      // as the browser won't know how to handle it properly.
    } else if (uri.is('relative') && uri.toString() !== uri.hash()) {
      e.preventDefault()
      this.props.onLinkClick(href)
    }
  }

  /**
   * Scrolls the book content to the beginning position specifief in the parsed
   * blr object.
   *
   * @param {element} context - book content's context element (iframe document)
   * @todo determine best way to figure out if the content has changed, we're
   * currently scrolling to the top if there isn't an initial position, but there
   * are times that you might click a link and it loads footnotes and it causes
   * the main content to render again which makes this scroll to the top.
   */
  goToBlr(context) {
    const { content } = this.props

    if (content.initialPosition) {
      const startingElement = context.querySelector(content.initialPosition)

      startingElement.scrollIntoView()
      window.parent.scrollBy(0, -100) // take into account the fixed nav and toolbar.
    } else {
      window.parent.scroll(0, 0)
    }
  }

  /**
   * Clears the loading phase if it's active. While in the loading phase, nothing
   * is rendered (removing any previously mounted content). By clearing the
   * loading phase, we force a rerender, which will cause the book content to be
   * remounted and reinitialized properly.
   */
  clearLoading() {
    const { isLoading } = this.state

    if (isLoading) {
      this.setState({ isLoading: false })
    }
  }

  /**
   * Determines how to render the component.
   * @returns {function} Component
   */
  render() {
    const { content, ebook } = this.props
    const { isLoading } = this.state

    // Render nothing if there is no content available or if we're in the loading
    // phase.
    if (isLoading || !content || !content.content) {
      return false
    }

    return (
      <Iframe
        src="about:blank"
        content={content.content}
        onSetup={(iframe, iframeDocument) => {
          this.setupIframe(iframe, iframeDocument)
        }}
        title={`${ebook.package.metadata.title}`}
        innerCSS={{
          html: {
            fontSize: '1.2rem',
            lineHeight: '1.5',
            fontFamily: 'Palatino, Georgia, serif',
          },
          body: {
            padding: '2rem 1.5rem',
            margin: 'auto',
            width: '100%',
            maxWidth: '50rem',
            boxSizing: 'border-box',
            pointer: 'cursor',
          },
          img: {
            maxWidth: '100%',
          },
          'body *': {
            // Override iOS highlight color for clickable elements.
            '-webkit-tap-highlight-color': 'rgba(0,0,0,0)',
          },
          'body > *:not(#annotation-container)': {
            position: 'relative',
            zIndex: '2',
          },
        }}
      />
    )
  }
}

BookContent.defaultProps = {
  content: {},
  ebook: {},
  initializeAnnotations: () => {},
  getFirstVisibleBlr: () => {},
  updateAnnotations: () => {},
  onLinkClick: () => {},
  onPrevious: () => {},
  onNext: () => {},
  onLoadAnnotations: () => {},
  onSetup: () => {},
}

BookContent.propTypes = {
  /** string of HTML to display in reader */
  content: PropTypes.shape({}),
  /** eBook object being displayed */
  ebook: PropTypes.shape({}),
  /** Function provided by `WithAnnotations` HOC that initializes annotations for reader. */
  initializeAnnotations: PropTypes.func,
  /** Function provided by `WithAnnotations` HOC that returns a BLR for the first visible node. */
  getFirstVisibleBlr: PropTypes.func,
  /** Function provided by `WithAnnotations` HOC that updates the list of annotations. */
  updateAnnotations: PropTypes.func,
  /** Callback for overriding a link click action inside the book content. */
  onLinkClick: PropTypes.func,
  /** Callback for when the previous content should be selected */
  onPrevious: PropTypes.func,
  /** Callback for when the next content should be selected */
  onNext: PropTypes.func,
  /** Callback for when new annotations are loaded. */
  onLoadAnnotations: PropTypes.func,
  /** Callback for when the book content has been initially setup. */
  onSetup: PropTypes.func,
}

export default WithAnnotations(BookContent)
