import React from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * A `Select` is a wrapper for the `<select />` tag. It should be used inside a `Control`.
 *
 * If you're trying to build a form, take a look at the different field components.
 * Using those will help ensure consistency.
 *
 * Based on Bulma's `.select`
 *
 * @see https://bulma.io/documentation/form/select/
 * @todo update documentation
 */
const SelectWrapper = glamorous.div('select', propsToHelperClasses)
const SelectInput = glamorous.select(propsToHelperClasses)
/**
 * Select component
 *
 * @param {object} props - Component props
 * @returns {function} Component
 */
const Select = ({ containerProps, ...rest }) => (
  <SelectWrapper {...containerProps}>
    <SelectInput {...rest} />
  </SelectWrapper>
)

Select.displayName = 'Select'

Select.defaultProps = {
  containerProps: {},
}

Select.propTypes = {
  /** Additional props for the wrapper component. */
  containerProps: PropTypes.shape({}),
}

export default Select
