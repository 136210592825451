import React from 'react'
import Slider from 'rc-slider'
import propTypes from 'prop-types'
import { VolumeUpIcon, VolumeOffIcon, IconWrapper } from '../../dbWebUI/icons'
import './VolumeControl.css'
import { Dropdown } from '../../dbWebUI'

/**
 * Component for controling volume levels.
 */
class VolumeControl extends React.Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      lastVolume: 0,
    }

    this.handleVolumeButtonClick = this.handleVolumeButtonClick.bind(this)
  }

  /**
   * Event handler for when the volume button is clicked.
   */
  handleVolumeButtonClick() {
    if (this.props.volume) {
      this.setState({ lastVolume: this.props.volume })
      this.props.onVolumeChange(0)
    } else {
      this.props.onVolumeChange(this.state.lastVolume)
    }
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const slider = (
      <Slider
        vertical
        value={this.props.volume * 100}
        className={this.props.isDropdown
            ? 'slider dropdown'
            : 'slider'}
        onChange={volume => this.props.onVolumeChange(volume / 100)}
      />
    )
    const button
      = this.props.volume === 0 ? (
        <VolumeOffIcon
          className={`volume-icon ${
            this.props.isDropdown
              ? 'is-mobile'
              : ''
            }`}
        />
      ) : (
        <VolumeUpIcon
          className="volume-icon"
        />
      )

    return (
      <Dropdown isUp isHoverable className="VolumeSlider">
        <Dropdown.Trigger>
          <Dropdown.Trigger.Button isCircular onClick={this.handleVolumeButtonClick}>
            <IconWrapper>
              {button}
            </IconWrapper>
          </Dropdown.Trigger.Button>
        </Dropdown.Trigger>
        <Dropdown.Menu className="volume-slide-track">
          {slider}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

VolumeControl.propTypes = {
  /** Method called when a new volume is selected */
  onVolumeChange: propTypes.func,
  /** The current volume */
  volume: propTypes.number,
  /** Should the component display in a dropdown? */
  isDropdown: propTypes.bool,
}

VolumeControl.defaultProps = {
  onVolumeChange: () => {},
  volume: 0,
  isDropdown: false,
}

export default VolumeControl
