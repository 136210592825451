import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import URI from 'urijs'

import { login } from '../../api/users'
import { libraryUrl } from '../../utils/routes'
import { Button, InputField } from '../../dbWebUI'

/**
* Generic login form. Displays input fields and handles login and redirecting
* the user to the proper place.
*/
class LoginForm extends Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      form: {
        email: '',
        password: '',
      },
      errorMessage: '',
      isLoading: false,
    }

    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.formIsValid = this.formIsValid.bind(this)
  }

  /**
  * Makes sure the form is submitted, then logs the user in. If there's a `next`
  * route param, then the user is redirected there, otherwise they are redirected
  * to the homepage.
  *
  * @param {event} e - Submit event
  */
  onSubmitHandler(e) {
    e.preventDefault()

    if (this.formIsValid()) {
      const { email, password } = this.state.form
      const { history } = this.props

      this.setState({ isLoading: true })

      login({ email, password })
        .then(() => {
          this.setState({ isLoading: false })

          if (history) {
            const routeParams = URI().search(true)

            history.push('next' in routeParams
              ? decodeURIComponent(routeParams.next)
              : libraryUrl())
          }
        })
        .catch((error) => {
          let { message } = error

          /** A type error is returned when the application can't reach the server */
          if (error instanceof TypeError) {
            message = 'Something went wrong connecting to the server. Please check your network connectivity.'
          }
          this.setState({
            errorMessage: message,
            isLoading: false,
          })
        })
    }
  }

  /**
  * Hanldes change of form value.
  *
  * @param {object} e - event
  */
  onChangeHandler(e) {
    const { name, value } = e.target

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  /**
  * Checks to see if the form is valid.
  *
  * @returns {boolean} If the form is valid.
  */
  formIsValid() {
    const { email, password } = this.state.form

    return email && password
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const { errorMessage, isLoading } = this.state

    return (
      <form onSubmit={this.onSubmitHandler}>
        <InputField
          id="email"
          name="email"
          labelContent="Email"
          type="email"
          required
          onChange={this.onChangeHandler}
        />
        <InputField
          id="password"
          name="password"
          labelContent="Password"
          type="password"
          required
          onChange={this.onChangeHandler}
        />
        {!!errorMessage && <div>{errorMessage}</div>}
        <Button
          isPrimary
          type="submit"
          disabled={!this.formIsValid() || isLoading}
          isLoading={isLoading}
        >
          Sign In
        </Button>
        {this.props.children}
      </form>
    )
  }
}

LoginForm.propTypes = {
  /** window history object */
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
}

LoginForm.defaultProps = {
  children: false,
}

export default withRouter(LoginForm)
