import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'


/**
 * @see https://bulma.io/documentation/elements/notification/
 * @todo add documentation
 */
const NotificationWrapper = glamorous.div('notification', propsToHelperClasses)

/**
 * Component for displaying a notification
 */
class Notification extends PureComponent {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      isHidden: false,
    }
  }

  /**
   * Setup auto-close once the notification is rendered.
   */
  componentDidMount() {
    if (this.props.autoCloseTimeout) {
      setTimeout(() => {
        this.setState({ isHidden: true })
      }, this.props.autoCloseTimeout)
    }
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const {
      isHidden, isCloseable, children, ...rest
    } = this.props
    const { isHidden: isHiddenState } = this.state

    if (isHidden || isHiddenState) {
      return false
    }

    return (
      <NotificationWrapper {...rest}>
        {isCloseable
          ? (
            <span
              className="delete"
              role="button"
              tabIndex="0"
              onClick={() => {
                this.setState({ isHidden: true })
              }}
              onKeyPress={() => {
                this.setState({ isHidden: true })
              }}
            />
          )
          : false}
        {children}
      </NotificationWrapper>
    )
  }
}

Notification.defaultProps = {
  isCloseable: true,
  children: false,
  isHidden: false,
  autoCloseTimeout: null,
}

Notification.propTypes = {
  isCloseable: PropTypes.bool,
  children: PropTypes.node,
  isHidden: PropTypes.bool,
  autoCloseTimeout: PropTypes.oneOf([null, PropTypes.number]),
}


export default Notification
