import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import URI from 'urijs'

import { register } from '../../api/users'
import { libraryUrl } from '../../utils/routes'
import { Button, InputField } from '../../dbWebUI'

/**
* Generic registration form. Displays input fields and handles registration and redirecting
* the user to the proper place.
*/
class RegistrationForm extends Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
      },
      errorMessage: '',
      isLoading: false,
    }

    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.formIsValid = this.formIsValid.bind(this)
  }

  /**
  * Makes sure the form is submitted, then registers the user. If there's a `next`
  * route param, then the user is redirected there, otherwise they are redirected
  * to the homepage.
  *
  * @param {event} e - submit event
  */
  onSubmitHandler(e) {
    e.preventDefault()

    if (this.formIsValid()) {
      const { history } = this.props

      this.setState({ isLoading: true })

      register(this.state.form)
        .then(() => {
          this.setState({ isLoading: false })

          if (history) {
            const routeParams = URI().search(true)

            history.push('next' in routeParams
              ? decodeURIComponent(routeParams.next)
              : libraryUrl())
          }
        })
        .catch((error) => {
          this.setState({ errorMessage: error.message, isLoading: false })
        })
    }
  }

  /**
  * Hanldes change of form value.
  *
  * @param {object} e - event
  */
  onChangeHandler(e) {
    const { name, value } = e.target

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    })
  }

  /**
  * Checks to see if the form is valid.
  *
  * @returns {boolean} If form is valid or not
  */
  formIsValid() {
    const { email, password, passwordConfirmation } = this.state.form

    return email && password && passwordConfirmation && (password === passwordConfirmation)
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const { errorMessage, isLoading } = this.state

    return (
      <form onSubmit={this.onSubmitHandler}>
        <InputField
          id="firstName"
          name="firstName"
          labelContent="First Name"
          type="text"
          onChange={this.onChangeHandler}
        />
        <InputField
          id="lastName"
          name="lastName"
          labelContent="Last Name"
          type="text"
          onChange={this.onChangeHandler}
        />
        <InputField
          id="email"
          name="email"
          labelContent="Email"
          type="email"
          required
          onChange={this.onChangeHandler}
        />
        <InputField
          id="password"
          name="password"
          labelContent="Password"
          type="password"
          required
          onChange={this.onChangeHandler}
        />
        <InputField
          id="passwordConfirmation"
          name="passwordConfirmation"
          labelContent="Password Confirmation"
          type="password"
          required
          onChange={this.onChangeHandler}
        />
        {!!errorMessage && <div>{errorMessage}</div>}
        <Button
          isPrimary
          type="submit"
          disabled={!this.formIsValid() || isLoading}
          isLoading={isLoading}
        >
          Register
        </Button>
        {this.props.children}
      </form>
    )
  }
}

RegistrationForm.propTypes = {
  /** window history object */
  history: PropTypes.shape({}).isRequired,
  children: PropTypes.node,
}

RegistrationForm.defaultProps = {
  children: false,
}

export default withRouter(RegistrationForm)
