import React from 'react'
import { Button, Box, Icon, Level, Heading } from '@deseretbook/react-ui'
import PropTypes from 'prop-types'
import { AUDIOBOOK_CONTROL_VIEWS } from './AudiobookControls'
import { Slider } from '../../../../dbWebUI'

/**
 * Volume control view of audiobook player controls.
 */
export default class VolumeView extends React.Component {
  /**
   * Component constructor
   *
   * @param {object} props - initial props
   */
  constructor(props) {
    super(props)
    this.state = {
      volume: props.volume,
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleAfterChange = this.handleAfterChange.bind(this)
  }

  /**
   * Handles volume change
   *
   * @param {number} volume - new volume
   */
  handleChange(volume) {
    this.setState({ volume: volume / 100 })
  }

  /**
   * Callback for when volume changes.
   *
   * @param {number} newVolume - Updated volume
   */
  handleAfterChange(newVolume) {
    this.props.onChangeVolume(newVolume / 100)
  }

  /**
   * Figures out how to render this thing.
   * @returns {function} Component
   */
  render() {
    const { onChangeView } = this.props

    return (
      <React.Fragment>
        <Heading>Change Volume</Heading>
        <Level>
          <Level.Item>
            <Box isFullwidth flex={1} padding={1}>
              <Slider
                value={this.state.volume * 100}
                min={0}
                max={100}
                step={1}
                onBeforeChange={this.handleBeforeChange}
                onChange={this.handleChange}
                onAfterChange={this.handleAfterChange}
              />
            </Box>
          </Level.Item>
          <Level.Right>
            <Button type="button" onClick={() => onChangeView(AUDIOBOOK_CONTROL_VIEWS.control)}>
              <Icon name="check" />
            </Button>
          </Level.Right>
        </Level>
      </React.Fragment>
    )
  }
}

VolumeView.propTypes = {
  onChangeView: PropTypes.func,
  onChangeVolume: PropTypes.func,
  volume: PropTypes.number,
}

VolumeView.defaultProps = {
  onChangeView: () => {},
  onChangeVolume: () => {},
  volume: 0,
}
