import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Box } from '@deseretbook/react-ui'
import { listenAudiobookUrl } from '../../../utils/routes'
import { openPopupPlayer } from '../../../utils/media'

/**
 * Wraps the component in either a `Box` or `Link` component.
 * Box is used to open the audiobook popup player, while the Link goes to the
 * component reader page.
 *
 * @param {boolean} isAudiobook - whether the media is an audiobook
 * @param {object} media - media object
 * @param {string} className - class name
 * @param {function} children - React Component to wrap
 *
 * @returns {function} React component
 */
const PlayerWrapper = ({ isAudiobook, children, media, className, ...rest }) => {
  const Component = isAudiobook ? Box : Link
  const onClick = isAudiobook
    ? () => openPopupPlayer(listenAudiobookUrl(media.mediaId, media.audiobook.sku))
    : undefined

  return (
    <Component onClick={onClick} className={className} {...rest}>
      {children}
    </Component>
  )
}

PlayerWrapper.defaultProps = {
  className: '',
  isAudiobook: false,
  media: {},
  children: null,
}

PlayerWrapper.propTypes = {
  isAudiobook: PropTypes.bool,
  media: PropTypes.shape({
    mediaId: PropTypes.string,
    audiobook: PropTypes.shape({
      sku: PropTypes.string,
    }),
  }),
  className: PropTypes.string,
  children: PropTypes.node,
}

export default PlayerWrapper
