/* eslint import/prefer-default-export: 0 */

/**
 * Figures out what elements are found at the given x/y coordinates.
 *
 * @param {number} x - x-axis position
 * @param {number} y - y-axis position
 * @param {HTMLDocument} [context=document] - Context of where to look for elements.
 *
 * @returns {array} - Array of dom elements
 */
export function elementsFromPoint(x, y, context = document) {
  if (context.elementsFromPoint) {
    return context.elementsFromPoint(x, y)
  }

  const elements = []
  const previousPointerEvents = []
  let current = context.elementFromPoint(x, y)

  // get all elements via elementFromPoint, and remove them from hit-testing in order
  while (current && elements.indexOf(current) === -1 && current != null) {
    // Record the element and save its current style for later restoration.
    elements.push(current)
    previousPointerEvents.push({
      value: current.style.getPropertyValue('pointer-events'),
      priority: current.style.getPropertyPriority('pointer-events'),
      element: current,
    })

    // Add `pointer-events: none` to get to the underlying element
    current.style.setProperty('pointer-events', 'none', 'important')

    current = context.elementFromPoint(x, y)
  }

  // restore the previous pointer-events values
  return previousPointerEvents
    .map((pointerEvent) => {
      pointerEvent.element.style.setProperty('pointer-events', pointerEvent.value || '', pointerEvent.priority)

      return pointerEvent.element
    })
}
