import React from 'react'
import Icon from '../Icon'

/**
 * Add Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function CreateIcon(props) {
  return (
    <Icon
      icon="add"
      {...props}
    >
      <g>
        <path d="M19 13h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" />
      </g>
    </Icon>
  )
}
