import { TOKEN_STORAGE_KEY, USER_STORAGE_KEY } from '../constants'


/**
 * Logs out the current user by removing their token from `localStorage`
 *
 * @example
 * logout()
 *   .then(data => console.log('logged out!'))
 *
 * @returns {Promise} Promise that clears token from local storage
 */
export default function logout() {
  return new Promise((resolve) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, '')
    localStorage.setItem(USER_STORAGE_KEY, '')
    resolve(true)
  })
}
