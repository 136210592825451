import React from 'react'
import Icon from '../Icon'

/**
 * Bookshelf PLUS's "PLUS" Logo.
 * @todo add documentation
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function PlusLogo(props) {
  return (
    <Icon
      icon="plus-logo"
      viewBox="0 0 755 161"
      title="Bookshelf PLUS"
      role="img"
      {...props}
    >
      <g>
        <path d="M719.427,84.919l-17.813,0c-4.668,0 -8.443,-3.775 -8.443,-8.444c0,
        -4.669 3.775,-8.45 8.443,-8.45l17.813,0c4.662,0 8.444,3.781 8.444,8.45c0,
        4.669 -3.782,8.444 -8.444,8.444m-38.588,-18.807c0,10.369 -8.437,18.807 -18.812,
        18.807l-31.506,0c-4.663,0 -8.438,-3.775 -8.438,-8.444c0,-4.669 3.775,-8.45 8.438,
        -8.45l31.506,0c1.044,0 1.919,-0.875 1.919,-1.913l0,-31.506c0,-4.662 3.781,
        -8.444 8.45,-8.444c4.662,0 8.443,3.782 8.443,8.444l0,31.506Zm0,59.075c0,
        4.663 -3.781,8.444 -8.443,8.444c-4.669,0 -8.45,-3.781 -8.45,-8.444l0,-17.812c0,
        -4.663 3.781,-8.444 8.45,-8.444c4.662,0 8.443,3.781 8.443,8.444l0,17.812Zm41.588,
        -125.187l-94.913,0c-17.843,0 -32.437,14.6 -32.437,32.437l0,94.919c0,17.838 14.594,
        32.431 32.437,32.431l94.913,0c17.844,0 32.444,-14.593 32.444,-32.431l0,-94.919c0,
        -17.837 -14.6,-32.437 -32.444,-32.437"
        />
        <path d="M426.648,135.312l20.256,-24.256c14.006,11.594 28.706,18.919 46.512,
        18.919c14.025,0 22.469,-5.544 22.469,-14.688l0,-0.431c0,-8.706 -5.337,
        -13.137 -31.375,-19.819c-31.375,-8 -51.631,-16.7 -51.631,-47.637l0,-0.438c0,
        -28.25 22.694,-46.962 54.525,-46.962c22.706,0 42.068,7.119 57.862,19.819l-17.806,
        25.8c-13.787,-9.575 -27.381,-15.35 -40.5,-15.35c-13.131,0 -20.037,6.012 -20.037,
        13.568l0,0.469c0,10.213 6.687,13.575 33.612,20.463c31.594,8.231 49.4,19.581 49.4,
        46.725l0,0.475c0,30.9 -23.588,48.268 -57.194,48.268c-23.581,0 -47.4,-8.231 -66.093,
        -24.925"
        />
        <path d="M271.411,91.272l0,-89.025l34.281,0l0,88.119c0,25.363 12.687,38.5 33.612,
        38.5c20.913,0 33.594,-12.669 33.594,-37.394l0,-89.225l34.281,0l0,87.882c0,47.2 -26.487,
        70.343 -68.325,70.343c-41.843,0 -67.443,-23.375 -67.443,-69.2"
        />
        <path d="M143.908,2.249l34.281,0l0,124.632l77.669,0l0,31.137l-111.95,0l0,-155.769Z" />
        <path d="M61.419,80.841c17.131,0 27.125,-10.231 27.125,-23.6l0,-0.437c0,-15.35 -10.663,
        -23.607 -27.8,-23.607l-26.475,0l0,47.644l27.15,0Zm-61.419,-78.581l63.638,0c37.143,
        0 59.631,22.062 59.631,53.869l0,0.431c0,36.056 -28.038,54.737 -62.963,54.737l-26.037,
        0l0,46.725l-34.269,0l0,-155.762Z"
        />
      </g>
    </Icon>
  )
}
