import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

import './Button.css'

/**
 * @see https://bulma.io/documentation/elements/button/
 * @todo add documentation
 */
const Button = glamorous.button('button', propsToHelperClasses)

export default Button
