import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Menu } from '../../../dbWebUI'

/**
 * Simple list view of a Books Table of Contents.
 *
 * TODO Write tests for this
 * TODO Add story for this
 * TODO support multiple levels of TOC.
 * @returns {function} Component
 */
function BookToc({
  className,
  toc,
  flatToc,
  activeChapter,
  onChapterSelect,
  ...rest
}) {
  const classes = classNames('book-toc', className)
  // const parent = activeChapter ? activeChapter.parent : null
  const parent = flatToc
    .filter(item => item && activeChapter && item.id === activeChapter.parent)
    .reduce((current, parentToc) => parentToc, null)

  /**
   * Determines how to render the list of chapters.
   * @returns {function} Component
   */
  const renderChapters = () => {
    let chapters = toc

    if (activeChapter && activeChapter.nested) {
      chapters = activeChapter.nested
    } else if (parent && parent.nested) {
      chapters = parent.nested
    }

    return chapters
      .map(chapter => (
        <Menu.ListItem
          key={chapter.id}
          isActive={activeChapter && chapter.id === activeChapter.id}
        >
          <Menu.ListItem.Button onClick={() => onChapterSelect({ src: chapter.src })}>
            {chapter.label}
          </Menu.ListItem.Button>
        </Menu.ListItem>
      ))
  }

  /**
   * Determines how to render the back button.
   * @returns {function} Component
   */
  const renderBackButton = () => {
    if (!activeChapter) {
      return false
    }

    return (
      <Menu.ListItem>
        <Menu.ListItem.Button onClick={() => onChapterSelect({ src: parent.src })}>
          Previous
        </Menu.ListItem.Button>
      </Menu.ListItem>
    )
  }

  return (
    <Menu {...rest} className={classes}>
      <Menu.List>
        {renderBackButton()}
        {renderChapters()}
      </Menu.List>
    </Menu>
  )
}

BookToc.defaultProps = {
  className: '',
  toc: [],
  flatToc: [],
  activeChapter: {},
  onChapterSelect: () => {},
}

BookToc.propTypes = {
  /** Additional classes */
  className: PropTypes.string,
  /** Media objects to display as `MediaCard`s */
  toc: PropTypes.arrayOf(PropTypes.object),
  flatToc: PropTypes.arrayOf(PropTypes.object),
  activeChapter: PropTypes.shape({}),
  onChapterSelect: PropTypes.func,
}

export default BookToc
