import { getBookAnnotations as sdkGetBookAnnotations } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'
import { formatAnnotations } from '../../utils/media'
import { uuid } from '../../utils/base'


/**
 * Gets all annotations the user has made on given book.
 *
 * @example
 * getBookAnnotations({ bookId })
 *   .then(annotations => console.log(annotations))
 *
 * @param {object} params - Book annotations request parameters
 * @param {string} params.bookId - This should be the eBook's `bookId`, not `mediaId`
 * @returns {Promise<Array<object>, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getBookAnnotations({ bookId, ...rest }) {
  // Since there's no way to know a device's id through the browser, and since
  // we don't really cache this data, then we just send random id's for
  // `deviceId` and `lastSyncId`.
  return sdkGetBookAnnotations({ lastSyncId: uuid(), bookId, ...rest }, requestOptions())
    .then((data) => {
      const formatted = formatAnnotations(data)

      return formatted[bookId] || {}
    })
}
