import { reportAudiobookPosition as sdkReportAudiobookPosition } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Updates the user's last position for the given media.
 *
 * @example
 * reportAudiobokPosition({ id, sku, seconds, item }, { token, deviceId })
 *   .then(success => console.log(success))
 *
 * @see http://docs-linked-from-confluence.s3.amazonaws.com/usage_api.swagger.html
 *
 * @param {object} params - user last position request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - product sku
 * @param {number} params.seconds - seconds position
 * @param {number} params.item - track position
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function reportAudiobookPosition(params = {}) {
  return sdkReportAudiobookPosition(params, requestOptions())
}
