import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * Adds helpful text to a `Field`.
 *
 * Based on Bulma's `.help`
 *
 * @see https://bulma.io/documentation/form/general/
 * @todo update documentation
 */
const Help = glamorous.p('help', propsToHelperClasses)

export default Help
