import { login as sdkLogin } from '@deseretbook/media-sdk'
import { TOKEN_STORAGE_KEY, USER_STORAGE_KEY } from '../constants'
import { requestOptions } from '../base'


/**
 * Logs in a user and saves a successful login to `localStorage`.
 *
 * @example
 * login({ email, password })
 *   .then(response => console.log(response))
 *
 * @see https://deseretbookwiki.atlassian.net/wiki/spaces/DB/pages/33030/Deseret+Bookshelf+API+Reference#DeseretBookshelfAPIReference-LoginAPI
 *
 * @param {object} params - login request parameters
 * @param {string} params.email - account email address
 * @param {string} params.password - account password
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 *
 * @todo return value can probably be cleaned up.
 * @todo Move storage of token to a cookie. This will improve security and will
 * also be needed for single sign-in.
 */
export default function login(params = {}) {
  return sdkLogin(params, requestOptions())
    .then((response) => {
      if (response.success) {
        localStorage.setItem(TOKEN_STORAGE_KEY, response.hash)
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(response.user))
      }

      return response
    })
}
