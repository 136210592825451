import { getEbookPackage as sdkGetEbookPackage } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Retrieves the package file contents for the given eBook.
 *
 * @example
 * getEbookPackage({ id, sku })
 *   .then(package => console.log(package))
 *
 * @see https://s3.amazonaws.com/docs-linked-from-confluence/epub_content_api.swagger.html#path--media--mediaId---sku--package
 *
 * @param {object} params - ebook package request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - eBook sku
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getEbookPackage(params = {}) {
  return sdkGetEbookPackage(params, requestOptions())
}
