import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createAnnotation, updateAnnotation, deleteAnnotation } from '../../../api/media'
import {
  HIGHLIGHT_STYLE_OPTIONS,
  HIGHLIGHT_COLOR_OPTIONS,
  ANNOTATION_DEFAULT_ACTION,
  ANNOTATION_HIGHLIGHT_ACTION,
  ANNOTATION_NOTE_ACTION,
  ANNOTATION_DELETE_ACTION,
  ANNOTATION_BOOKMARK_ACTION,
} from '../../../utils/constants'
import DefaultView from './actionMenu/DefaultView'
import HighlightView from './actionMenu/HighlightView'
import NoteView from './actionMenu/NoteView'
import BookmarkView from './actionMenu/BookmarkView'
import PositionMananger from './actionMenu/PositionManager'

import './AnnotationActionMenu.css'

/**
 * Annotation action menu component.
 */
class AnnotationActionMenu extends Component {
  /**
   * Component constructor.
   *
   * @param {object} props - initial component props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** Which page is currently being displayed. */
      visiblePage: props.startingAction,
      /** True if we're in the process of communicating with the server. */
      isSaving: false,
      /** Helper variable that tracks which action is performing the saving. */
      savingAction: null,
    }

    this.changeView = this.changeView.bind(this)
    this.removeAnnotation = this.removeAnnotation.bind(this)
    this.saveHighlight = this.saveHighlight.bind(this)
    this.saveNote = this.saveNote.bind(this)
    this.saveBookmark = this.saveBookmark.bind(this)
    this.renderContent = this.renderContent.bind(this)
  }

  /**
   * Stuff that happens when the component is first rendered.
   */
  componentDidMount() {
    // Since calculation of the position of the menu is dependent on knowing what
    // the menu actually looks like, we can't accurately position the menu until
    // after it is first rendered. So basically we trigger a re-render initially
    // so we can properly place the menu.
    if (this.menu) {
      this.forceUpdate()
    }
  }

  /**
   * Checks new props to see if state should change at the same time.
   *
   * @param {object} nextProps - next component props
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.annotation !== this.props.annotation) {
      this.setState(prevState => ({
        visiblePage: prevState.isSaving
          ? prevState.visiblePage
          : nextProps.startingAction,
        isSaving: false,
        savingAction: null,
      }))
    }
  }

  /**
   * Changes the currently visible view.
   *
   * @param {string} [view=ANNOTATION_DEFAULT_ACTION] - The constant of the view to be displayed.
   */
  changeView(view = ANNOTATION_DEFAULT_ACTION) {
    this.setState({
      visiblePage: view,
    })
  }

  /**
   * Deletes the current annotation and then clears the selection. If the
   * annotation hasn't been saved yet, then we just return.
   */
  removeAnnotation() {
    const { annotation, onAnnotationsUpdate } = this.props

    // We can't delete the annotation if it doesn't exist.
    if (!annotation.id) {
      return
    }
    this.setState({ isSaving: true, savingAction: ANNOTATION_DELETE_ACTION })
    deleteAnnotation(annotation)
      .then(onAnnotationsUpdate)
  }

  /**
   * Applies the given params to the annotation's highlight instance and then
   * saves the updated annotation to the server.
   *
   * @param {object} params - highlight changes
   * @param {boolean} [shouldDelete=false] - if the highlight should be deleted
   * @param {function} [callback] - Function to run after the highlight has been saved.
   */
  saveHighlight(params, shouldDelete = false, callback) {
    const { annotation, onAnnotationsUpdate, selectedText } = this.props

    this.setState({
      isSaving: true,
      savingAction: ANNOTATION_HIGHLIGHT_ACTION,
    })

    if (!annotation || (shouldDelete && !annotation.id)) {
      if (typeof callback === 'function') {
        callback()
      }

      this.setState({
        isSaving: false,
        savingAction: null,
      })

      return
    }

    // Figure out what has been changed.
    const highlight = annotation.highlight || {}
    const colorChanged = (!highlight.color && params.color) || (highlight.color !== params.color)
    const styleChanged = (!highlight.style && params.style) || (highlight.style !== params.style)

    // Return if there's nothing that changed.
    if (!colorChanged && !styleChanged && !shouldDelete) {
      if (typeof callback === 'function') {
        callback()
      }
      this.setState({
        isSaving: false,
        savingAction: null,
      })

      return
    }

    // Build the updated annotation data.
    const data = {
      ...annotation,
      highlight: shouldDelete
        ? null
        : {
          content: selectedText,
          style: HIGHLIGHT_STYLE_OPTIONS[0].value,
          color: HIGHLIGHT_COLOR_OPTIONS[0].value,
          ...highlight,
          ...params,
        },
    }

    // Pick the right save method and then save the changes.
    const saveMethod = annotation.id
      ? updateAnnotation
      : createAnnotation

    saveMethod(data)
      .then((results) => {
        onAnnotationsUpdate(results)

        if (typeof callback === 'function') {
          callback()
        }
      })
  }

  /**
   * Applies the given params to the annotation's highlight instance and then
   * saves the updated annotation to the server.
   *
   * @param {object} params - note changes
   * @param {boolean} [shouldDelete=false] - if the note should be deleted
   * @param {function} [callback] - Function to run after the note has been saved.
   */
  saveNote(params = {}, shouldDelete = false, callback) {
    const { annotation, onAnnotationsUpdate } = this.props

    this.setState({
      isSaving: true,
      savingAction: ANNOTATION_NOTE_ACTION,
    })

    if (!annotation || (shouldDelete && !annotation.id)) {
      if (typeof callback === 'function') {
        callback()
      }

      this.setState({
        isSaving: false,
        savingAction: null,
      })

      return
    }

    // Figure out what has been changed.
    const note = annotation.note || {}

    const titleChanged = (!note.title && params.title) || (note.title !== params.title)
    const textChanged = (!note.text && note.text) || (note.text !== params.text)

    // Return if there's nothing that changed.
    if (!titleChanged && !textChanged && !shouldDelete) {
      if (typeof callback === 'function') {
        callback()
      }
      this.setState({
        isSaving: false,
        savingAction: null,
      })

      return
    }

    // Build the updated annotation data.
    const data = {
      ...annotation,
      note: shouldDelete
        ? null
        : {
          title: '',
          text: '',
          ...note,
          ...params,
        },
    }

    // Clear the default highlight if it doesn't exist as it will kill the
    // validator.
    if (data.highlight && !(data.highlight.color && data.highlight.style)) {
      data.highlight = null
    }

    // Pick the right save method and then save the changes.
    const saveMethod = annotation.id
      ? updateAnnotation
      : createAnnotation

    saveMethod(data)
      .then((results) => {
        onAnnotationsUpdate(results)

        if (typeof callback === 'function') {
          callback()
        }
      })
  }

  /**
   * Saves the bookmark.
   *
   * @param {object} params - bookmark changes
   * @param {boolean} [shouldDelete=false] - if the bookmark should be deleted
   * @param {function} [callback] - Function to run after the bookmark has been saved.
   */
  saveBookmark(params = {}, shouldDelete = false, callback) {
    const { annotation, onAnnotationsUpdate } = this.props

    this.setState({
      isSaving: true,
      savingAction: ANNOTATION_BOOKMARK_ACTION,
    })

    if (!annotation || (shouldDelete && !annotation.id)) {
      if (typeof callback === 'function') {
        callback()
      }

      this.setState({
        isSaving: false,
        savingAction: null,
      })

      return
    }

    // Figure out what has been changed.
    const bookmark = annotation.bookmark || {}

    const titleChanged = (!bookmark.name && params.name) || (bookmark.name !== params.name)

    // Return if there's nothing that changed.
    if (!titleChanged && !shouldDelete) {
      if (typeof callback === 'function') {
        callback()
      }
      this.setState({
        isSaving: false,
        savingAction: null,
      })

      return
    }

    // Build the updated annotation data.
    const data = {
      ...annotation,
      bookmark: shouldDelete
        ? null
        : {
          title: '',
          ...bookmark,
          ...params,
        },
    }

    // Clear the default highlight if it doesn't exist as it will kill the
    // validator.
    if (data.highlight && !(data.highlight.color && data.highlight.style)) {
      data.highlight = null
    }

    // Pick the right save method and then save the changes.
    const saveMethod = annotation.id
      ? updateAnnotation
      : createAnnotation

    saveMethod(data)
      .then((results) => {
        onAnnotationsUpdate(results)

        if (typeof callback === 'function') {
          callback()
        }
      })
  }

  /**
   * Renders the right content of the action menu based on the
   * current visiblePage state.
   *
   * @returns {function} Component
   */
  renderContent() {
    const { annotation, bookTitle, chapterTitle } = this.props
    const { visiblePage, isSaving, savingAction } = this.state

    const canDelete = !!(annotation && annotation.id)
    const highlight = annotation.highlight || {}
    const bookmark = annotation.bookmark || {}
    const hasHighlight = !!(highlight && highlight.color)
    const note = annotation.note || {}
    const hasNote = !!(note.id)
    const defaultHighlight = {
      color: HIGHLIGHT_COLOR_OPTIONS[0].value,
      style: HIGHLIGHT_STYLE_OPTIONS[0].value,
    }

    switch (visiblePage) {
      case ANNOTATION_HIGHLIGHT_ACTION:
        return (
          <HighlightView
            highlight={highlight}
            onBack={() => this.changeView(ANNOTATION_DEFAULT_ACTION)}
            onSave={this.saveHighlight}
            isLoading={isSaving}
          />
        )
      case ANNOTATION_NOTE_ACTION:
        return (
          <NoteView
            note={note}
            onBack={() => this.changeView(ANNOTATION_DEFAULT_ACTION)}
            onSave={this.saveNote}
            isLoading={isSaving}
          />
        )
      case ANNOTATION_BOOKMARK_ACTION:
        return (
          <BookmarkView
            bookmark={bookmark}
            defaultName={chapterTitle || bookTitle}
            onBack={() => this.changeView(ANNOTATION_DEFAULT_ACTION)}
            onSave={this.saveBookmark}
            isLoading={isSaving}
          />
        )
      default:
        return (
          <DefaultView
            activeAction={savingAction}
            canDelete={canDelete}
            hasHighlight={hasHighlight}
            hasNote={hasNote}
            changeView={this.changeView}
            createHighlight={() => this.saveHighlight(defaultHighlight)}
            deleteAnnotation={this.removeAnnotation}
          />
        )
    }
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const { annotation, offset } = this.props

    if (!annotation) {
      return false
    }

    // @fixme @todo
    // Ok this is really bad and not the way we should be doing it but here we are.
    // So for whatever reason when calling `getBoundingClientRect` on the containing
    // iframe, the `left` value seem to be ok, but the `top` is really weird and a lot
    // of times negative, also for some reason it isn't based on the top left of the
    // page, but is affected by padding and other dom elements. So what this is doing
    // is just trying its best to determine the actual `top` value for the `getBoundingClientRect`
    // if the dom changes in the future the way the menu is positioned could break though
    // so we should fix this.
    const mediaQuery = window.matchMedia('(min-width: 1088px)')
    const topNavbarHeight = 52
    const containerPadding = 24
    const top = mediaQuery.matches
      ? topNavbarHeight + containerPadding
      : containerPadding

    return (
      <PositionMananger
        defaultOptions={{
          height: 52,
          spaceBetween: 60,
          paddingTop: 100,
          paddingBottom: 100,
          paddingLeft: 50,
          paddingRight: 50,
          offsetTop: top,
          offsetLeft: offset.left,
        }}
      >
        {({ calculateBestPosition }) => {
          // Calculate the best position for the menu in relation to the annotation.
          const bestPosition = calculateBestPosition(this.menu, annotation.range.nativeRange)

          return (
            <div
              className="AnnotationActionMenu box has-shadow is-paddingless"
              ref={(menu) => {
                this.menu = menu
              }}
              style={{
                transform: `translate(${bestPosition.left}px, ${bestPosition.top}px)`,
              }}
            >
              {this.renderContent()}
            </div>
          )
        }}
      </PositionMananger>

    )
  }
}

AnnotationActionMenu.defaultProps = {
  bookTitle: '',
  chapterTitle: '',
  selectedText: '',
  annotation: {},
  onAnnotationsUpdate: () => {},
  startingAction: ANNOTATION_DEFAULT_ACTION,
  offset: {},
}

AnnotationActionMenu.propTypes = {
  /** Title of book/collection of documents */
  bookTitle: PropTypes.string,
  /** Title of chapter/document */
  chapterTitle: PropTypes.string,
  /** Current text that is selected. Highlights won't work witout this. */
  selectedText: PropTypes.string,
  /** Current annotation object. */
  annotation: PropTypes.shape({}),
  /** Callback for after a change is made to the annotation. */
  onAnnotationsUpdate: PropTypes.func,
  /** Initial action to display when the menu is opened. */
  startingAction: PropTypes.string,
  /** DomRect info for the container so we know how to offset the menu when rendering it. */
  offset: PropTypes.shape({}),
}

export default AnnotationActionMenu
