import React from 'react'
import PropTypes from 'prop-types'
import URI from 'urijs'
import LoadingIndicator from '../components/LoadingIndicator'
import PageLayout from '../layouts/DefaultLayout'
import { Title } from '../dbWebUI'
import { getAudiobookManifest, getMedia, reportAudiobookPosition } from '../api/media'
import './ListeningPage.css'
import {
  AudiobookHandler,
  AudiobookControls,
  UsageTracker,
  PopupPlayer,
  ChapterListing,
} from '../components/media'
import ErrorComponent from '../components/ErrorComponent'
import { AUDIOBOOK_LAST_POSITION_PARAM } from '../utils/constants'

/**
 * Default page state.
 *
 * @returns {object} default state
 */
const cleanListenerState = () => ({
  audiobook: {},
  mediaInfo: null,
  chapter: null,
  trackIndex: 0,
  loading: true,
  error: null,
  lastPosition: null,
  showChapters: false,
})

/**
 * Audiobook listener page.
 */
export default class AudioBookListener extends React.Component {
  /**
   * Component constructor
   */
  constructor() {
    super()

    this.state = cleanListenerState()

    this.renderChapterTitle = this.renderChapterTitle.bind(this)
    this.getDescriptionText = this.getDescriptionText.bind(this)
    this.handleNextChapter = this.handleNextChapter.bind(this)
    this.handleExpandDescription = this.handleExpandDescription.bind(this)
    this.handleGoToProductPage = this.handleGoToProductPage.bind(this)
    this.handleReload = this.handleReload.bind(this)
    this.getLastPosition = this.getLastPosition.bind(this)
    this.handleReportLastPostion = this.handleReportLastPostion.bind(this)
    this.renderChapterListings = this.renderChapterListings.bind(this)
    this.toggleChapters = this.toggleChapters.bind(this)
    this.handlePreviousChapter = this.handlePreviousChapter.bind(this)
  }

  /**
   * Initializes data on mount.
   */
  componentDidMount() {
    const { match } = this.props
    // get relevant audiobook info
    const promises = [null, null]

    promises[0] = new Promise((resolve) => {
      getAudiobookManifest(match.params)
        .then((audiobook) => {
          resolve(audiobook)
        })
        .catch(error => this.setState({ error, loading: false }))
    })

    promises[1] = new Promise((resolve) => {
      getMedia({ id: match.params.id })
        .then((mediaInfo) => {
          resolve(mediaInfo)
          this.setState({
            mediaInfo,
            lastPosition: this.getLastPosition(mediaInfo),
          })
        })
        .catch(error => this.setState({ error, loading: false }))
    })

    Promise.all(promises)
      .then((values) => {
        const lastTrackIndex = this.getLastPosition(values[1]).item
        const { chapters } = values[0]
        const chapter = chapters[lastTrackIndex || 0] || {}

        this.setState({
          audiobook: values[0],
          chapter,
          trackIndex: lastTrackIndex || 0,
          error: null,
          loading: false,
        })
      })
      .catch((error) => {
        this.setState({ error, loading: false })
      })
  }

  /**
   * Parses the book's description.
   *
   * @returns {object} Props to apply based on unsafe HTML of description.
   */
  getDescriptionText() {
    const innerHtml
      = (this.state.mediaInfo
        && this.state.mediaInfo.description
        && this.state.mediaInfo.description)
      || ''

    return {
      __html: innerHtml,
    }
  }

  /**
   * Parses last position from url.
   *
   * @param {object} [mediaInfo=this.state.mediaInfo] - Where to find the last position from.
   *
   * @returns {object} - last listened to position in the book
   * @property {number} seconds The seconds into the selected track
   * @property {number} item The selected track number
   */
  getLastPosition(mediaInfo = this.state.mediaInfo) {
    try {
      return JSON.parse(atob(URI().search(true)[AUDIOBOOK_LAST_POSITION_PARAM]))
    } catch (e) {
      // pass
    }

    try {
      const { lastPosition } = mediaInfo.audiobook.userInfo

      return lastPosition
    } catch (e) {
      // pass
    }

    return null
  }

  /**
   * Saves last listened to position to server and/or url.
   *
   * @param {number} seconds - position in seconds
   * @param {boolean} shouldReportToServer - If position should also be synced with server
   */
  handleReportLastPostion(seconds, shouldReportToServer) {
    const { mediaInfo, trackIndex: item } = this.state
    const { history, location } = this.props
    const params = {
      ...URI().search(true),
      [AUDIOBOOK_LAST_POSITION_PARAM]: btoa(JSON.stringify({ seconds, item })),
    }

    history.replace({
      ...location,
      pathname: location.pathname,
      search: URI().search(params).search(),
    })

    if (shouldReportToServer) {
      try {
        const { mediaId, sku } = mediaInfo.audiobook

        reportAudiobookPosition({
          id: mediaId,
          sku,
          item,
          seconds,
        })
      } catch (e) {
        // mediaId or sku do not exist
      }
    }
  }

  /**
   * Handles when a new chapter is selected.
   */
  handleSelectChapter({ selectedChapter, index, callback }) {
    this.setState(
      {
        chapter: selectedChapter,
        trackIndex: index,
      },
      callback,
    )
  }

  /**
   * Mimics a page reload.
   */
  handleReload() {
    this.setState(cleanListenerState(), this.componentDidMount)
  }

  /**
   * Event handler for when the book description should be toggled open/closed.
   */
  handleExpandDescription() {
    this.setState(prevState => ({
      ...prevState,
      isDescriptionExpanded: !prevState.isDescriptionExpanded,
    }))
  }

  /**
   * Opens the product's purchase page in a separate window.
   */
  handleGoToProductPage() {
    try {
      if (this.state.mediaInfo.ebook.productUrl) {
        window.open(this.state.mediaInfo.ebook.productUrl)
      }
    } catch (error) {
      // productUrl does not exist
    }
  }

  /**
   * Handler for when a chapter ends.
   * @param {Function} playCallback A callback to play the next media
   */
  handleNextChapter() {
    const { audiobook } = this.state

    if (
      audiobook.chapters
      && audiobook.chapters.length > this.state.trackIndex + 1
    ) {
      this.setState(prevState => ({
        trackIndex: prevState.trackIndex + 1,
        chapter: audiobook.chapters[prevState.trackIndex + 1],
      }))
    }
  }

  /**
   * Moves to previous chapter.
   */
  handlePreviousChapter() {
    const { audiobook } = this.state

    if (this.state.trackIndex - 1 > 0) {
      this.setState(prevState => ({
        trackIndex: prevState.trackIndex - 1,
        chapter: audiobook.chapters[prevState.trackIndex - 1],
      }))
    }
  }

  /**
   * Toggles chapters display.
   */
  toggleChapters() {
    this.setState(prevState => ({
      ...prevState,
      showChapters: !prevState.showChapters,
    }))
  }

  /**
   * Determines how to render the chapter's title
   *
   * @returns {function} Component
   */
  renderChapterTitle() {
    const mediaInfo = this.state.mediaInfo || {}

    return (
      <React.Fragment>
        <Title is4>{mediaInfo.title}</Title>
        {mediaInfo
          && mediaInfo.authors
          && mediaInfo.authors
            .map(({ firstName, lastName }) => `${firstName} ${lastName}`)
            .join(', ')}
      </React.Fragment>
    )
  }

  /**
   * Determines how to render the list of chapters.
   *
   * @param {function} [play=()=>{}] - Callback for when a chapter list item is clicked.
   * @returns {function} Component
   */
  renderChapterListings(play = () => {}) {
    const { chapter } = this.state
    const chapters = this.state.audiobook.chapters || []

    return (
      <ChapterListing
        chapters={chapters}
        onSelectChapter={params => this.handleSelectChapter({ ...params, callback: play })}
        currentChapter={chapter}
      />
    )
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const {
      chapter, error, trackIndex, lastPosition, mediaInfo,
    } = this.state

    if (this.state.loading) {
      return <LoadingIndicator />
    }

    if (error) {
      return (
        <PageLayout>
          <ErrorComponent error={error} onReload={this.handleReload} />
        </PageLayout>
      )
    }

    const subscribed = this.state.mediaInfo
      && this.state.mediaInfo.audiobook
      && this.state.mediaInfo.audiobook.subscriptionPlanIds
      && this.state.mediaInfo.audiobook.subscriptionPlanIds.length > 0

    return (
      <UsageTracker
        mediaData={{
          sku: (this.state.audiobook && this.state.audiobook.sku) || '',
          id: (this.state.audiobook && this.state.audiobook.mediaId) || '',
          subscribed,
        }}
        startOnMount={false}
      >
        {({ start: startTracking, pause: pauseTracking }) => (
          <AudiobookHandler
            media={this.state.chapter}
            duration={(chapter && chapter.length) || 0}
            seekTime={30}
            onPlay={startTracking}
            onPause={pauseTracking}
            startOnMount={false}
            lastPosition={lastPosition}
            onReportPosition={this.handleReportLastPostion}
            currentTrackIndex={trackIndex}
            onNextTrack={this.handleNextChapter}
            onPreviousTrack={this.handlePreviousChapter}
          >
            {({
              previous,
              loading,
              next,
              pause,
              progress,
              duration,
              seekTo,
              isPlaying,
              play,
              handleChangeVolume,
              volume,
              handleChangeRate,
              rate,
              onPreviousTrack,
              onNextTrack,
            }) => (
              <PopupPlayer
                mediaInfo={mediaInfo}
                chapters={this.state.audiobook.chapters || []}
                currentChapter={this.state.chapter}
                onSelectChapter={params => this.handleSelectChapter({ ...params, callback: play })}
              >
                <AudiobookControls
                  play={play}
                  pause={pause}
                  next={next}
                  previous={previous}
                  progress={progress}
                  duration={duration}
                  seekTo={seekTo}
                  isPlaying={isPlaying}
                  volume={volume}
                  onChangeVolume={handleChangeVolume}
                  rate={rate}
                  isLoading={loading}
                  onChangeRate={handleChangeRate}
                  onChangeHeight={this.handleHeightChange}
                  onPreviousTrack={onPreviousTrack}
                  onNextTrack={onNextTrack}
                />
              </PopupPlayer>
            )}
          </AudiobookHandler>
        )}
      </UsageTracker>
    )
  }
}

AudioBookListener.propTypes = {
  match: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
}
