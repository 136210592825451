import { TOKEN_STORAGE_KEY } from '../constants'


/**
 * Checks if the current user is authenticated.
 *
 * @returns {boolean} User is authenticated
 */
export default function isAuthenticated() {
  return Boolean(localStorage.getItem(TOKEN_STORAGE_KEY))
}
