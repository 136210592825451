import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { createAnnotation } from '../../../api/media'
import { Navbar, Box, AddIcon, IconWrapper } from '../../../dbWebUI'
import BookmarkForm from './actionMenu/BookmarkForm'
import BookmarkList from './BookmarkList'
import { sortAnnotationsByPosition } from '../../../utils/media'

import './BookmarkSidebarContent.css'

/**
 * Component for displaying a list of bookmarks.inside a `Sidebar`.
 */
class BookmarkSidebarContent extends Component {
  /**
   * Component constructor.
   *
   * @param {object} props - component props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** If a new bookmark is being created. */
      isCreating: false,
      /** If a new bookmark is currently being saved. */
      isSaving: false,
      /** New bookmark annotation values. */
      newBookmark: {
        name: props.defaultName,
      },
    }

    this.getBookmarks = this.getBookmarks.bind(this)
    this.toggleCreateForm = this.toggleCreateForm.bind(this)
    this.onCreateBookmark = this.onCreateBookmark.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  /**
   * Updates state based on changing props.
   *
   * @param {object} nextProps - Next component props
   */
  componentWillReceiveProps(nextProps) {
    // When switching from not open to open, we'll want to reset state to its
    // initial value.
    if (nextProps.isOpen && !this.props.isOpen) {
      this.setState({
        isCreating: false,
        isSaving: false,
        newBookmark: {
          name: nextProps.defaultName,
        },
      })
    }
  }

  /**
   * On submit handler for the `BookmarkForm`. Takes the given bookmark info, and
   * calculates the most relevant BLR and creates a new annotation based on that
   * bookmark information.
   *
   * @param {event} e - submit event
   */
  onCreateBookmark(e) {
    e.preventDefault()

    const { bookId, documentId, calculateBlr, onCreate } = this.props
    const { newBookmark, isSaving } = this.state
    const isValid = !!newBookmark.name && !!bookId && !!documentId
    // Since we're not contextually adding the bookmark, we have to calculate a
    // BLR to use.
    const blr = calculateBlr()

    if (!blr || !blr.length || isSaving || !isValid) {
      return
    }

    // Set a limbo state so we can indicate the form is being submitted, then
    // run the callback so we actually save the changes.
    this.setState(
      {
        isSaving: true,
      },
      () => {
        createAnnotation({
          bookId,
          documentId,
          startBlr: blr,
          endBlr: blr,
          bookmark: newBookmark,
        }).then((results) => {
          this.setState({ isSaving: false, isCreating: false })
          onCreate(results)
        })
      },
    )
  }

  /**
   * `onChange` handler for `<BookmarkForm />` inputs. This method parses an input
   * change event and applies the changes to the editable bookmark data stored in state.
   *
   * @param {Event} e - input change event
   */
  onChangeHandler(e) {
    const { isSaving } = this.state
    const { name, value } = e.target

    // Don't do anything if we're in limbo.
    if (isSaving) {
      return
    }

    // Store the changes.
    this.setState((prevState) => ({
      newBookmark: {
        ...prevState.newBookmark,
        [name]: value,
      },
    }))
  }

  /**
   * Parses and flattens annotations to return an ordered list of bookmarks.
   *
   * @returns {Array<object>} Flattened list of annotations containing bookmarks.
   */
  getBookmarks() {
    const { annotations, manifest } = this.props

    const reduced = Object.keys(annotations)
      // Flatten and filter annotations to only be a single list of annotations with bookmarks.
      .reduce((currentBookmarks, chapterId) => {
        const chapterBookmarks = annotations[chapterId].filter(
          (annotation) => !!annotation.bookmark,
        )

        return [...currentBookmarks, ...chapterBookmarks]
      }, [])

    // Sort the list of bookmarks by their position in the book.
    const sorted = reduced.sort((bookmarkA, bookmarkB) =>
      sortAnnotationsByPosition(bookmarkA, bookmarkB, manifest),
    )

    return sorted
  }

  /**
   * Toggles the visibility of the create bookmark form.
   */
  toggleCreateForm() {
    this.setState((prevState) => ({ isCreating: !prevState.isCreating }))
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const {
      bookId,
      documentId,
      className,
      tableOfContents,
      onClick,
      onCreate,
      calculateBlr,
      defaultName,
      isOpen,
      ...rest
    } = this.props

    const { isCreating, isSaving, newBookmark } = this.state

    const bookmarks = this.getBookmarks()

    const classes = classNames('BookmarkSidebarContent', className)

    return (
      <div className={classes} {...rest}>
        <Navbar isPrimary hasShadow isMobile>
          <Navbar.Brand>
            <Navbar.StaticItem>{isCreating ? 'New Bookmark' : 'Bookmarks'}</Navbar.StaticItem>
          </Navbar.Brand>
          <Navbar.End>
            <Navbar.Item
              hasTooltip={!isCreating}
              hasTooltipLeft
              data-tooltip="New Bookmark"
              onClick={this.toggleCreateForm}
            >
              {/* Show either an "add icon" or "cancel" based on if we're in edit more or not. */}
              {isCreating ? (
                <span>Cancel</span>
              ) : (
                <IconWrapper>
                  <AddIcon />
                </IconWrapper>
              )}
            </Navbar.Item>
          </Navbar.End>
        </Navbar>
        {/* Show the create form if we're in create mode, otherwise show the list of bookmarks. */}
        {isCreating ? (
          <Box isShadowless>
            <BookmarkForm
              onSubmit={this.onCreateBookmark}
              onChange={this.onChangeHandler}
              bookmark={newBookmark}
              isSubmitting={isSaving}
              isValid={!!newBookmark.name}
            />
          </Box>
        ) : (
          <BookmarkList
            bookmarks={bookmarks}
            tableOfContents={tableOfContents}
            onBookmarkClick={onClick}
            onEmptyListClick={this.toggleCreateForm}
          />
        )}
      </div>
    )
  }
}

BookmarkSidebarContent.defaultProps = {
  annotations: {},
  tableOfContents: {},
  manifest: {},
  defaultName: '',
  isOpen: false,
  onClick: () => {},
  onCreate: () => {},
  className: '',
}

BookmarkSidebarContent.propTypes = {
  /** Id of current book. */
  bookId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** Id of currently displayed document. */
  documentId: PropTypes.string.isRequired,
  /** Function to calculate the proper BLR for a new bookmark. */
  calculateBlr: PropTypes.func.isRequired,
  /** Default name of a new bookmark. */
  defaultName: PropTypes.string,
  /** An Object of annotations that will be sorted/filtered to only display bookmarks. */
  annotations: PropTypes.shape({}),
  /** An Object with relevant table of contents/chapter information. */
  tableOfContents: PropTypes.shape({}),
  /** An Object with relevant table of contents/page information. */
  manifest: PropTypes.shape({}),
  /** True if the list is currently visible. */
  isOpen: PropTypes.bool,
  /** Event handler for when a bookmark in the list is clicked. */
  onClick: PropTypes.func,
  /** Event handler for when a new bookmark is created. */
  onCreate: PropTypes.func,
  /** Additional classname to be applied. */
  className: PropTypes.string,
}

export default BookmarkSidebarContent
