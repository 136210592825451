import React from 'react'
import PageLayout from '../layouts/DefaultLayout'


/**
 * 404 - Page Not Found
 *
 * @returns {function} Component
 */
function PageNotFound() {
  return (
    <PageLayout>
      Page not found!
    </PageLayout>
  )
}

export default PageNotFound
