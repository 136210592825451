import React from 'react'
import PropTypes from 'prop-types'
import { Notification, Content } from '../dbWebUI'
import { logout } from '../api/users'

/**
 * Generic error message. Used when an error gets caught by another component.
 *
 * @returns {function} Component
 */
export default function ErrorComponent({ error }) {
  if (!error) {
    return null
  }

  // If this is a token error we'll just logout so the user has to login again.
  if (error.name === 'TokenError') {
    logout()
    window.location.reload()

    return false
  }

  return (
    <Notification isWarning>
      <Content>
        Sorry, something went wrong. You can reload the page, or try again later.<br />
        <strong>Error:</strong> {error.message}
      </Content>
    </Notification>
  )
}

ErrorComponent.defaultProps = {
  error: null,
}

ErrorComponent.propTypes = {
  error: PropTypes.shape({}),
}
