import React from 'react'
import PropTypes from 'prop-types'
import { Button, IconWrapper, PaletteIcon, HighlightIcon, NewNoteIcon, NoteIcon, TrashIcon, BookmarkIcon } from '../../../../dbWebUI'
import { ANNOTATION_NOTE_ACTION, ANNOTATION_HIGHLIGHT_ACTION, ANNOTATION_DELETE_ACTION, ANNOTATION_BOOKMARK_ACTION } from '../../../../utils/constants'


/**
 * Default `AnnotationActionMenu` view for displaying all the available options
 * related to the selected annotation. It should be used as a child of
 * `AnnotationActionMenu`.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function DefaultView({
  activeAction,
  canDelete,
  hasHighlight,
  hasNote,
  changeView,
  createHighlight,
  deleteAnnotation,
}) {
  return (
    <div className="DefaultContent">
      {/** Create/Edit Highlight Button. */}
      <Button
        isWhite
        hasLabeledIcon
        isLoading={activeAction === ANNOTATION_HIGHLIGHT_ACTION}
        onClick={
          hasHighlight
            ? () => changeView(ANNOTATION_HIGHLIGHT_ACTION)
            : createHighlight
        }
      >
        <IconWrapper>
          {hasHighlight
            ? <PaletteIcon />
            : <HighlightIcon />}
        </IconWrapper>
        <p className="heading">
          {hasHighlight
            ? 'Style'
            : 'Mark'}
        </p>
      </Button>

      {/** Edit Note Button */}
      <Button
        isWhite
        hasLabeledIcon
        isLoading={activeAction === ANNOTATION_NOTE_ACTION}
        onClick={() => changeView(ANNOTATION_NOTE_ACTION)}
      >
        <IconWrapper>
          {hasNote
            ? <NoteIcon />
            : <NewNoteIcon />}
        </IconWrapper>
        <p className="heading">note</p>
      </Button>

      {/** Bookmark Button */}
      <Button
        isWhite
        hasLabeledIcon
        isLoading={activeAction === ANNOTATION_BOOKMARK_ACTION}
        onClick={() => changeView(ANNOTATION_BOOKMARK_ACTION)}
      >
        <IconWrapper>
          <BookmarkIcon />
        </IconWrapper>
        <p className="heading">bookmark</p>
      </Button>

      {/** Only display the edit button if the annotation exists. */}
      {canDelete ? (
        <Button
          isWhite
          isBorderless
          hasLabeledIcon
          isLoading={activeAction === ANNOTATION_DELETE_ACTION}
          onClick={deleteAnnotation}
        >
          <IconWrapper>
            <TrashIcon />
          </IconWrapper>
          <p className="heading">delete</p>
        </Button>
      ) : false}
    </div>
  )
}

DefaultView.defaultProps = {
  activeAction: null,
  canDelete: false,
  hasHighlight: false,
  hasNote: false,
  changeView: () => {},
  createHighlight: () => {},
  deleteAnnotation: () => {},
}

DefaultView.propTypes = {
  /** Which action is loading or being displayed. */
  activeAction: PropTypes.string,
  /** True if the annotation exists and is editable. */
  canDelete: PropTypes.bool,
  /** True if a highlight exists and is editable. */
  hasHighlight: PropTypes.bool,
  /** True if a note exists and is editable. */
  hasNote: PropTypes.bool,
  /** Callback for changing the `AnnotationActionMenu` view. */
  changeView: PropTypes.func,
  /** Callback for creating a new highlight. */
  createHighlight: PropTypes.func,
  /** Callback for deleting an existing annotation. */
  deleteAnnotation: PropTypes.func,
}

export default DefaultView
