/**
 * Flattens and sorts an eBook TOC. Assumes the TOC was a response from {@link getEbookToc}.
 *
 * @example
 * getEbookToc({ id, sku })
 *   .then(toc => {
 *     const flattened = flattenEbookToc(toc)
 *   })
 *
 * @param {Array<object>} toc - Array of TOC nav points
 * @returns {object} Flattened TOC with key as index of each TOC nav points
*/
export default function flattenEbookToc(toc) {
  if (!toc) return {}

  return toc
    .reduce((list, nav) => {
      // Remove any children from the navItem
      const navItem = nav
      const children = navItem.nested

      // Flatten any children and add them in as well.
      const flatChildren = flattenEbookToc(children)

      return {
        ...list,
        ...flatChildren,
        [navItem.order]: navItem,
      }
    }, {})
}
