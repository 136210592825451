import { getMedia as sdkGetMedia } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Retrieves information about a single media item. The item is not required to be
 * in the users library.
 *
 * @example
 * getMedia({ id })
 *   .then(media => console.log(media))
 *
 * @see https://deseretbookwiki.atlassian.net/wiki/spaces/DB/pages/33030/Deseret+Bookshelf+API+Reference#DeseretBookshelfAPIReference-Getinformationaboutasinglemediaitem
 *
 * @param {object} [params={}] - media request parameters
 * @param {string} params.id - media id
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getMedia(params = {}) {
  return sdkGetMedia(params, requestOptions())
}
