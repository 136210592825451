import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { throttle } from '../utils/base'

import './DefaultLayout.css'

import { Section, Container } from '../dbWebUI'
import MainHeader from '../components/MainHeader'

/**
* Default layout containing a header and footer.
*/
class DefaultLayout extends Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.yPosition = 0

    this.state = {
      scrollDirection: null,
    }

    this.onScrollHandler = this.onScrollHandler.bind(this)
    this.throttledOnScrollHandler = throttle(this.onScrollHandler, 200)
  }

  /**
   * Component initializer. Adds event listeners.
   */
  componentDidMount() {
    window.addEventListener('scroll', this.throttledOnScrollHandler, false)
  }

  /**
   * Removes event listeners before the component goes bye-bye.
   */
  componentWillUnmount() {
    window.removeEventListener('scroll', this.throttledOnScrollHandler)
  }

  /**
   * Handles an on scroll event. Compares the previous vertical scroll position
   * with the current scroll position, if the difference between the two is more
   * than the specified `minimumScrollDistance`, then the navbar is either set to
   * be fixed at the top of the screen if the user is scrolling upwards, or static
   * at the top of the page if the user is scrolling downwards.
   */
  onScrollHandler() {
    const { scrollDirection } = this.state
    const { minimumScrollDistance } = this.props
    const hasScrolledDown = window.scrollY - this.yPosition > minimumScrollDistance
    const hasScrolledUp = this.yPosition - window.scrollY > minimumScrollDistance

    if (!hasScrolledUp && !hasScrolledDown) {
      return
    }

    if (hasScrolledUp && scrollDirection !== 'up') {
      this.setState({ scrollDirection: 'up' })
    } else if (hasScrolledDown && scrollDirection !== 'down') {
      this.setState({ scrollDirection: 'down' })
    }

    this.yPosition = window.scrollY
  }

  /**
   * Determines how the component is rendered.
   *
   * @returns {function} Component
   */
  render() {
    const {
      className,
      children,
      secondaryNav,
      headerProps,
      minimumScrollDistance,
      isFluid,
      ...rest
    } = this.props
    const { scrollDirection } = this.state

    const classes = classNames(
      'DefaultLayout',
      className,
      scrollDirection
        ? `is-scrolling-${scrollDirection}`
        : '',
    )


    return (
      <div className={classes} {...rest}>
        <MainHeader {...headerProps} />
        {secondaryNav ? (
          <div className="secondary-nav">
            {secondaryNav}
          </div>
        ) : false}
        <main>
          <Section>
            <Container isFluid={isFluid}>
              {children}
            </Container>
          </Section>
        </main>
      </div>
    )
  }
}

DefaultLayout.defaultProps = {
  className: '',
  children: false,
  secondaryNav: false,
  minimumScrollDistance: 20,
  headerProps: {},
  isFluid: false,
}

DefaultLayout.propTypes = {
  /** Additional classes for layout container */
  className: PropTypes.string,
  /** Page content */
  children: PropTypes.node,
  /** Secondary navbar component */
  secondaryNav: PropTypes.node,
  /** minimum change in scroll position for navbar toggling to take effect (in pixels) */
  minimumScrollDistance: PropTypes.number,
  /** Props passed to `<MainHeader />` component. */
  headerProps: PropTypes.shape({}),
  /** Whether the container should be marginless */
  isFluid: PropTypes.bool,
}

export default DefaultLayout
