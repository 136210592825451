import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

/**
 * Generic wrapper for inline svgs. Used to embed logos and icons. Should be
 * used with an `IconWrapper`.
 *
 * @see https://dmfrancisco.github.io/react-icons/
 * @todo update documentation
 *
 * @returns {function} Component
 */
function Icon({
  className,
  style,
  children,
  icon,
  color,
  title,
  description,
  viewBox,
  ...rest
}) {
  const classes = classNames('svg-icon', {
    [`is-${icon}`]: icon.length,
    [`has-fill-${color}`]: color.length,
  }, className)
  const newStyle = {
    flex: 1,
    fill: 'currentColor',
    ...style,
  }

  const labelledBy = classNames({
    title: title.length,
    desc: description.length,
  })

  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      aria-labelledby={labelledBy}
      viewBox={viewBox}
      style={newStyle}
      className={classes}
      {...rest}
    >
      {title
        ? <title id="title">{title}</title>
        : false}
      {description
        ? <desc id="desc">{description}</desc>
        : false}
      {children}
    </svg>
  )
}

Icon.defaultProps = {
  className: '',
  color: 'default',
  icon: '',
  title: '',
  description: '',
  viewBox: '0 0 24 24',
  style: {},
}

Icon.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  viewBox: PropTypes.string,
  style: PropTypes.shape({}),
}

export default Icon
