import React, { Component } from 'react'
import PropTypes from 'prop-types'
import URI from 'urijs'

import { getEbookContent, getAllEbookInfo } from '../api/media'
import PageLayout from '../layouts/DefaultLayout'
import LoadingIndicator from '../components/LoadingIndicator'
import { SimpleSidebar } from '../dbWebUI'
import {
  BookContent,
  TopToolbar,
  TableOfContents,
  BottomToolbar,
  BookmarkSidebarContent,
} from '../components/media/bookReader'
import { parseBlr } from '../utils/media'
import { UsageTracker } from '../components/media'
import { BLR_ROUTE_PARAM, SRC_ROUTE_PARAM } from '../utils/constants'
import ErrorComponent from '../components/ErrorComponent'

/**
 * Default page state.
 * @returns {object} default state
 */
const cleanReaderState = () => ({
  currentPage: 0,
  page: {},
  annotations: {},
  ebook: {},
  primaryContent: {},
  isLoading: true,
  displayTableOfContents: false,
  displayBookmarks: false,
  error: null,
})

/**
 * Page for reading eBooks
 */
class ReaderPage extends Component {
  /**
   * Component constructor
   */
  constructor() {
    super()

    this.state = cleanReaderState()

    this.cache = {}

    this.getFirstVisibleBlr = () => {}

    this.updateAnnotations = () => {}

    this.onBookmarkClick = this.onBookmarkClick.bind(this)
    this.onBookContentSetup = this.onBookContentSetup.bind(this)
    this.toggleTableOfContentsDisplay = this.toggleTableOfContentsDisplay.bind(this)
    this.toggleBookmarksDisplay = this.toggleBookmarksDisplay.bind(this)
    this.handleReload = this.handleReload.bind(this)

    this.getPage = this.getPage.bind(this)
    this.onNextPage = this.onNextPage.bind(this)
    this.getInitialPage = this.getInitialPage.bind(this)
  }

  /**
   * Initializes data and event handlers on mount.
   */
  componentDidMount() {
    const { match } = this.props

    // Get all the relevant book info.
    getAllEbookInfo(match.params)
      .then((ebook) => {
        this.setState({ ebook }, this.getInitialPage)
      })
      .catch((error) => {
        this.setState({
          error,
          isLoading: false,
        })
      })

    // Add a listener to the url history to figure out where the starting location
    // is at based on the url.
    window.addEventListener('popstate', this.getInitialPage, false)
  }

  /**
   * Cleans up event listeners before the component goes bye-bye.
   */
  componentWillUnmount() {
    // Remove all event listeners.
    window.removeEventListener('popstate', this.getInitialPage)
  }

  /**
   * Determines and gets the next page based on the current one, if there isn't
   * a next page then we stay on the current page. If the `isForward` prop is
   * set to false, then the previous page is selected, but by
   *
   */
  onNextPage({ isForward = true }) {
    const { currentPage, ebook } = this.state

    const nextPosition = currentPage + (isForward ? 1 : -1)

    // If the current page order isn't found or if the requested page isn't
    // in the table of contents, then we'll just not do anything at all.
    if (nextPosition < 0 || !ebook.manifest.items.pages[nextPosition]) {
      return
    }

    const { href } = ebook.manifest.items.pages[nextPosition]

    this.getPage({ src: href })
  }

  /**
   * Event handler for when a bookmark is clicked. Uses the given information
   * to load the proper page and jump to the specified BLR.
   *
   * @param {object} blr - Parsed BLR object.
   * @param {string} documentId - this is the parameter documentId
   */
  onBookmarkClick(blr, documentId) {
    const { ebook } = this.state
    const matchingDocument = ebook.manifest.items.byDocumentId[documentId]

    this.getPage(
      {
        blr,
        src: matchingDocument.href,
      },
      () => this.toggleBookmarksDisplay({ close: true }),
    )
  }

  /**
   * Callback for when the book content is first initialized. This allows us to gain
   * access to methods that the BookContent has access to.
   *
   * @param {object} params - function params
   * @param {object} params.getFirstVisibleBlr - Helper method that calculates the first visible BLR
   * @param {object} params.updateAnnotations - Method that updates the annotations displayed.
   */
  onBookContentSetup({ getFirstVisibleBlr, updateAnnotations }) {
    this.getFirstVisibleBlr = getFirstVisibleBlr
    this.updateAnnotations = updateAnnotations
  }

  /**
   * Determines the best document to display based on any route params, if there
   * aren't any valid route params, then it falls back to the first page found.
   * Once the right document is determined, the document is loaded.
   */
  getInitialPage() {
    const { ebook } = this.state

    // Check the current url for starting position data.
    const params = URI().search(true)

    const mediaInfoBlr = ebook && ebook.userInfo && ebook.userInfo.lastPosition
    // Check if there's a BLR in the url. If so, then load content based on that.
    const startingBlr = params[BLR_ROUTE_PARAM]
      ? parseBlr(atob(params[BLR_ROUTE_PARAM]))
      : parseBlr(mediaInfoBlr)

    if (startingBlr && startingBlr.documentId) {
      const manifestItem = ebook.manifest.items.byDocumentId[startingBlr.documentId]

      this.getPage({ src: manifestItem.href, blr: startingBlr, addToHistory: false })

      return
    }

    // Check if there's a document source in the url. If so, then load content based on that.
    if (params[SRC_ROUTE_PARAM]) {
      this.getPage({ src: atob(params[SRC_ROUTE_PARAM]), addToHistory: false })

      return
    }

    // Fallback to loading the first page found in the table of contents.
    const firstPage = ebook.manifest.items.pages[0]

    this.getPage({ src: firstPage.href, addToHistory: false })
  }

  /**
   * Loads a page's content into the reader based on the settings provided.
   *
   * @param {object} params - getPage parameters
   * @param {string} params.src - page's relative source url.
   * @param {boolean} [params.addToHistory=true] - if the change in page should
   * be added to the route history (only applies to pages found in the TOC)
   * @param {object} [params.blr] - formatter blr object for starting position
   * @param {func} [callback= () => {}] - callback for when this method is completed.
   */
  async getPage({ src, blr, addToHistory = true }, callback = () => {}) {
    // Can't get a page without a `src`
    // `<img src="TapsTempleMeme.jpg" />`
    if (!src) {
      return
    }

    const { match } = this.props
    const { ebook } = this.state

    // Parse and clean the given src so we can properly display the content.
    const uri = new URI(src)
    const anchorId = uri.hash()
    const documentSrc = String(uri.hash(''))

    const matchingDocument = ebook.manifest.items.bySrc[documentSrc]

    const pageIndex = ebook.manifest.items.pages.findIndex((page) => page.href === documentSrc)

    const hasBlr = !!(blr && blr.selector && blr.selector.selector)

    // If there's a matching document and we want to add this to the route
    // history, then we'll add it here.
    if (matchingDocument && addToHistory) {
      const pathParams = blr
        ? { [BLR_ROUTE_PARAM]: btoa(blr.blr) }
        : { [SRC_ROUTE_PARAM]: btoa(src) }

      window.history.pushState(null, null, String(new URI().search(pathParams)))
    }

    // Build the content object we'll send down to the component that displays the content.
    const readerContent = {
      initialPosition: hasBlr ? blr.selector.selector : anchorId,
      documentId: matchingDocument ? matchingDocument.id : null,
      content: this.cache[documentSrc],
    }

    if (!readerContent.content) {
      readerContent.content = await getEbookContent({ ...match.params, href: src }).catch(
        (error) => {
          this.setState({ error })
        },
      )
      this.cache[documentSrc] = readerContent.content
    }

    // Update state with the new results.
    this.setState(
      // eslint-disable-next-line
      (prevState) => ({
        currentPage: pageIndex,
        page: matchingDocument,
        primaryContent: matchingDocument ? readerContent : prevState.primaryContent,
        isLoading: false,
      }),
      () => callback(readerContent.content),
    )
  }

  /**
   * Mimics page reload
   */
  handleReload() {
    this.setState(cleanReaderState(), this.componentDidMount)
  }

  /**
   * Toggles the display of the table of contents, unless `open` or `close` are specified.
   *
   * @param {object} params - function params
   * @param {boolean} [params.open=false] - Force open the table of contents
   * @param {boolean} [params.close=false] - Force close the table of contents
   */
  toggleTableOfContentsDisplay({ open = false, close = false }) {
    this.setState((prevState) => {
      // Default to toggle the current value.
      let displayTableOfContents = !prevState.displayTableOfContents

      // Force open if specified.
      if (open) {
        displayTableOfContents = true
        // Otherwise, force close if specified.
      } else if (close) {
        displayTableOfContents = false
      }

      return { displayTableOfContents }
    })
  }

  /**
   * Toggles the display of the bookmark sidebar.
   *
   * @param {object} params - function params
   * @param {boolean} [params.open=false] - Force open the bookmark sidebar
   * @param {boolean} [params.close=false] - Force close the bookmark sidebar
   */
  toggleBookmarksDisplay({ open = false, close = false }) {
    this.setState((prevState) => {
      // Default to toggle the current value.
      let displayBookmarks = !prevState.displayBookmarks

      // Force open if specified.
      if (open) {
        displayBookmarks = true
        // Otherwise, force close if specified.
      } else if (close) {
        displayBookmarks = false
      }

      return { displayBookmarks }
    })
  }

  /**
   * Determines how to render the component.
   * @returns {function} Component
   */
  render() {
    const {
      annotations,
      primaryContent,
      ebook,
      isLoading,
      displayBookmarks,
      displayTableOfContents,
      error,
      currentPage,
    } = this.state

    if (isLoading) {
      return <LoadingIndicator />
    }

    const totalPages = ebook.manifest.items.pages.length

    // Calculate a percentage of how far along the user is in the book.
    const completionPercentage = ((currentPage + 1) * 100) / totalPages

    // Determine the label to display when referencing the current page.
    const pageCountLabel = `Page ${currentPage + 1} of ${totalPages}`
    const pageCountLabelWithPercentage = `${pageCountLabel} (${completionPercentage.toFixed(0)}%)`

    const topToolbar = (
      <TopToolbar
        ebook={ebook}
        onNext={this.onNextPage}
        onPrevious={() => this.onNextPage({ isForward: false })}
        onToggleBookmarks={() => this.toggleBookmarksDisplay({ open: true })}
        onMenuClick={() => this.toggleTableOfContentsDisplay({ open: true })}
        pageCountLabel={pageCountLabel}
      />
    )

    if (error) {
      return (
        <PageLayout>
          <ErrorComponent error={error} />
        </PageLayout>
      )
    }

    return (
      <UsageTracker
        mediaData={{
          id: ebook.bookId,
          sku: ebook.sku,
          subscribed: ebook.userInfo.subscribed,
        }}
      >
        {({ start: startUsageTracker }) => (
          <PageLayout
            secondaryNav={topToolbar}
            headerProps={{
              className: 'is-hidden-touch',
              navbarProps: {
                isHiddenMobile: false,
              },
            }}
          >
            <div className="read-container">
              <SimpleSidebar
                hasOverlay
                isCloseable
                isOpen={displayTableOfContents}
                onClose={() => this.toggleTableOfContentsDisplay({ close: true })}
              >
                <SimpleSidebar.Content>
                  <TableOfContents
                    isVisible={displayTableOfContents}
                    tableOfContents={ebook.tableOfContents.original}
                    onSelectChapter={(ch) =>
                      this.getPage(ch, this.toggleTableOfContentsDisplay({ close: true }))
                    }
                  />
                </SimpleSidebar.Content>
              </SimpleSidebar>
              <BookContent
                ebook={ebook}
                content={primaryContent}
                onNext={this.onNextPage}
                onPrevious={() => this.onNextPage({ isForward: false })}
                onLinkClick={(src) => this.getPage({ src })}
                onLoadAnnotations={(newAnnotations) =>
                  this.setState({ annotations: newAnnotations })
                }
                onSetup={(setupParams) => {
                  // Pass along any params to the setup callback.
                  this.onBookContentSetup(setupParams)
                  // Initialize the usage tracking and give it the context of usage.
                  startUsageTracker(setupParams.iframeDocument)
                }}
              />
              <SimpleSidebar
                hasOverlay
                isCloseable
                isOpen={displayBookmarks}
                onClose={() => this.toggleBookmarksDisplay({ close: true })}
              >
                <SimpleSidebar.Content isRight>
                  <BookmarkSidebarContent
                    bookId={ebook.bookId}
                    documentId={primaryContent.documentId}
                    annotations={annotations}
                    tableOfContents={ebook.tableOfContents}
                    manifest={ebook.manifest.items}
                    calculateBlr={this.getFirstVisibleBlr}
                    onCreate={(response) => {
                      this.setState({ annotations: response.annotations })
                      this.updateAnnotations(response)
                      this.toggleBookmarksDisplay({ close: true })
                    }}
                    onClick={this.onBookmarkClick}
                    isOpen={displayBookmarks}
                    defaultName={`${ebook.package.metadata.title}: Page - ${currentPage + 1}`}
                  />
                </SimpleSidebar.Content>
              </SimpleSidebar>
            </div>
            <BottomToolbar
              completionPercentage={completionPercentage}
              pageCountLabel={pageCountLabelWithPercentage}
              onNext={this.onNextPage}
              onPrevious={() => this.onNextPage({ isForward: false })}
            />
          </PageLayout>
        )}
      </UsageTracker>
    )
  }
}

ReaderPage.propTypes = {
  /** Route match object received from `Route` */
  match: PropTypes.shape({}).isRequired,
}

export default ReaderPage
