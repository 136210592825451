import React, { useEffect, useState } from 'react'
import { Box, CMSContent, Divider, Text, Button, Icon } from '@deseretbook/react-ui'
import propTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@deseretbook/react-utils'
import PageLayout from '../layouts/DefaultLayout'
import ErrorComponent from '../components/ErrorComponent'
import LoadingIndicator from '../components/LoadingIndicator'
import { readEbookUrl, listenAudiobookUrl } from '../utils/routes'
import { openPopupPlayer } from '../utils/media'
import useMedia from '../hooks/useMedia'
import LearnMoreButton from '../components/buttons/LearnMoreButton'
import ActNowButton from '../components/buttons/ActNowButton'
import SocialLinks from '../components/media/socialLinks/SocialLinks'
import { formatDuration } from '../utils/base'
import { getAudiobookManifest } from '../api/media'
import { getUser } from '../api/users'
import { BOOKSHELF_PLUS_IDS, DIGITAL_VIP_IDS } from '../subscriptionIds'
import InLibraryBadge from '../components/media/inLibraryBadge/InLibraryBadge'

/**
 * Checks whether a subscription id is within a media accessor
 * @param {object} accessor ebook or audiobook object
 * @param {array} subscriptionIds - ids that match a subscription
 * @returns {boolean} whether the subscription ids are found
 */
const checkMediaSubscription = (accessor, subscriptionIds = []) => {
  return !!(
    accessor &&
    Array.isArray(accessor.subscriptionPlanIds) &&
    accessor.subscriptionPlanIds.find((val) => subscriptionIds.indexOf(val) > -1)
  )
}

/**
 * checks if a user has a subscription or not
 *
 * @param {array} userSubIds - list of users subscriptions
 * @param {array} subscriptionIds - List of subscription ids to compare
 * @returns {boolean} Whether the user has a subscription or not
 */
const isUserSubscribed = (userSubIds = [], subscriptionIds = []) => {
  return userSubIds.some((id) => subscriptionIds.includes(id))
}

/**
 * Landing page for Media items
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
const LandingPage = ({ match }) => {
  const { id, sku } = match.params
  const { isLoading, error, media } = useMedia(id)
  const [audiobook, setAudiobook] = useState(null)
  const isMediumOrLarger = useMediaQuery(850)
  // Set media types
  const isEbook = media.ebook && media.ebook.sku === sku
  const isAudiobook = media.audiobook && media.audiobook.sku === sku

  // Subscription IDs
  const plusIds = BOOKSHELF_PLUS_IDS
  const digitalVipIds = DIGITAL_VIP_IDS

  // User Subscriptions
  const { subscriptionPlanIds = [] } = getUser()
  const userPlus = isUserSubscribed(subscriptionPlanIds, plusIds)
  const userVip = isUserSubscribed(subscriptionPlanIds, digitalVipIds)

  /**
   * Fetches the audiobook manifest
   */
  const getAudiobook = async () => {
    try {
      const audiobookResults = await getAudiobookManifest({ id, sku })

      setAudiobook(audiobookResults)
    } catch (e) {
      setAudiobook(null)
    }
  }

  // Get the audiobook manifest if the media type is audiobook
  // This is primarily to display the duration
  useEffect(() => {
    if (isAudiobook && !audiobook) {
      getAudiobook()
    }
    // eslint-disable-next-line
  }, [isAudiobook])

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (error) {
    return (
      <PageLayout>
        <ErrorComponent error={error} />
      </PageLayout>
    )
  }

  // Collection of variables to be used in the component
  const accessor = isEbook ? 'ebook' : 'audiobook'
  const isPlus = checkMediaSubscription(media[accessor], plusIds)

  const { inLibrary = false, subscribed = false, purchased = false } =
    media[accessor] && media[accessor].userInfo

  let url = ''

  if (isEbook) {
    url = readEbookUrl(id, sku)
  } else {
    url = listenAudiobookUrl(id, sku)
  }

  // Decide which button to render
  const buttonProps = isAudiobook
    ? {
        onClick: () => {
          openPopupPlayer(listenAudiobookUrl(id, sku))
        },
      }
    : { to: url, as: Link }

  let actionButton = null

  if (inLibrary || subscribed) {
    actionButton = (
      <ActNowButton
        purchased={purchased}
        buttonProps={buttonProps}
        isAudiobook={isAudiobook}
        isPlus={userPlus}
        isVip={userVip}
      />
    )
  } else if (isPlus && !userPlus) {
    actionButton = <LearnMoreButton />
  }

  // Create a list of authors from the media object.
  const authorList =
    (media &&
      media.authors &&
      Array.isArray(media.authors) &&
      media.authors.length &&
      media.authors.map((author) => `${author.firstName} ${author.lastName}`).join(', ')) ||
    ''

  return (
    <PageLayout>
      <Text as="h1" size="large">
        Deseret Book Library
      </Text>
      <Divider style={{ boxShadow: 'none', borderBottom: '1px solid #d7d7d7' }} />
      <Box
        alignItems={isMediumOrLarger ? 'start' : 'center'}
        justifyContent="start"
        isColumn={!isMediumOrLarger}
      >
        <Box marginRight={2} width={25} minWidth={25} marginBottom={5}>
          <figure className="image has-shadow">
            <img src={media.cover.fullUrl} alt={media.title} />
          </figure>
          {actionButton}
        </Box>
        <Box>
          <Box alignItems="end" justifyContent="start">
            <Text display="inline-block" isBold style={{ fontSize: '1.25rem', lineHeight: 1.2 }}>
              {media.title}
            </Text>
            {isPlus && (
              <Icon
                style={{
                  width: '7rem',
                  minWidth: '7rem',
                  height: '1.25rem',
                  marginLeft: '0.75rem',
                  display: 'inline-block',
                }}
                name="plus"
              />
            )}
          </Box>
          <Text display="block">{authorList}</Text>
          {isAudiobook && (
            <p>
              <Text isSecondary isItalic>
                Duration:&ensp;
              </Text>
              <span>{formatDuration((audiobook && audiobook.length) || 0)}</span>
            </p>
          )}

          <Divider size="small" style={{ boxShadow: 'none', borderBottom: '1px solid #d7d7d7' }} />
          <div>
            <CMSContent
              dangerouslySetInnerHTML={{ __html: media.description }}
              itsNotDangerousBecause="mediaInfo.description comes directly from the Bookshelf API, and should be trustworthy"
            />
          </div>
          <br />
          <Box alignItems="center">
            <Button
              as="a"
              href={`${process.env.REACT_APP_DESERET_BOOK_BASE_URL}/product/${sku}.html`}
              target="_blank"
              style={{ color: '#00d1b2', borderColor: '#00d1b2' }}
            >
              Learn More
            </Button>
            {inLibrary && <InLibraryBadge isPlus={isPlus} isVip={userVip} purchased={purchased} />}
          </Box>
          {isAudiobook && (
            <Box marginVertical={1}>
              <SocialLinks media={media} />
            </Box>
          )}
        </Box>
      </Box>
    </PageLayout>
  )
}

LandingPage.defaults = {
  match: {
    params: {
      id: '',
      sku: '',
    },
  },
}

LandingPage.propTypes = {
  match: propTypes.shape({
    params: propTypes.shape({
      id: propTypes.string,
      sku: propTypes.string,
    }),
  }).isRequired,
}

export default LandingPage
