import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, IconWrapper, NoteIcon, BookmarkIcon } from '../../../dbWebUI'
import { ANNOTATION_NOTE_ACTION, ANNOTATION_BOOKMARK_ACTION, HIGHLIGHT_COLOR_OPTIONS, HIGHLIGHT_BLOCK_STYLE } from '../../../utils/constants'

import './AnnotationFab.css'

/**
 * AnnotationFab that renders a FAB or group of FABs in the gutter of an ebook's
 * content.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function AnnotationFab({
  annotations,
  offsetY,
  onClick,
  className,
  style,
  isLeft,
  ...rest
}) {
  const hasManyFabs = annotations.length > 1
  const firstFab = annotations[0]
  const classes = classNames('AnnotationFab', className, {
    'is-left': isLeft,
  })
  const groupStyles = {
    ...style,
    position: 'absolute',
    top: `${offsetY}px`, // Place at the same height as the offset.
  }

  /**
   * Computes added styles for the given annotation FAB.
   *
   * @param {object} annotation - Annotation instance
   * @returns {object} css styles object
   */
  const getFabStyles = (annotation) => {
    const styles = {}

    // Style the FAB based on the annotation's highlight color and style.
    if (annotation && annotation.highlight && annotation.highlight.color) {
      const selectedColor = HIGHLIGHT_COLOR_OPTIONS
        .reduce((selected, color) => (
          (color.value === annotation.highlight.color)
            ? color.hex
            : selected
        ), '')

      if (annotation.highlight.style === HIGHLIGHT_BLOCK_STYLE) {
        styles.backgroundColor = selectedColor
      } else {
        styles.borderColor = selectedColor
        styles.borderWidth = '2px'
      }
    }

    return styles
  }

  /**
   * Determines how to render the annotation buttons.
   *
   * @param {object} annotation - annotation to render button for
   * @returns {function} Component
   */
  const renderAnnotationButtons = (annotation) => {
    const buttons = []

    if (isLeft) {
      if (annotation.bookmark && annotation.bookmark.id) {
        buttons.push((
          <Button
            key="bookmark"
            isCircular
            isLight
            hasShadow
            hasTooltip
            hasTooltipRight
            hasTooltipActive
            data-tooltip={annotation.bookmark.name}
            style={getFabStyles(annotation)}
            onClick={() => onClick(annotation, null, ANNOTATION_BOOKMARK_ACTION)}
          >
            <IconWrapper>
              <BookmarkIcon />
            </IconWrapper>
          </Button>
        ))
      }
    } else if (annotation.note && annotation.note.id) {
      buttons.push((
        <Button
          key={annotation.id}
          isCircular
          isLight
          hasShadow
          hasTooltip
          hasTooltipLeft
          hasTooltipActive
          data-tooltip={annotation.note.title || annotation.note.text}
          style={getFabStyles(annotation)}
          onClick={() => onClick(annotation, annotation.parts[0][0], ANNOTATION_NOTE_ACTION)}
        >
          <IconWrapper>
            <NoteIcon />
          </IconWrapper>
        </Button>
      ))
    }

    return buttons
  }

  const primaryFabAction = isLeft
    ? ANNOTATION_BOOKMARK_ACTION
    : ANNOTATION_NOTE_ACTION
  let fabLabel = ''

  if (isLeft && !!firstFab.bookmark) {
    fabLabel = firstFab.bookmark.name
  } else if (!isLeft && !!firstFab.note) {
    fabLabel = firstFab.note.title || firstFab.note.text
  }

  return (
    <div className={classes} style={groupStyles} {...rest}>
      <Button
        isCircular
        isSmall
        isLight
        hasShadow
        hasBadge={hasManyFabs}
        hasBadgePrimary={hasManyFabs}
        data-badge={hasManyFabs
          ? annotations.length
          : null}
        hasTooltip={!hasManyFabs}
        hasTooltipRight={isLeft && !hasManyFabs}
        hasTooltipLeft={!isLeft && !hasManyFabs}
        data-tooltip={fabLabel}
        style={hasManyFabs
          ? null
          : getFabStyles(firstFab)}
        onClick={!hasManyFabs && firstFab
          ? () => onClick(firstFab, null, primaryFabAction)
          : null
        }
      >
        <IconWrapper>
          {isLeft
            ? <BookmarkIcon />
            : <NoteIcon />}
        </IconWrapper>
      </Button>
      {/** If there are more than one FAB on the given line, nest them. */}
      {hasManyFabs ? (
        <div className="nested-fabs">
          {annotations.map(renderAnnotationButtons)}
        </div>
      ) : false}
    </div>
  )
}

AnnotationFab.defaultProps = {
  annotations: [],
  offsetY: 0,
  onClick: () => {},
  isLeft: false,
  className: '',
  style: {},
}

AnnotationFab.propTypes = {
  /** Array of annotations represented by this FAB. */
  annotations: PropTypes.arrayOf(PropTypes.object),
  /** Top offset in pixels. */
  offsetY: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /** Click handler for when an individual annotation action is selected. */
  onClick: PropTypes.func,
  /** Anno */
  isLeft: PropTypes.bool,
  /** Additional class names. */
  className: PropTypes.string,
  /** Additional style object. */
  style: PropTypes.shape({}),
}

export default AnnotationFab
