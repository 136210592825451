import React from 'react'
import PropTypes from 'prop-types'
import { Button, InputField } from '../../../../dbWebUI'


/**
 * Annotation note edit form. This should be used as a child of `NoteView` when
 * in edit mode.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function BookmarkForm({
  onSubmit,
  onChange,
  bookmark,
  isSubmitting,
  isValid,
  ...rest
}) {
  /**
   * Select text in input on focus.
   * @param {event} e - input focus event
   */
  const onFocusHandler = (e) => {
    e.target.select()
  }

  return (
    <form onSubmit={onSubmit} {...rest}>
      <InputField
        id="name"
        name="name"
        labelContent="Name"
        onChange={onChange}
        defaultValue={bookmark.name}
        autoComplete="off"
        autoFocus
        onFocus={onFocusHandler}
      />
      <Button
        type="submit"
        isPrimary
        isLoading={isSubmitting}
        disabled={!isValid}
      >
        Save Bookmark
      </Button>
    </form>
  )
}

BookmarkForm.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
  bookmark: {},
  isSubmitting: false,
  isValid: false,
}

BookmarkForm.propTypes = {
  /** On form submit handler. */
  onSubmit: PropTypes.func,
  /** On inpit field change handler. */
  onChange: PropTypes.func,
  /** Initial form data. */
  bookmark: PropTypes.shape({}),
  /** True if the form is in the process of submitting. */
  isSubmitting: PropTypes.bool,
  /** True if the form is valid and able to be submitted. */
  isValid: PropTypes.bool,
}

export default BookmarkForm
