import React from 'react'
import { Level, Button, Box, Icon } from '@deseretbook/react-ui'
import Proptypes from 'prop-types'
import { IconWrapper } from '../../../../dbWebUI'
import { AUDIOBOOK_CONTROL_VIEWS } from './AudiobookControls'

/**
 * Audiobook control view.
 * @returns {function} Component
 */
export default function ControlView({
  isPlaying,
  isLoading,
  pause,
  play,
  next,
  duration,
  previous,
  rate,
  onChangeView,
  onPreviousTrack,
  onNextTrack,
}) {
  const disabled = {
    disabled: duration === 0 || isLoading,
  }
  const seekBackButton = (
    <Button
      type="button"
      isMedium
      {...disabled}
      onClick={previous}
      title={duration === 0
        ? 'Please select a track to play'
        : 'Skip backwards 30 seconds'}
      aria-label={duration === 0
        ? 'Please select a track to play'
        : 'Skip backwards 30 seconds'}
    >
      <Icon name="rewind30" />
    </Button>
  )
  const playButton = (
    <Button
      type="button"
      isLarge
      isLoading={isLoading}
      {...disabled}
      isAction
      onClick={isPlaying
        ? pause
        : play}
    >
      <Icon
        name={isPlaying
          ? 'pause'
          : 'play'}
      />
    </Button>
  )
  const seekForwardButton = (
    <Button
      type="button"
      isMedium
      {...disabled}
      onClick={next}
      title={duration === 0
        ? 'Please select a track to play'
        : 'Skip forward 30 seconds'}
      aria-label={duration === 0
        ? 'Please select a track to play'
        : 'Skip forward 30 seconds'}
    >
      <Icon name="forward30" />
    </Button>
  )
  const volumeButton = (
    <Button
      type="button"
      isSmall
      {...disabled}
      onClick={() => onChangeView(AUDIOBOOK_CONTROL_VIEWS.volume)}
      className="audio-control-button seek next"
      title={duration === 0
        ? 'Please select a track to play'
        : 'Change volume'}
      aria-label={duration === 0
        ? 'Please select a track to play'
        : 'Change volume'}
    >
      <Icon name="volume" />
    </Button>
  )
  const playbackButton = (
    <Button
      type="button"
      isSmall
      {...disabled}
      onClick={() => onChangeView(AUDIOBOOK_CONTROL_VIEWS.playbackRate)}
      className="audio-control-button seek next"
      title={duration === 0
        ? 'Please select a track to play'
        : 'Change volume'}
      aria-label={duration === 0
        ? 'Please select a track to play'
        : 'Change volume'}
    >
      <IconWrapper>{rate}x</IconWrapper>
    </Button>
  )
  const skipForward = (
    <Button
      type="button"
      isMedium
      aria-label="Skip to next chapter"
      {...disabled}
      onClick={onNextTrack}
    >
      <Icon name="skipNext" />
    </Button>
  )
  const skipBack = (
    <Button
      type="button"
      isMedium
      title="Skip to previous chapter"
      aria-label="Skip to previous chapter"
      {...disabled}
      onClick={onPreviousTrack}
    >
      <Icon name="skipBack" />
    </Button>
  )

  return (
    <Level>
      <Level.Item>
        <Box justifyContent="center" flex={1}>
          {playbackButton}
        </Box>
      </Level.Item>
      <Level.Item>
        <Box justifyContent="center" flex={1}>
          <Level isMobile>
            <Level.Item>{skipBack}</Level.Item>
            <Level.Item>{seekBackButton}</Level.Item>
            <Level.Item>{playButton}</Level.Item>
            <Level.Item>{seekForwardButton}</Level.Item>
            <Level.Item>{skipForward}</Level.Item>
          </Level>
        </Box>
      </Level.Item>
      <Level.Item>{volumeButton}</Level.Item>
    </Level>
  )
}

ControlView.propTypes = {
  isPlaying: Proptypes.bool,
  isLoading: Proptypes.bool,
  pause: Proptypes.func,
  play: Proptypes.func,
  next: Proptypes.func,
  duration: Proptypes.number,
  rate: Proptypes.number,
  onChangeView: Proptypes.func,
  onPreviousTrack: Proptypes.func,
  onNextTrack: Proptypes.func,
  previous: Proptypes.func,
}

ControlView.defaultProps = {
  isPlaying: false,
  isLoading: false,
  pause: () => {},
  play: () => {},
  next: () => {},
  previous: () => {},
  onChangeView: () => {},
  onPreviousTrack: () => {},
  onNextTrack: () => {},
  duration: 0,
  rate: 1,
}
