import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icon'

/**
 * Arrow Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function ArrowIcon({ isBack, ...props }) {
  let path = 'M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z'

  if (isBack) {
    path = 'M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z'
  }

  return (
    <Icon icon="arrow" {...props}>
      <g>
        <path d={path} />
      </g>
    </Icon>
  )
}

ArrowIcon.defaultProps = {
  isBack: false,
}

ArrowIcon.propTypes = {
  /** Point arrow back. */
  isBack: PropTypes.bool,
}

export default ArrowIcon
