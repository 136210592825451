import * as React from 'react'
import propTypes from 'prop-types'
import { Level } from '@deseretbook/react-ui'
import { Menu } from '../../../dbWebUI'
import { formatSecondsToTimeString } from '../../../utils/base'

/**
 * List of chapters.
 * @returns {function} Component
 */
export default function ChapterListing({
  onSelectChapter,
  chapters,
  currentChapter,
}) {
  return (
    <Menu>
      <Menu.List>
        {chapters.map((selectedChapter, index) => (
          <Menu.ListItem
            key={
              selectedChapter.name + selectedChapter.length
            }
          >
            <Menu.ListItem.Button
              isActive={
                currentChapter
                && currentChapter.files
                && currentChapter.files[0]
                && currentChapter.files[0].checksum
                === (selectedChapter.files
                  && selectedChapter.files[0]
                  && selectedChapter.files[0].checksum)
              }
              onClick={() => onSelectChapter({ selectedChapter, index })}
            >
              <Level isMobile>
                <Level.Left>
                  {selectedChapter.name}
                </Level.Left>
                <Level.Right>
                  {formatSecondsToTimeString(selectedChapter.length)}
                </Level.Right>
              </Level>
            </Menu.ListItem.Button>
          </Menu.ListItem>
        ))}
      </Menu.List>
    </Menu>
  )
}

ChapterListing.propTypes = {
  onSelectChapter: propTypes.func,
  chapters: propTypes.arrayOf(propTypes.object),
  currentChapter: propTypes.shape({}),
}

ChapterListing.defaultProps = {
  onSelectChapter: () => { },
  chapters: [],
  currentChapter: null,
}
