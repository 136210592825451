import React from 'react'
import { Box, Text, Button, Icon } from '@deseretbook/react-ui'

const BOOKSHELF_PLUS_LINK =
  'https://www.deseretbook.com/bookshelf-plus-landing-page/bookshelf-plus-landing-page.html'

/**
 * Reusable button to link to dbcom
 *
 * @returns {function} react component
 */
const LearnMoreButton = () => (
  <Button
    target="_blank"
    as="a"
    href={BOOKSHELF_PLUS_LINK}
    isFullwidth
    style={{ marginTop: '1rem', color: '#f7a92b', borderColor: '#f7a92b' }}
  >
    <Box alignItems="center">
      <Text color="inherit">Learn More About</Text>
      <Icon
        color="plus"
        style={{ width: '7rem', height: '1.25rem', marginLeft: '0.75rem', display: 'inline-block' }}
        size="medium"
        name="plus"
      />
    </Box>
  </Button>
)

export default LearnMoreButton
