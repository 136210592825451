import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/form/general/
 * @todo add documentation
 */
const Control = glamorous.div('control', propsToHelperClasses)

export default Control
