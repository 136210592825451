/* eslint react/no-array-index-key: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AnnotationHighlight from './AnnotationHighlight'


/**
 * `Annotation` component that displays and provides interaction with an annotation.
 *
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function Annotation({
  className, annotation, onClick, ...rest
}) {
  if (!annotation.parts || !annotation.parts.length) {
    return false
  }

  const classes = classNames('bwapp-annotation', className)

  /**
   * Renders individual highlight components for every rect in the annotation.
   *
   * @returns {function} Component
   */
  const renderParts = () => annotation.parts
    .reduce((flattened, rects) => [...flattened, ...rects], [])
    .map((rect, idx) => (
      <AnnotationHighlight
        rect={rect}
        highlight={annotation.highlight || {}}
        key={idx}
        onClick={() => onClick(rect)}
      />
    ))

  return (
    <span className={classes} {...rest}>
      {renderParts()}
    </span>
  )
}

Annotation.defaultProps = {
  className: '',
  annotation: {
    parts: [],
    highlight: {},
  },
  onClick: () => {},
}

Annotation.propTypes = {
  /** Additional class names */
  className: PropTypes.string,
  annotation: PropTypes.shape({
    /** Array of arrays containing the position and dimensions of each part to be highlighted. */
    parts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
    /** Information regarding how to display the annotation */
    highlight: PropTypes.shape({}),
  }),
  onClick: PropTypes.func,
}

export default Annotation
