import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * A simple container to center your content horizontally.
 *
 * @see https://bulma.io/documentation/layout/container/
 * @todo add documentation
 */
const Container = glamorous.div('container', propsToHelperClasses)

export default Container
