import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

import './Level.css'

/**
 * @see https://bulma.io/documentation/layout/level/
 * @todo add documentation
 */
const Level = glamorous.div('level', propsToHelperClasses)

Level.Item = glamorous.div('level-item', propsToHelperClasses)
Level.Left = glamorous.div('level-left', propsToHelperClasses)
Level.Right = glamorous.div('level-right', propsToHelperClasses)

export default Level
