import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'
import Control from './Control'
import Help from './Help'
import Label from './Label'
import Input from './Input'
import Select from './Select'
import Textarea from './Textarea'

/**
 * Groups together components related to an input field.
 *
 * May contain a `FormLabel`, `FormControl`, and `HelpText` component.
 *
 * Based on Bulma's `.field`
 *
 * @see https://bulma.io/documentation/form/general/#form-field
 * @todo update documentation
 */
const Field = glamorous.div('field', propsToHelperClasses)

Field.Control = Control
Field.Help = Help
Field.Label = Label
Field.Select = Select
Field.Input = Input
Field.Textarea = Textarea

export default Field
