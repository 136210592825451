import React from 'react'
import PageLayout from '../../layouts/DefaultLayout'
import RegistrationForm from '../../components/users/RegistrationForm'
import { Columns, ButtonLink } from '../../dbWebUI'
import { loginUrl } from '../../utils/routes'
import ErrorComponent from '../../components/ErrorComponent'


/**
* Registration page.
*/
class RegisterPage extends React.Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()
    this.state = {
      error: null,
    }
  }

  /**
   * Catches errors when something happens.
   * @param {object} error - Caught error
   */
  componentDidCatch(error) {
    this.setState({ error })
  }

  /**
   * Determines how the component should be rendered.
   * @returns {function} Component
   */
  render() {
    const { error } = this.state

    if (error) {
      return <PageLayout><ErrorComponent error={error} /></PageLayout>
    }

    return (
      <PageLayout>
        <Columns>
          <Columns.Column isHalf isOffsetOneQuarter>
            <p className="title">Create an Account</p>
            <RegistrationForm>
              <ButtonLink isPulledRight to={loginUrl()}>Sign in</ButtonLink>
            </RegistrationForm>
          </Columns.Column>
        </Columns>
      </PageLayout>
    )
  }
}

export default RegisterPage
