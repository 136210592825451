import { register as sdkRegister } from '@deseretbook/media-sdk'
import { TOKEN_STORAGE_KEY, USER_STORAGE_KEY } from '../constants'
import { requestOptions } from '../base'


/**
 * Attempts to create a new account, and if successful it saves the registration to localStorage.
 *
 * @example
 * register({ email, password })
 *   .then(response => console.log('logged in and registered'))
 *
 * @see https://deseretbookwiki.atlassian.net/wiki/spaces/DB/pages/33030/Deseret+Bookshelf+API+Reference#DeseretBookshelfAPIReference-CreateAccount
 *
 * @param {object} params - register user request params
 * @param {string} [params.firstName] - user's first name
 * @param {string} [params.lastName] - user's last name
 * @param {string} params.email - account email
 * @param {string} params.password - account password
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 *
 * @todo response data can probably be cleaned up
 * @todo Move storage of token to a cookie. This will improve security and will
 * also be needed for single sign-in.
 */
export default function register(params = {}) {
  return sdkRegister(params, requestOptions())
    .then((response) => {
      if (response.success) {
        localStorage.setItem(TOKEN_STORAGE_KEY, response.hash)
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(response.user))
      }

      return response
    })
}
