import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/elements/box/
 * @todo add documentation
 */
const Box = glamorous.div('box', propsToHelperClasses)

export default Box
