import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

import './Textarea.css'

/**
 * A `Textarea` is a wrapper for the `<textarea />` tag. It should be used inside a `Control`.
 *
 * If you're trying to build a form, take a look at the different field components.
 * Using those will help ensure consistency.
 *
 * Based on Bulma's `.textarea`
 *
 * @see https://bulma.io/documentation/form/textarea/
 * @todo update documentation
 */
const Textarea = glamorous.textarea('textarea', propsToHelperClasses)

export default Textarea
