import React from 'react'
import { Box, Text, Button, Icon } from '@deseretbook/react-ui'
import propTypes from 'prop-types'

/**
 * Read now button with plus icon
 *
 * @param {string} url - Link to read or listen to media
 * @param {string} actionText - Text to display on the button
 * @returns {function} react component
 */
const ActNowButton = ({ isPlus, isAudiobook, buttonProps, isVip, purchased }) => {
  const actionText = isAudiobook ? 'Listen' : 'Read'
  const showIcon = !purchased && (isPlus || isVip)

  let buttonColor = 'bookshelf'

  if (!purchased) {
    if (isVip) {
      buttonColor = 'vip'
    } else if (isPlus) {
      buttonColor = 'plus'
    }
  }

  const defaultButtonProps = {
    isFullwidth: true,
    color: buttonColor,
    style: {
      marginTop: '1rem',
      color: 'white',
    },
  }

  return (
    <Button {...defaultButtonProps} {...buttonProps}>
      {showIcon ? (
        <Box alignItems="center">
          <Text color="inherit">{actionText} for Free with</Text>

          <Icon
            color="light"
            style={{
              width: isVip ? '2rem' : '7rem',

              height: '1.25rem',
              marginLeft: '0.5rem',
              display: 'inline-block',
            }}
            size="medium"
            name={isVip ? 'vip' : 'plus'}
          />
        </Box>
      ) : (
        <Text color="inherit">{actionText} Now</Text>
      )}
    </Button>
  )
}

ActNowButton.defaultProps = {
  isPlus: false,
  isVip: false,
  isAudiobook: false,
  buttonProps: {},
  purchased: false,
}

ActNowButton.propTypes = {
  purchased: propTypes.bool,
  isPlus: propTypes.bool,
  isVip: propTypes.bool,
  isAudiobook: propTypes.bool,
  buttonProps: propTypes.shape({}),
}

export default ActNowButton
