import React from 'react'
import PropTypes from 'prop-types'
import { authenticateUrl } from '../../../utils/routes'

const PLAY = 'play'
const PAUSE = 'pause'

/**
 * This class is accepts a function as a child, and passes in methods that allow the child
 * to control the usage stastics reporter.
 * This handler requires the audiofeeds to be passed in as an array.
 * It handles one track at a time, and that track can have multiple subtracks.
 * This class can handle seeking across the subtracks smoothly,
 * and shows progress across the entire collection of subtracks.
 */
class AudiobookHandler extends React.Component {
  /**
   * Determines state values based on given prop values.
   *
   * @param {object} props - Component props
   * @param {object} state - Current component state
   * @returns {object} Changes to state
   */
  static getDerivedStateFromProps(props, state) {
    if (props.media && props.media.files) {
      let index = 0

      if (props.media.files[state.currentTrack]) {
        index = state.currentTrack
      }

      return {
        currentSource: authenticateUrl(props.media.files[index].fileUrl),
      }
    }

    return {}
  }

  /**
   * Extracts the source strings from a chapter
   * @param {object} [chapter={}] - Chapter instance
   * @returns {array<string>} An array of source strings
   */
  static getSourceFromChapter(chapter = {}) {
    return (
      (chapter
        && chapter.files
        && chapter.files.map(f => authenticateUrl(f.fileUrl)))
      || []
    )
  }

  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.audioSources = []
    this.audioFeed = null
    this.playPromise = null
    this.handleAudioError = this.handleAudioError.bind(this)
    this.handleTogglePlayPause = this.handleTogglePlayPause.bind(this)
    this.handleOnProgress = this.handleOnProgress.bind(this)
    this.renderAudioFeeds = this.renderAudioFeeds.bind(this)
    this.handleEndTrack = this.handleEndTrack.bind(this)
    this.handleSeek = this.handleSeek.bind(this)
    this.resetTrackTimes = this.resetTrackTimes.bind(this)
    this.handlePausePlayAttempt = this.handlePausePlayAttempt.bind(this)
    this.handleBeginLoad = this.handleBeginLoad.bind(this)
    this.handleFinishLoad = this.handleFinishLoad.bind(this)
    this.handlePlay = this.handlePlay.bind(this)
    this.handlePause = this.handlePause.bind(this)
    this.handleSetLastPosition = this.handleSetInitialPosition.bind(this)
    this.handleReportLastPostion = this.handleReportLastPostion.bind(this)
    this.positionReportingInterval = setInterval(() => this.handleReportLastPostion(true), 30000)
    this.updatePositionHash = setInterval(this.handleReportLastPostion, 2500)

    /**
     * isPlaying: tracks the current play state of the track, true means the track is playing
     * currentTrack: current 0-index subtrack
     * totalDuration: Float, total duration of all subtracks
     * progress: percent completion of all the subtracks
     * volume: volume percent. Must be set for each individual track
     * sources: array of source strings.
     *  This must be included to work around 403 erros on the presigned amazon url
     * pause: variable that stores if the track is paused. Used to avoid infinite play/pause loop
     * loading: is the track loading currently
     */
    this.state = {
      isPlaying: false,
      currentTrack: 0,
      progress: 0.0,
      volume: 0.75,
      pause: false,
      loading: false,
      speed: 1,
      duration: 0,
      currentSource: '',
      hasSeekedToLastPosition: false,
    }
  }

  /**
   * Resets things if the track/media has changed at all.
   *
   * @param {object} prevProps - Previous component props
   */
  componentDidUpdate(prevProps) {
    const { media } = this.props

    if (
      prevProps.media !== media
      && media
      && media.files
    ) {
      /**
       * cannot be used in deriveStateFromProps because access is needed to the component's
       */
      this.resetTrackTimes()
    }
    const currentFeed = this.audioFeed

    /**
     * these checks are necessary because play is asynchronous
     * when seeking on a part of the track that has not buffered, the
     * HTML5 api automatically pauses the track. Without a way to track this automatic pausing,
     * the track gets stuck in a loop playing and pausing itself.
     * @see https://stackoverflow.com/questions/36803176/how-to-prevent-the-play-request-was-interrupted-by-a-call-to-pause-error
     */
    if (this.mediaMethodToCall === PLAY) {
      if (currentFeed && currentFeed.paused) {
        currentFeed.play()
      }
    } else if (currentFeed && !currentFeed.paused && !this.state.pause) {
      currentFeed.pause()
    }

    if (currentFeed && currentFeed.playbackRate !== this.state.speed) {
      currentFeed.playbackRate = this.state.speed
    }

    if (currentFeed && currentFeed.volume !== this.state.volume) {
      currentFeed.volume = this.state.volume
    }
    this.handleSetInitialPosition()
  }

  /**
   * Cleans things up before the component goes adios.
   */
  componentWillUnmount() {
    clearInterval(this.positionReportingInterval)
    clearInterval(this.updatePositionHash)
  }

  /**
   * returns media method to call based on current state
   */
  get mediaMethodToCall() {
    return this.state.isPlaying
      ? PLAY
      : PAUSE
  }

  /**
   * returns total time elapsed among all tracks.
   */
  get totalCurrentTime() {
    const { currentTrack } = this.state

    if (this.audioFeed) {
      /**
       * takes the currentTrack from state, and
       * loops over this.audiofeeds while the currentTrack is less than the index of the loop.
       * Once currentTrack equals index, it takes the currentTime of the currentTrack and sums that
       * to the total.
       * In the end, the result is the total times of all the tracks that have already elapsed,
       * plus the elapsed time on the current track, thus returning the total current runtime.
       */
      const accTime = this.props.media
        && Array.isArray(this.props.media.files)
        && this.props.media.files
          .reduce((prev, file, index) => {
            if (!file) return prev

            if (index < currentTrack) {
              return prev + file.length || 0
            } else if (currentTrack === index) {
              return prev + (this.audioFeed && this.audioFeed.currentTime)
            }

            return prev
          }, 0)

      return accTime || 0
    }

    return 0
  }

  /**
   * Returns the track index for the specified absolute time
   * @param {number} time Absolute time in seconds of seek position
   * @returns {number} The track of the
   */
  getTrackFromAbsoluteTime(time) {
    if (this.props.media && Array.isArray(this.props.media.files)) {
      const trackInfo = this.props.media.files.reduce(
        (prev, file, index) => {
          if (!file || prev.trackIndex !== -1) return prev

          if (file.length - prev.time > 0) {
            return {
              time: prev.time,
              trackIndex: index,
            }
          }

          return {
            trackIndex: -1,
            time: prev.time - (file.length || 0),
          }
        },
        { time, trackIndex: -1 },
      )

      return trackInfo
    }

    return { trackIndex: -1 }
  }

  /**
   * Event handler for when the last position should be reported somewhere.
   *
   * @param {boolean} [shouldReportToServer=false] - If the change should be sent to the server too
   */
  handleReportLastPostion(shouldReportToServer = false) {
    this.props.onReportPosition(this.totalCurrentTime || 0, shouldReportToServer)
  }

  /**
   * This function checks whether the audio has already seeked to the last
   * saved position.
   */
  handleSetInitialPosition() {
    const { lastPosition, currentTrackIndex } = this.props

    if (lastPosition
      && !this.state.hasSeekedToLastPosition
      && lastPosition.item === currentTrackIndex) {
      this.handleSeek(lastPosition.seconds)
      this.setState({ hasSeekedToLastPosition: true })
    }
  }

  /**
   * Called when the audio begins loading
   */
  handleBeginLoad() {
    this.setState({ loading: true })
  }

  /**
   * Called when audio finishes loading
   */
  handleFinishLoad() {
    this.setState({ loading: false })
  }

  /**
   * Toggles between play and pause based on the value that is stored in state
   */
  handleTogglePlayPause() {
    this.setState(
      prevState => ({ isPlaying: !prevState.isPlaying }),
      () => {
        if (this.state.isPlaying) {
          this.props.onPlay()
        } else {
          this.props.onPause()
        }
      },
    )
  }

  /**
   * Plays the audio
   */
  handlePlay() {
    this.setState({ isPlaying: true })
  }

  /**
   * Pauses the audio
   */
  handlePause() {
    this.setState({ isPlaying: false })
  }

  /**
   * Handles the seeking with the seek buttons on the player.
   * @param {number} absoluteSeekTime the position on the track to seek to in seconds.
   * A negative number implies a seek backwards, positive is forwards
   */
  handleSeek(absoluteSeekTime) {
    if (absoluteSeekTime > this.props.duration) {
      this.resetTrackTimes()
      this.props.onNextTrack(this.handleTogglePlayPause)
      this.props.onPreviousTrack(this.handleTogglePlayPause)
    } else if (absoluteSeekTime < 0) {
      this.resetTrackTimes()
    }
    const { trackIndex, time } = this.getTrackFromAbsoluteTime(absoluteSeekTime)

    if (absoluteSeekTime === 0 || trackIndex === -1 || !this.audioFeed) {
      return
    }
    this.setState({
      currentTrack: trackIndex,
    })
    const newFeed = this.audioFeed

    newFeed.currentTime = time

    if (this.totalCurrentTime < absoluteSeekTime) {
      this.props.onNext()
    } else {
      this.props.onPrevious()
    }
  }

  /**
   * Sets all audio tracks to a currentTime of 0
   */
  resetTrackTimes() {
    this.setState({ currentTrack: 0 })
    this.audioFeed.currentTime = 0
    this.handleOnProgress()
  }

  /**
   * Handles transitioning to the next audio source for multi-track chapters
   */
  handleEndTrack() {
    this.resetTrackTimes()

    if (
      this.props.media
      && this.props.media.files[this.state.currentTrack + 1]
    ) {
      this.setState({
        currentTrack: this.state.currentTrack + 1,
        isPlaying: true,
      })
    } else {
      this.setState({
        currentTrack: 0,
      })
      this.props.onNextTrack(this.handleTogglePlayPause)
    }
  }

  /**
   * Updates state to reflect the current progress of the audio track
   * relative to the total duration of all available tracks
   *
   * @returns {boolean} Returns false because it apparently does and idk why and don't care to
   *  change it or figure out why
   */
  handleOnProgress() {
    const { audioFeed } = this

    if (audioFeed) {
      this.setState({ progress: this.totalCurrentTime })

      return false
    }
    this.setState({ progress: this.totalCurrentTime })

    return false
  }

  /**
   * changes the volume of all tracks to a specified percent
   * @param {number} volume - a value between 0 and 1 representing the volume level as a percentage.
   */
  handleChangeVolume(volume) {
    this.setState({ volume })
  }

  /**
   * Changes the playback speed for the track
   * @param {number} speed Desired playback speed
   */
  handleChangeSpeed(speed) {
    this.setState({ speed })
  }

  /**
   * Called when the audiobook api encouters an error.
   * In practice, because the pre-signed URLs from amazon
   * expire quickly, the error this is designed to handle is
   * a 403 unauthorized when attempting to buffer or seek
   * on the track after the URL has expired. This forces the API
   * to reload the resource URL from the DeseretBook server,
   * thus circumventing load issues with the 403 on amazon
   * @param {Event} event Event object describing the error
   * @param {number} track The track on which the error occurred
   */
  handleAudioError(event) {
    const { target } = event

    if (
      this.props.media
      && this.props.media.files
      && this.props.media.files[this.state.currentTrack]
    ) {
      const newSource = authenticateUrl(`${
        this.props.media.files[this.state.currentTrack].fileUrl
      }?salt=${Date.now()}`)

      this.setState(
        {
          currentSource: newSource,
        },
        () => {
          this.forceUpdate()
          this.audioFeed.currentTime = target.currentTime || 0
        },
      )
    }
  }

  /**
   * Sets state to keep track of calls that are automatically made from
   * the HTML5 <audio /> API. Tracking this in states prevents play/pause loops
   * on the media
   * @param {'play' | 'pause'} status The call being requested, play or pause
   */
  handlePausePlayAttempt(status) {
    this.setState({
      pause: status === PAUSE,
    })

    if (status === PAUSE) {
      this.props.onPause()
    } else if (status === PLAY) {
      this.props.onPlay()
    }
  }

  /**
   * Returns an array of html5 audio elements
   * @param {string[]} media - an array of audio track source strings
   * @returns {Node} - an html5 audio node
   */
  renderAudioFeeds(media = this.state.currentSource) {
    if (!media) return false

    return (
      <audio
        ref={(c) => {
          this.audioFeed = c
        }}
        key={media}
        onTimeUpdate={this.handleOnProgress}
        onLoadedMetadata={this.calculateDuration}
        preload="auto"
        onEnded={this.handleEndTrack}
        onLoadStart={this.handleBeginLoad}
        onCanPlay={this.handleFinishLoad}
        onPause={() => this.handlePausePlayAttempt(PAUSE)}
        onPlay={() => this.handlePausePlayAttempt(PLAY)}
        onError={this.handleAudioError}
      >
        <track kind="captions" />
        <source onError={this.handleAudioError} src={media} type="audio/mp3" />
      </audio>
    )
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    return (
      <div>
        {this.renderAudioFeeds()}
        {this.props.children({
          previous: () =>
            this.handleSeek(this.totalCurrentTime - this.props.seekTime),
          next: () =>
            this.handleSeek(this.props.seekTime + this.totalCurrentTime),
          toggle: this.handleTogglePlayPause,
          seekTo: this.handleSeek,
          play: this.handlePlay,
          pause: this.handlePause,
          handleChangeVolume: volume => this.handleChangeVolume(volume),
          handleChangeRate: rate => this.handleChangeSpeed(rate),
          rate: this.state.speed,
          volume: this.state.volume,
          isPlaying: this.state.isPlaying,
          progress: this.state.progress,
          duration: this.props.duration || this.state.duration,
          loading: this.state.loading,
          speed: this.state.speed,
          onNextTrack: this.props.onNextTrack,
          onPreviousTrack: this.props.onPreviousTrack,
        })}
      </div>
    )
  }
}

AudiobookHandler.defaultProps = {
  duration: 0,
  lastPosition: null,
  onNextTrack: () => {},
  onPreviousTrack: () => {},
  media: {},
  onNext: () => {},
  onPause: () => {},
  onPlay: () => {},
  onPrevious: () => {},
  seekTime: 10,
  currentTrackIndex: null,
  onReportPosition: () => null,
}
/**
 * children: a function that returns a react element
 * media: an array of sources of media files
 * onNext: callback function when the user seeks forwards
 * onPrevious: callback function when the user seeks backwards
 * seekTime: relative seek time from current location. Negative is backwards, positive forwards
 */
AudiobookHandler.propTypes = {
  children: PropTypes.func.isRequired,
  lastPosition: PropTypes.shape({
    seconds: PropTypes.number,
    item: PropTypes.number,
  }),
  media: PropTypes.shape({
    files: PropTypes.array,
  }),
  onNext: PropTypes.func,
  onPause: PropTypes.func,
  onPlay: PropTypes.func,
  onPrevious: PropTypes.func,
  seekTime: PropTypes.number,
  currentTrackIndex: PropTypes.number,
  duration: PropTypes.number,
  onNextTrack: PropTypes.func,
  onPreviousTrack: PropTypes.func,
  onReportPosition: PropTypes.func,
}

export default AudiobookHandler
