import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { chapterHasChildren } from '../../../utils/media'
import { Menu, Navbar, ArrowIcon, IconWrapper, ChevronIcon, Level } from '../../../dbWebUI'
import { libraryUrl } from '../../../utils/routes'

import './TableOfContents.css'

/**
 * Displays a table of contents. Mainly to be used in a `<Sidebar />` but could be
 * placed in other locations as well.
 * @todo add this to the storybook?
 */
class TableOfContents extends Component {
  /**
   * Component constructor
   *
   * @param {object} props - Component props
   */
  constructor(props) {
    super(props)
    this.state = {
      /** List of chapters to display */
      displayedTableOfContents: props.tableOfContents,
    }

    this.renderChapters = this.renderChapters.bind(this)
    this.selectChapter = this.selectChapter.bind(this)
  }

  /**
   * Stuff that should happen before a component is updated with new props.
   *
   * @param {object} nextProps - Next props the component with receive.
   */
  componentWillReceiveProps(nextProps) {
    // Update what is currently being displayed based on incoming props.
    // If the `currentChapter` props changes, then we'll want to display that
    // new one. If we find out that the table of contents wasn't visible but
    // now is, then we want to reset where in the table of contents we're viewing.
    const changeInVisibility = nextProps.isVisible && !this.props.isVisible

    if (changeInVisibility) {
      this.setState({
        displayedTableOfContents: nextProps.tableOfContents,
      })
    }
  }

  /**
   * Selects the given chapter. If no chapter is passed in, then the table of
   * contents is displayed. If a chapter is given and it has a nested table of
   * contents, then the nested table of contents is displayed. If the chapter
   * doesn't have a nested table of contents, then the `selectChapter` callback
   * is called to inform the parent of a new chapter selection.
   *
   * @param {object} chapter - chapter object, or null
   */
  selectChapter(chapter) {
    const { onSelectChapter, tableOfContents } = this.props

    // Fallback to the main table of contents
    if (!chapter) {
      this.setState({
        displayedTableOfContents: tableOfContents,
      })

      return
    }

    // Display the nested table of contents if one is present
    if (chapterHasChildren(chapter)) {
      this.setState({
        displayedTableOfContents: chapter.nested,
      })

      return
    }

    // Let the parent know to switch chapters
    onSelectChapter(chapter)
  }

  /**
   * Renders the currently displayed table of contents
   *
   * @returns {function} Component
   */
  renderChapters() {
    const { displayedTableOfContents } = this.state

    return displayedTableOfContents.map((chapter) => (
      <Menu.ListItem key={chapter.id}>
        <Menu.ListItem.Button onClick={() => this.selectChapter(chapter)}>
          <Level isMobile>
            <Level.Left>
              <Level.Item>{chapter.label}</Level.Item>
            </Level.Left>
            {chapterHasChildren(chapter) ? (
              <Level.Right>
                <Level.Item>
                  <IconWrapper>
                    <ChevronIcon />
                  </IconWrapper>
                </Level.Item>
              </Level.Right>
            ) : (
              false
            )}
          </Level>
        </Menu.ListItem.Button>
      </Menu.ListItem>
    ))
  }

  /**
   * Determines how the component should be rendered.
   *
   * @returns {function} Component
   */
  render() {
    const { tableOfContents, onSelectChapter, className, isVisible, ...rest } = this.props

    const classes = classNames('TableOfContents', className)

    return (
      <div className={classes} {...rest}>
        <Navbar isPrimary isHiddenDesktop>
          <Navbar.Brand isFullwidth>
            <Navbar.Link to={libraryUrl()} exact title="Back" isFullwidth>
              <Level isMobile>
                <Level.Left>
                  <Level.Item>
                    <IconWrapper>
                      <ArrowIcon isBack color="white" />
                    </IconWrapper>
                  </Level.Item>
                  <Level.Item>Back to Library</Level.Item>
                </Level.Left>
              </Level>
            </Navbar.Link>
          </Navbar.Brand>
        </Navbar>
        <Navbar isPrimary hasShadow>
          <Navbar.Brand>
            <Navbar.StaticItem>Table of Contents</Navbar.StaticItem>
          </Navbar.Brand>
        </Navbar>
        <Menu hasVerticalScroll>
          <Menu.List>{this.renderChapters()}</Menu.List>
        </Menu>
      </div>
    )
  }
}

TableOfContents.defaultProps = {
  tableOfContents: [],
  onSelectChapter: () => {},
  isVisible: true,
  className: '',
}

TableOfContents.propTypes = {
  /** Table of contents items */
  tableOfContents: PropTypes.arrayOf(PropTypes.object),
  /** callback for selecting a chapter */
  onSelectChapter: PropTypes.func,
  /** if the table of contents is currently being displayed. */
  isVisible: PropTypes.bool,
  /** Additional class names */
  className: PropTypes.string,
}

export default TableOfContents
