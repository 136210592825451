/**
 * Token storage namespace
 */
export const TOKEN_STORAGE_KEY = 'bwapp:token'

/**
 * User storage namespace
 */
export const USER_STORAGE_KEY = 'bwapp:user'

/**
 * "create" sync code used by Bookshelf API. Send to indicate something
 * is being created.
 */
export const CREATE_SYNC_CODE = 0

/**
 * "update" sync code used by Bookshelf API. Send to indicate something is
 * being updated.
 */
export const UPDATE_SYNC_CODE = 1

/**
 * "delete" sync code used by Bookshelf API. Send to indicate something is
 * being deleted.
 */
export const DELETE_SYNC_CODE = 2
