import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isAuthenticated } from '../../api/users'

/**
 * Authentication wrapper for `Route`. Checks to see if the user is authenticated
 * and if not it redirects the user to the login page. After login they will be
 * redirected back to the page they initially tried to access.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function AuthenticatedRoute(props) {
  if (!isAuthenticated()) {
    const { path, location } = props
    const nextPath = location ? `${location.pathname}${location.search}` : path || '/'

    return <Redirect to={`/login?next=${encodeURIComponent(nextPath)}`} />
  }

  return <Route {...props} />
}

AuthenticatedRoute.defaultProps = {
  location: null,
  path: '',
}

AuthenticatedRoute.propTypes = {
  /** window.location from `Route` component */
  location: PropTypes.shape({}),
  /** Next page path from `Route` component */
  path: PropTypes.string,
  /** Component to be displayed */
  component: PropTypes.func.isRequired,
}

export default AuthenticatedRoute
