/* eslint react/no-array-index-key: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { HIGHLIGHT_UNDERLINE_STYLE } from '../../../utils/constants'


/**
 * A single display piece of an `Annotation`.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function AnnotationHighlight(props) {
  const {
    className, rect, highlight, ...rest
  } = props

  const classes = classNames('bwapp-annotation-highlight', className)

  const isUnderlined = highlight.style === HIGHLIGHT_UNDERLINE_STYLE


  const color = highlight.color
    ? `#${highlight.color.toString(16)}`
    : ''

  return (
    <span
      className={classes}
      style={{
        position: 'absolute',
        boxSizing: 'border-box',
        width: `${rect.width}px`,
        height: `${rect.height}px`,
        borderBottom: isUnderlined
          ? `solid 2px ${color}`
          : '',
        top: `${rect.top}px`,
        left: `${rect.left}px`,
        background: !isUnderlined
          ? color
          : '',
        zIndex: 0,
        opacity: 0.5,
      }}
      {...rest}
    />
  )
}

AnnotationHighlight.defaultProps = {
  className: '',
}

AnnotationHighlight.propTypes = {
  /** additional class names */
  className: PropTypes.string,
  /** Object with dimension/position data */
  rect: PropTypes.shape({}).isRequired,
  /** Data regarding type of highlight to display */
  highlight: PropTypes.shape({}).isRequired,
}

export default AnnotationHighlight
