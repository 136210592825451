import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Navbar, Container, IconWrapper, ChevronIcon } from '../../../dbWebUI'
import Progress from '../progress/Progress'

/**
 * Bottom Toolbar component for use in the Reader Page.
 *
 * @returns {function} Component
 */
function BottomToolbar({
  onPrevious,
  onNext,
  className,
  pageCountLabel,
  completionPercentage,
  ...rest
}) {
  const classes = classNames('BottomToolbar', className)

  return (
    <Navbar className={classes} hasShadow isMobile isFixedBottom {...rest}>
      <div style={{ position: 'absolute', top: -3, left: 0, width: '100%', zIndex: 5000 }}>
        <Progress value={completionPercentage.toFixed(0)} />
      </div>
      <Container>
        <Navbar.Menu>
          <Navbar.Center>
            <Navbar.Item
              onClick={onPrevious}
              hasTooltip
              hasTooltipLeft
              data-tooltip="Previous Page"
            >
              <IconWrapper>
                <ChevronIcon isLeft />
              </IconWrapper>
            </Navbar.Item>
            <Navbar.Item>{pageCountLabel}</Navbar.Item>
            <Navbar.Item onClick={onNext} hasTooltip hasTooltipLeft data-tooltip="Next Page">
              <IconWrapper>
                <ChevronIcon isRight />
              </IconWrapper>
            </Navbar.Item>
          </Navbar.Center>
        </Navbar.Menu>
      </Container>
    </Navbar>
  )
}

BottomToolbar.defaultProps = {
  onNext: () => {},
  onPrevious: () => {},
  className: '',
  pageCountLabel: '',
  completionPercentage: 0,
}

BottomToolbar.propTypes = {
  /** Callback for next chapter button */
  onNext: PropTypes.func,
  /** Callback for previous chapter button */
  onPrevious: PropTypes.func,
  /** Additional class names */
  className: PropTypes.string,
  /** The current page of total pages */
  pageCountLabel: PropTypes.string,
  /** The current progress of the book */
  completionPercentage: PropTypes.number,
}

export default BottomToolbar
