import { URI } from '@deseretbook/utils'
import { groupByKey } from './base'
import { DELETE_SYNC_CODE } from '../api/constants'

/**
 * Converts a string in BLR format to a css selector. Assumes string is a proper
 * BLR. Also assumes the first two components of the BLR are the `book_id` and `document_id`.
 *
 * @example
 * blrToSelector('book|doc|HTML|BODY|P[2]|47')
 * // returns { selector: 'html > body > p:nth-of-type(3)', offset: 47 }
 *
 * @param {string} blr - string in BLR format
 * @returns {object} selector and offset
 */
export function blrToSelector(blr) {
  if (!blr) {
    return null
  }
  const parsed = blr
    .toLowerCase()
    .replace(/(\[)(\d+)(])/gi, (a, b, num) => `:nth-of-type(${Number(num) + 1})`)
    .split('|')

  const selector = parsed.splice(2, parsed.length - 3).join(' > ')

  return {
    selector,
    offset: Number(parsed[parsed.length - 1]),
  }
}

/**
 * Builds a list of text nodes found nested inside the given element
 *
 * @param {Element} element - dom element
 *
 * @returns {array<Node>} text nodes
 */
export function getTextNodes(element) {
  const nodes = []
  const nodeType = element ? element.nodeType : null

  // Only add this if it is a text node.
  if (nodeType === 3) {
    nodes.push(element)
    // If this is an element node, then check for nested text nodes.
  } else if (nodeType === 1) {
    Array.from(element.childNodes).map((node) => nodes.push(...getTextNodes(node)))
  }

  return nodes
}

/**
 * Finds the actual offset of a node based on the given parent node.
 *
 * @param {Node} parent - parent node
 * @param {Node} node - Node in question
 * @param {number} [nodeOffset=0] - Current offset within the node.
 *
 * @returns {number} offset amount
 */
export function findNodeOffsetInElement(parent, node, nodeOffset = 0) {
  if (!parent || !node) {
    return nodeOffset
  }

  const nodes = getTextNodes(parent)
  let currentOffset = nodeOffset

  // Go through all text nodes inside the element and determine which one
  // matches the one we're looking for.
  for (let i = 0; i < nodes.length; i += 1) {
    if (nodes[i] === node) {
      return currentOffset
    }

    // Add the current length to the offset so we can keep looking.
    currentOffset += nodes[i].textContent.length
  }

  // If we got this far then we couldn't find the node at the requested offset.
  return nodeOffset
}

/**
 * Builds the individual BLR selector for the given node.
 *
 * @param {Node} node - dom node
 *
 * @returns {string} selector for given node
 */
function buildNodeSelector(node) {
  if (!node) {
    return ''
  }

  let currentSelector = node.nodeName
  const siblings = node && node.parentNode ? node.parentNode.childNodes : []

  Array.from(siblings)
    .filter((sibling) => sibling.nodeName === node.nodeName)
    .map((sibling, idx) => {
      if (sibling === node && idx) {
        currentSelector += `[${idx}]`
      }

      return sibling
    })

  return currentSelector
}

/**
 * Converts a BLR to a tree view for easy comparison.
 *
 * @param {string} blr - BLR string
 *
 * @returns {object} BLR as an Object.
 */
function blrToTree(blr = '') {
  return blr
    .toLowerCase()
    .replace(/(\[)(\d+)(])/gi, (a, b, num) => `,${num}`)
    .split('|')
    .slice(2)
    .reverse()
    .reduce((tree, node) => {
      if (!tree) {
        return {
          tag: 'text',
          offset: Number(node) || 0,
        }
      }
      const [tag, offset] = node.split(',')

      return {
        tag,
        offset: Number(offset) || 0,
        child: tree,
      }
    }, null)
}

/**
 * Builds a blr string based on given node.
 *
 * @param {object} params - node params
 * @param {Node} params.node - document node
 * @param {number} [params.offset=0] - text offset inside node
 * @param {string} [params.documentId] - document id
 * @param {string} [params.bookId] - book id
 *
 * @returns {string} blr
 */
export function nodeToBlr({ node, offset = 0, documentId, bookId }) {
  if (!node) {
    return ''
  }

  const blrComponents = []

  let current = node

  // Walk up the dom tree adding selectors to the `blrComponents`.
  while (current) {
    const type = current.nodeType

    if (!blrComponents.length) {
      const newOffset = findNodeOffsetInElement(current.parentNode, current, offset)

      blrComponents.push(newOffset)
    }

    // Only add nodes that are elements in the dom tree, ignore text/comments.
    if (type === 1) {
      const selector = buildNodeSelector(current)

      blrComponents.unshift(selector)
    }

    current = current.parentNode
  }

  // Add `documentId` and `bookId` to the `blrComponents` if they are given.
  if (documentId) {
    blrComponents.unshift(documentId)
  }

  if (bookId) {
    blrComponents.unshift(bookId)
  }

  return blrComponents.join('|')
}

/**
 * Parses a blr string into an object with more useful data, including the `selector`,
 * `documentId`, and `bookId`.
 *
 * @param {string} blr - blr in string format
 *
 * @returns {object} parsed BLR object
 * @todo write tests.
 */
export function parseBlr(blr) {
  if (!blr) {
    return null
  }
  const parsed = blr.split('|')

  return {
    blr,
    selector: blrToSelector(blr),
    tree: blrToTree(blr),
    bookId: parsed[0],
    documentId: parsed[1],
  }
}

/**
 * Adds relevant tag objects to each annotation, then it groups annotations
 * by book and document.
 *
 * Assumes annotations are from {@link getBookAnnotations} or {@link getAllAnnotations}
 *
 * @example
 * getBookAnnotations()
 *   .then(annotations => {
 *     return formatAnnotations(annotations)
 *   })
 *
 * @param {array<object>} data - Annotations response from server.
 * @returns {object} annotations sorted by book and document.
 */
export function formatAnnotations(data) {
  // Add tag objects in place of `tag_ids`
  const annotations = data.annotations
    .filter((annotation) => annotation.syncCode !== DELETE_SYNC_CODE)
    .map((annotation) => {
      const updatedAnnotation = annotation

      updatedAnnotation.tagIds = updatedAnnotation.tagIds || []
      updatedAnnotation.tags = data.tags.filter(
        (tag) => updatedAnnotation.tagIds.indexOf(tag.id) > -1,
      )
      delete updatedAnnotation.tagIds

      updatedAnnotation.start = parseBlr(updatedAnnotation.startBlr)
      updatedAnnotation.end = parseBlr(updatedAnnotation.endBlr)

      return updatedAnnotation
    })
  // Group annotations by book.
  const groupedByBook = groupByKey(annotations, 'bookId')

  // Group annotations by document in each book.
  Object.keys(groupedByBook).map((bookId) => {
    groupedByBook[bookId] = groupByKey(groupedByBook[bookId], 'documentId')

    return bookId
  })

  return groupedByBook
}

/**
 * Checks to see if the given chapter has children chapters or not.
 *
 * @param {object} chapter - chapter object (from table of contents)
 *
 * @returns {boolean} true if there are nested chapters
 * @todo write tests.
 */
export function chapterHasChildren(chapter) {
  return Boolean(chapter && chapter.nested && chapter.nested.length)
}

/**
 * Returns the text node and offset relative to the text node based on the
 * given offset and element.
 *
 * @param {Node} element - dom element
 * @param {number} offset - offset relative to start of `element`
 *
 * @returns {object} returns an object with the text node and its relative offset.
 */
export function getHighlightTextNode(element, offset) {
  const nodes = getTextNodes(element)
  let currentOffset = 0

  // Go through all text nodes inside the element and determine which one is
  // at the given offset.
  for (let i = 0; i < nodes.length; i += 1) {
    const textLength = nodes[i].textContent.length

    // If this node's content length goes over the requested offset, then we can return
    // this node as it's the one we're looking for.
    if (currentOffset + textLength >= offset) {
      return {
        node: nodes[i],
        offset: offset - currentOffset,
      }
    }
    // Add the current length to the offset so we can keep looking.
    currentOffset += textLength
  }

  // If we got this far then we couldn't find the node at the requested offset.
  return {
    node: null,
    offset: 0,
  }
}

/**
 * Function that compares the position of two annotations in an ebook based on
 * their `startBLR` and which page they're in. This function should be used
 * inside a `sort` higher order function.
 *
 * @param {object} annotationA - First annotation object to compare.
 * @param {object} annotationB - Second annotation object to compare.
 * @param {type} manifest - Table of Contents data for the book where the annotations reside.
 *
 * @returns {number} Returns a positive/negative number based on how the first annotation
 * compares to the second.
 */
export function sortAnnotationsByPosition(annotationA, annotationB, manifest) {
  if (!manifest || !manifest.byDocumentId) {
    return 0 // what to do here?
  }

  // Grab the index of the page where the annotation starts.
  const indexA = manifest.pages.findIndex((page) => page.id === annotationA.start.documentId)
  const indexB = manifest.pages.findIndex((page) => page.id === annotationB.start.documentId)

  // If the two annotations are located in the same page, we're going to have
  // to do some intelligent guessing on which one comes first. With the data we
  // have it won't be 100%, but on the average case we can get pretty close.
  if (indexA === indexB) {
    // Each annotation start should come with a tree, which is an objectified version
    // of the BLR. We can walk down the tree comparing each node to see where we diverge.
    // If the tag offset diverge, then we can assume that the node with the higher offset
    // comes later in the book, but if the node tag names diverge first, we basically just
    // have to guess as there isn't a good way for us to properly compare them without
    // actually having access to the DOM itself.
    let nodeA = annotationA.start.tree
    let nodeB = annotationB.start.tree

    while (nodeA && nodeB) {
      // If the tags are different at this point, then we'll just return by the
      // offset.
      if (nodeA.tag !== nodeB.tag || nodeA.offset !== nodeB.offset) {
        return nodeA.offset - nodeB.offset
      }
      nodeA = nodeA.child
      nodeB = nodeB.child
    }
  }

  return indexA - indexB
}

/**
 * Opens a popup player in a new window
 * @param {string} url The URL to open in the popup player
 */
export function openPopupPlayer(url) {
  window.open(
    URI(url).addSearch('isPopupPlayer', 'true'),
    null,
    'width=450,height=600,resizable=no,scrollbars=no,status=no,location=no,menubar=no',
  )
}

/**
 * Creates a new window to share a given url on Facebook
 * @param {string} url The URL to the page being shared
 */
export function shareOnFacebook(url) {
  window.open(
    `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    'pop',
    'width=600, height=400, scrollbars=no',
  )
}

/**
 * Creates a new window to share on Pinterest
 * @param {object} parameters Parameters for sharing on pinterest
 * @param {string} parameters.pageUrl The URL to the page being shared
 * @param {string} parameters.imageUrl The URL for the image being shared
 * @param {string} parameters.quote The quote to share with the media
 */
export function shareOnPinterest({ pageUrl, imageUrl, quote }) {
  window.open(
    `http://pinterest.com/pin/create/button/?url=${pageUrl}}&media=${imageUrl}&description=${quote}`,
  )
}

/**
 * shareOnEmail allows sharing a book through email
 * @param {object} parameters Email sharing parameters
 * @param {string} [parameters.title] The title of the book
 * @param {string} parameters.url The Product URL to share
 */
export function shareOnEmail({ title = 'this book', url }) {
  const emailBody = `I thought of you when I saw ${title || 'this book'} on Deseret Book!
    Check it out on Deseret Book's website. ${url}`
  const emailSubject = 'This book made me think of you'

  /**
   * Opens mailto in new window.
   * @returns {undefined} Whatever `window.open` returns idk
   */
  const openWindow = () => window.open(`mailto:?subject=${emailSubject}&body=${emailBody}`)
  let w = openWindow()

  // if the popup window didn't open, try one more time
  if (!w) {
    w = openWindow()
  }
  setTimeout(() => {
    if (w) {
      // if the popup window did manage to open, close it after one second
      w.close()
    }
  }, 1000)
}
