import * as React from 'react'
import propTypes from 'prop-types'
import { Drawer, Toolbar, Icon, Text } from '@deseretbook/react-ui'
import { shareOnFacebook, shareOnPinterest, shareOnEmail } from '../../../utils/media'
import { Menu } from '../../../dbWebUI'

/**
 * A way to share things.
 */
export default class SharePallette extends React.PureComponent {
  /**
   * Component constructor.
   */
  constructor() {
    super()
    this.getQuote = this.getQuote.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  /**
   * Returns text for sharing.
   * @returns {string} Text message
   */
  getQuote() {
    return `I found ${this.props.content.title || 'this book'} at DeseretBook. Go check it out!`
  }

  /**
   * Input change event handler.
   * @param {event} event - Input change event
   */
  handleInputChange(event) {
    const { name, value } = event.target

    this.setState({ [name]: value })
  }

  /**
   * Figures out how to render the thing.
   * @returns {function} Component
   */
  render() {
    const { content, onClose, isOpen } = this.props

    /**
     * onClick event handler.
     * @param {function} onClick - wrapped callback
     * @returns {function} closure
     */
    const handleClick = onClick => () => {
      onClick()
      onClose()
    }

    return (
      <Drawer
        position="left"
        isOpen={isOpen}
        onClose={onClose}
      >
        <Drawer.Header>
          <Toolbar titleContent="Share Content" leftActionIcon="close" onLeftAction={onClose} />
        </Drawer.Header>
        <Drawer.Content>
          <Menu>
            <Menu.List>
              <Menu.ListItem>
                <Menu.ListItem.Button type="button" onClick={handleClick(() => shareOnFacebook(content.audiobook.productUrl))}>
                  <Icon name="facebook" />
                  <Text>Share on Facebook</Text>
                </Menu.ListItem.Button>
              </Menu.ListItem>
              <Menu.ListItem>
                <Menu.ListItem.Button
                  type="button"
                  onClick={handleClick(() => shareOnPinterest({
                    pageUrl: content.audiobook.productUrl,
                    imageUrl: content.cover.fullUrl,
                    quote: this.getQuote(),
                  }))
                  }
                >
                  <Icon name="pinterest" />
                  <Text>Share on Pinterest</Text>
                </Menu.ListItem.Button>
              </Menu.ListItem>
              <Menu.ListItem>
                <Menu.ListItem.Button
                  type="button"
                  onClick={handleClick(() => shareOnEmail({
                    url: content.audiobook.productUrl,
                    title: content.title,
                  }))}
                >
                  <Icon name="email" />
                  <Text>Share with Email</Text>
                </Menu.ListItem.Button>
              </Menu.ListItem>
            </Menu.List>
          </Menu>
        </Drawer.Content>
      </Drawer>
    )
  }
}

SharePallette.propTypes = {
  content: propTypes.shape({
    title: propTypes.string,
    url: propTypes.string,
  }),
  isOpen: propTypes.bool,
  onClose: propTypes.func,
}
SharePallette.defaultProps = {
  content: {},
  isOpen: false,
  onClose: () => { },
}
