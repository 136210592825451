/**
 * Trims text to a specified length and adds ellipsis.
 *
 * @param {string} text - text to be trimmed
 * @param {number} limit - character limit
 *
 * @returns {string} trimmed text
 */
const trimText = (text, limit) => {
  let limitedText = text

  if (limitedText.length > limit) {
    limitedText = `${text.substring(0, limit)}`

    if (limitedText.charAt(limitedText.length - 1) === ' ') {
      limitedText = limitedText.trimRight()
    }

    limitedText += '...'
  }

  return limitedText
}

/**
 * Remove unwanted tags (images, media, etc.)
 * @param {Document} doc - HTML document
 */
const removeUnwantedTags = (doc) => {
  const tagsToRemove = ['img', 'video', 'audio', 'object', 'embed']

  tagsToRemove.forEach((tag) => {
    const elements = doc.querySelectorAll(tag)

    elements.forEach((el) => el.remove())
  })
}

/**
 * Remove empty tags and tags containing only whitespace
 * @param {Document} doc - HTML document
 */
const removeEmptyTags = (doc) => {
  const elements = doc.querySelectorAll('*')

  elements.forEach((el) => {
    if (el.innerHTML.trim() === '' && el.children.length === 0) {
      el.remove()
    }
  })
}

/**
 * Trims text content within HTML to a specified length and adds ellipsis.
 *
 * @param {string} html - HTML string to be trimmed
 * @param {number} limit - character limit
 *
 * @returns {string} trimmed HTML string
 */
const trimHtml = (html, limit) => {
  try {
    // Create a DOMParser to parse the HTML string
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    removeUnwantedTags(doc)
    removeEmptyTags(doc)

    // Create a TreeWalker to traverse text nodes
    const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false)

    let charCount = 0
    const textNodes = []

    // Collect text nodes and calculate character count
    while (walker.nextNode()) {
      textNodes.push(walker.currentNode)
      charCount += walker.currentNode.nodeValue.length
    }

    // If total character count is within limit, return the original HTML
    if (charCount <= limit) {
      return doc.documentElement.outerHTML
    }

    // Trim text nodes to the character limit
    let currentCharCount = 0

    for (let i = 0; i < textNodes.length; i += 1) {
      const node = textNodes[i]
      const remainingChars = limit - currentCharCount

      if (node.nodeValue.length > remainingChars) {
        node.nodeValue = `${node.nodeValue.substring(0, remainingChars).replace(/\s+$/, '')}...`
        break
      } else {
        currentCharCount += node.nodeValue.length
      }
    }

    // Remove remaining text nodes after the limit
    for (
      let i = textNodes.findIndex((node) => node.nodeValue.includes('...')) + 1;
      i < textNodes.length;
      i += 1
    ) {
      const node = textNodes[i]

      node.nodeValue = ''
    }

    // Ensure to remove any remaining empty tags after trimming
    removeEmptyTags(doc)

    return doc.documentElement.outerHTML
  } catch (error) {
    // We were not able to trim the html, use the basic trimmer instead
    return trimText(html, limit)
  }
}

export default trimHtml
