import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * A `Input` is a wrapper for the `<input />` tag. It should be used inside a `Control`.
 *
 * If you're trying to build a form, take a look at the different field components.
 * Using those will help ensure consistency.
 *
 * Based on Bulma's `.input`
 *
 * @see https://bulma.io/documentation/form/input/
 * @todo update documentation
 */
const Input = glamorous.input('input', propsToHelperClasses)

export default Input
