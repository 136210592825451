import React from 'react'
import PropTypes from 'prop-types'

import Field from './Field'

/**
 * A convenient form field component that makes it easy to add an `Input` field
 * to a form.
 *
 * This component combines the `Field`, `Control`, `FormLabel`, and `Input`
 * components together to make sure they work together properly.
 *
 * @todo Add better support for stateful colors (success, error, warning, etc.)
 * @todo update documentation
 * @param {object} props - Component props
 * @returns {function} Component
 */
function InputField(props) {
  const {
    id,
    labelContent,
    helpContent,
    fieldProps,
    labelProps,
    controlProps,
    helpProps,
    isSmall,
    isMedium,
    isLarge,
    ...rest
  } = props

  return (
    <Field {...fieldProps}>
      {labelContent ? (
        <Field.Label
          htmlFor={id}
          isSmall={isSmall}
          isMedium={isMedium}
          isLarge={isLarge}
          {...labelProps}
        >
          {labelContent}
        </Field.Label>
      ) : false}
      <Field.Control {...controlProps}>
        <Field.Input
          id={id}
          isSmall={isSmall}
          isMedium={isMedium}
          isLarge={isLarge}
          {...rest}
        />
      </Field.Control>
      {helpContent
        ? <Field.Help {...helpProps}>{helpContent}</Field.Help>
        : false}
    </Field>
  )
}

InputField.defaultProps = {
  isSmall: false,
  isMedium: false,
  isLarge: false,
  labelContent: false,
  helpContent: false,
  fieldProps: {},
  labelProps: {},
  controlProps: {},
  helpProps: {},
}

InputField.propTypes = {
  /** Input id */
  id: PropTypes.string.isRequired,
  /** Small input field */
  isSmall: PropTypes.bool,
  /** Medium input field */
  isMedium: PropTypes.bool,
  /** Large input field */
  isLarge: PropTypes.bool,
  /** Label display value */
  labelContent: PropTypes.node,
  /** Help display value */
  helpContent: PropTypes.node,
  /** Additional props for `Field` component */
  fieldProps: PropTypes.shape({}),
  /** Additional props for `Label` component */
  labelProps: PropTypes.shape({}),
  /** Additional props for `Control` component */
  controlProps: PropTypes.shape({}),
  /** Additional props for `HelpText` component */
  helpProps: PropTypes.shape({}),
}

export default InputField
