import React from 'react'
import Icon from '../Icon'

/**
 * Bookmark Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function BookmarkIcon(props) {
  return (
    <Icon
      icon="bookmark"
      {...props}
    >
      <g>
        <path d="M17,18L12,15.82L7,18V5H17M17,3H7A2,2 0 0,0 5,5V21L12,18L19,21V5C19,3.89 18.1,3 17,3Z" />
      </g>
    </Icon>
  )
}
