import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/layout/media/
 * @todo add documentation
 */
const Media = glamorous.div('media', propsToHelperClasses)

Media.Left = glamorous.div('media-left', propsToHelperClasses)
Media.Content = glamorous.div('media-content', propsToHelperClasses)
Media.Right = glamorous.div('media-right', propsToHelperClasses)

export default Media
