import React from 'react'
import propTypes from 'prop-types'
import { Level, Container, Box, Collapse } from '@deseretbook/react-ui'
import { Slider } from '../../../../dbWebUI'
import * as AudiobookHelpers from '../../../../utils/base'
import ControlView from './ControlView'
import VolumeView from './VolumeView'
import PlaybackRateView from './PlaybackRateView'

export const AUDIOBOOK_CONTROL_VIEWS = {
  control: 'control',
  volume: 'volume',
  playbackRate: 'playbackRate',
}

/**
 * Audiobook controls
 */
class AudiobookControls extends React.Component {
  /**
   * Figures out changes to state based on props.
   *
   * @param {object} nextProps - Next props
   * @param {object} prevState - Previous state
   * @returns {object} changes to state
   */
  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.dragging) {
      return { progress: nextProps.progress }
    }

    return null
  }

  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      /** Tracks whether or not the slider is currently being dragged */
      dragging: false,
      /**
       * current progress of the track.
       * Stored in state because it can vary from props when dragging
       */
      progress: 0,
      /**
       * The current view of the panel
       */
      currentView: AUDIOBOOK_CONTROL_VIEWS.control,
    }
    this.rateMenuContainer = null
    this.handleAfterChange = this.handleAfterChange.bind(this)
    this.handleBeforeChange = this.handleBeforeChange.bind(this)
    this.handleChangeRate = this.handleChangeRate.bind(this)
    this.handleChangeView = this.handleChangeView.bind(this)
  }

  /**
   * callback for event handled immediately before the slider value is changed
   */
  handleBeforeChange() {
    this.setState({ dragging: true })
  }

  /**
   * Callback for event handled immediately after the value is selected and the slider
   * is no longer being dragged.
   * percentage is a whole number between 0 and 100
   * @param {number} progress - a whole number between 0 and 100
   */
  handleAfterChange(progress) {
    this.setState({ dragging: false })
    this.props.seekTo(progress)
  }

  /**
   * Changes the playback rate of the track
   * @param {number} rate The new playback rate of the track,
   *  where 1 is normal speed
   */
  handleChangeRate(rate) {
    this.props.onChangeRate(rate)
  }

  /**
   * Changes view to the given view.
   * @param {string} view - View
   */
  handleChangeView(view) {
    this.setState({ currentView: view })
  }

  /**
   * Figures out how to render this thing.
   * @returns {function} Component
   */
  render() {
    const { duration, onChangeRate, rate } = this.props
    const { progress, currentView } = this.state

    const disabled = {
      disabled: this.props.duration === 0 || this.props.isLoading,
    }
    const currentTime = AudiobookHelpers.formatDuration(this.props.progress || 0)
    const remainingTime = AudiobookHelpers
      .formatDuration(this.props.duration - this.props.progress || 0)

    return (
      <Box className="AudiobookControls" style={{ position: 'relative' }}>
        <Slider
          className="audiobook-ui-slider"
          value={duration === 0
            ? 0
            : progress}
          min={0}
          max={duration}
          step={1}
          onBeforeChange={this.handleBeforeChange}
          onChange={val => this.setState({ progress: val })}
          onAfterChange={this.handleAfterChange}
        />
        <Container>
          <Level isMobile className="time-level">
            <Level.Left>
              <Box justifyContent="center" flex={1}>
                {currentTime}
              </Box>
            </Level.Left>
            <Level.Right>
              <Box justifyContent="center" flex={1}>
                {remainingTime}
              </Box>
            </Level.Right>
          </Level>
          <Box minHeight={4.5} isColumn justifyContent="center">
            <Collapse isCollapsed={!(currentView === AUDIOBOOK_CONTROL_VIEWS.control)}>
              <ControlView
                disabled={disabled}
                {...this.props}
                onChangeView={this.handleChangeView}
              />
            </Collapse>
            <Collapse isCollapsed={!(currentView === AUDIOBOOK_CONTROL_VIEWS.volume)}>
              <VolumeView
                volume={this.props.volume}
                onChangeVolume={this.props.onChangeVolume}
                onChangeView={this.handleChangeView}
              />
            </Collapse>
            <Collapse isCollapsed={!(currentView === AUDIOBOOK_CONTROL_VIEWS.playbackRate)}>
              <PlaybackRateView
                rate={rate}
                onChangeRate={onChangeRate}
                onChangeView={this.handleChangeView}
              />
            </Collapse>
          </Box>
        </Container>
      </Box>
    )
  }
}

AudiobookControls.defaultProps = {
  onChangeRate: () => {},
  previous: () => {},
  play: () => {},
  pause: () => {},
  next: () => {},
  seekTo: () => {},
  onChangeVolume: () => {},
  onPreviousTrack: () => {},
  onNextTrack: () => {},
  isLoading: false,
  isPlaying: false,
  duration: 0.0,
  style: {},
  progress: 0.0,
  volume: 0.15,
  rate: 1,
}

AudiobookControls.propTypes = {
  /** duration: the total duration of the track */
  duration: propTypes.number,
  /** isPlaying: the play state of the track, true is playing, false is paused */
  isPlaying: propTypes.bool,
  /** isLoading: is the track currently in a loading state? */
  isLoading: propTypes.bool,
  /** next: handler to skip to the next track */
  next: propTypes.func,
  /** onChangeVolume: handler for when the volume is changed */
  onChangeVolume: propTypes.func,
  /** onPreviousTrack: move to the previous track */
  onPreviousTrack: propTypes.func,
  /** onNextTrack: move to the next track */
  onNextTrack: propTypes.func,
  /** play: handler to toggle the play status of the current track */
  play: propTypes.func,
  /** previous: handler to seek forward */
  previous: propTypes.func,
  /** progress: total progress in percentage of the current track */
  progress: propTypes.number,
  /** seekTo: handler to seek to a specific point in the track */
  seekTo: propTypes.func,
  /** volume: volume level, number between 0 and 1 */
  volume: propTypes.number,
  /** style: style for the main container */
  style: propTypes.shape({}),
  /** onRateChange: the handler called when the playback rate changes */
  onChangeRate: propTypes.func,
  /** rate: the playback rate of the track */
  rate: propTypes.number,
  /** pause: pause the audio */
  pause: propTypes.func,
}

export default AudiobookControls
