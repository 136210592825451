/**
 * Available style options for highlights.
 */
export const HIGHLIGHT_STYLE_OPTIONS = [
  {
    value: 1,
    label: 'Block',
  },
  {
    value: 2,
    label: 'Underlined',
  },
]

/**
 * Available color options for highlights.
 */
export const HIGHLIGHT_COLOR_OPTIONS = [
  {
    hex: '#fb3335',
    value: 16462645,
    label: 'Red',
  },
  {
    hex: '#ffa64d',
    value: 16754253,
    label: 'Orange',
  },
  {
    hex: '#ffe66c',
    value: 16770668,
    label: 'Yellow',
  },
  {
    hex: '#9ee271',
    value: 10412657,
    label: 'Green',
  },
  {
    hex: '#78e1f9',
    value: 7922169,
    label: 'Light Blue',
  },
  {
    hex: '#57a5f2',
    value: 5744114,
    label: 'Blue',
  },
  {
    hex: '#a07de2',
    value: 10517986,
    label: 'Purple',
  },
  {
    hex: '#ff99d3',
    value: 16751059,
    label: 'Pink',
  },
  {
    hex: '#936b44',
    value: 9661252,
    label: 'Brown',
  },
  {
    hex: '#c6c6c6',
    value: 13027014,
    label: 'Gray',
  },
]

/**
 * Block style highlight value.
 */
export const HIGHLIGHT_BLOCK_STYLE = 1

/**
 * Underline style highlight value.
 */
export const HIGHLIGHT_UNDERLINE_STYLE = 2

/** Default action view for Annotation Action Menu. */
export const ANNOTATION_DEFAULT_ACTION = 'default'

/** Highlight action view for Annotation Action Menu. */
export const ANNOTATION_HIGHLIGHT_ACTION = 'highlight:edit'

/** Bookmark action view for Annotation Action Menu. */
export const ANNOTATION_BOOKMARK_ACTION = 'bookmark'

/** Note action view for Annotation Action Menu. */
export const ANNOTATION_NOTE_ACTION = 'note:show'

/** Delete action view for Annotation Action Menu. */
export const ANNOTATION_DELETE_ACTION = 'delete'

/** Route param that represents a BLR. */
export const BLR_ROUTE_PARAM = 'blr'

/** Route param that represents a document src. */
export const SRC_ROUTE_PARAM = 'src'

/** Route param that represents the last position */
export const AUDIOBOOK_LAST_POSITION_PARAM = 'lastPosition'

/** Route param that represents if audibook should be popup player */
export const POPUP_PARAM = 'isPopupPlayer'
