import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * Wrapper for `Icon` components.
 *
 * Based on Bulma's `.icon`
 *
 * @see https://bulma.io/documentation/elements/icon/
 * @todo update documentation
 */
const IconWrapper = glamorous.span('icon', propsToHelperClasses)

export default IconWrapper
