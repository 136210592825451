import { Component } from 'react'
import PropTypes from 'prop-types'
import { throttle, getViewportInfo } from '../../../../utils/base'

/** Default values for the `calculateBestPosition` method. */
const DEFAULT_POSITION_OPTIONS = {
  height: 0,
  spaceBetween: 0,
  paddingTop: 0,
  paddingBottom: 0,
  paddingRight: 0,
  paddingLeft: 0,
  offsetTop: 0,
  offsetLeft: 0,
}


/**
 * Render prop component that helps determine the proper position for a floating
 * menu or component in relationship to another. This component keeps track of
 * the current viewport and provides a `calculateBestPosition` method that will
 * determine the proper x/y offset that should be used when positioning a menu or
 * other floating component.
 *
 * Note about the `calculateBestPosition`, it can accept any two objects that have
 * a get method of `getBoundingClientRect`. Most likely you'll need to use a `ref`
 * to get access to the child dom element, the calculations might not work properly
 * on the first render, so you may have to trigger a re-render once the child component
 * has mounted initially.
 *
 * @example
 * <PositionManager>
 *   {({ viewport, calculateBestPosition }) => (
 *     <FloatingMenuThing
 *       ref={(menu) => { this.menu = menu }}
 *       style={calculateBestPosition(this.menu, targetDomElement)}
 *     />
 *   )}
 * </PositionManager>
 *
 * @todo It might be useful to add additional options for determining which position
 * (top/bottom/left/right) is the prefered location, and if the menu should stay fixed
 * while the target is outside the viewport, or if it should go off the viewport as well.
 * It might also be cool if there was support for left/right positioning as well.
 */
class PositionManager extends Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      /** The current viewport info */
      viewport: getViewportInfo(),
    }

    this.viewportChangeHandler = throttle(this.viewportChangeHandler.bind(this), 100)
    this.calculateBestPosition = this.calculateBestPosition.bind(this)
  }

  /**
   * Sets up event listeners.
   */
  componentDidMount() {
    window.addEventListener('resize', this.viewportChangeHandler, false)
    window.addEventListener('scroll', this.viewportChangeHandler, false)
  }

  /**
   * Removes event listeners
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.viewportChangeHandler)
    window.removeEventListener('scroll', this.viewportChangeHandler)
  }

  /**
   * Handles a change in the viewport. Updates the current values in state, this
   * will trigger a re-render so that the children components can re-position
   * themselves based on the new viewport information.
   */
  viewportChangeHandler() {
    this.setState({ viewport: getViewportInfo() })
  }

  /**
   * Calculates the best top/left offset in px for the given target based on the
   * current viewport and the position of the target. These values should generally
   * be used to add additional styles to the `menu`. When doing so it makes the most
   * sense to set the `menu`'s `position` to `fixed` as the values returned are
   * relative to the current viewport.
   *
   * @param {Node} menu - The menu that will be positioned relative to the `target`
   * @param {Node} target - The target eelement that the `menu` will be position relative to.
   * @param {object} [options={}] - Additional options/settings for determining the proper position.
   *
   * @returns {object} Best determined `top` and `left` offsets based on current viewport.
   */
  calculateBestPosition(menu, target, options = {}) {
    if (!menu || !target) {
      return {}
    }

    const { height, width, offsetTop: distanceScrolled } = this.state.viewport

    // Determine the proper options to use when calculating the best position.
    const appliedOptions = {
      ...DEFAULT_POSITION_OPTIONS,
      ...this.props.defaultOptions,
      ...options,
    }

    const targetRect = target.getBoundingClientRect()
    const menuRect = menu.getBoundingClientRect()

    // The top position is the distance from the top of the screen to the top of the
    // target (including the specified `offsetTop`). We then add the specified `spaceBetween`
    // and then since we're only calculating the offset relative to the viewport,
    // we remove the distance that the user has scrolled.
    const topPosition = (
      (targetRect.top + appliedOptions.offsetTop) - appliedOptions.spaceBetween - distanceScrolled
    )

    // The bottom position is the distance from the top of the screen to the bottom
    // of the target (including the specified `offsetTop`). We then add the specified
    // `spaceBetween` to give extra padding between the menu and its target. We also
    // add in the height of the menu to ensure we calculate the actual distance to the
    // top of where the menu should go. Finally, since we're only calculating the offset
    // relative to the viewport, we remove the distance that the user has scrolled.
    const bottomPosition = (
      (targetRect.bottom - distanceScrolled)
      + appliedOptions.offsetTop
      + appliedOptions.spaceBetween
      // If a `height` is specified in the options it means we need to use that height instead
      // of the calculated height. This is so that if the menu can have a variable height
      // then we ensure to always place the menu consistently when at the bottom position.
      + (appliedOptions.height || menuRect.height)
    )

    // The top position for when the target is above the current viewport. This
    // value is relative to the viewport.
    const fixedTopPosition = appliedOptions.paddingTop

    // The bottom position for when the target is below the current viewport.
    // This value is relative to the viewport so is calculated based on the height
    // of the viewport.
    const fixedBottomPosition = height - appliedOptions.paddingBottom - menuRect.height

    // Determines if there is enough room for the menu to fit towards the top of the screen.
    const menuCanBeOnTop = (
      targetRect.top - appliedOptions.spaceBetween - menuRect.height > distanceScrolled
    )

    // Determines if the target is currently above the viewport and if the menu should
    // just be fixed to the top of the screen.
    const targetIsAbove = bottomPosition - menuRect.height - appliedOptions.spaceBetween < 0

    // Determines if the target is currently below the viewport and if the menu should
    // just be fixed to the bottom of the screen.
    const targetIsBelow = (
      (targetRect.top + appliedOptions.offsetTop + appliedOptions.paddingBottom)
      > distanceScrolled + height
    )

    // Now based on all these calculations, determine the proper top offset.
    let top = bottomPosition

    if (menuCanBeOnTop) {
      top = topPosition
    }

    if (targetIsAbove) {
      top = fixedTopPosition
    }

    if (targetIsBelow) {
      top = fixedBottomPosition
    }

    // Now determine the best position horizontally.

    // The centered position for when the menu should be placed in the center of the
    // target.
    const centered = (
      (targetRect.left + (targetRect.width / 2) + appliedOptions.offsetLeft) - (menuRect.width / 2)
    )

    // The farLeft position is for when the menu should be placed on the very far left
    // because the centered position will overflow on the right.
    const farLeft = appliedOptions.paddingLeft

    // The farRight position is for when the menu should be placed on the very far right
    // because the centered position will overflow on the left.
    const farRight = width - appliedOptions.paddingRight - menuRect.width

    // Determine where the best place to center the menu horizontally is.
    const left = Math.min(Math.max(farLeft, centered), farRight)

    return {
      top,
      left,
    }
  }

  /**
   * Determines how to render the component.
   * @returns {function} Component
   */
  render() {
    return this.props.children({
      viewport: this.state.viewport,
      calculateBestPosition: this.calculateBestPosition,
    })
  }
}

PositionManager.defaultProps = {
  defaultOptions: DEFAULT_POSITION_OPTIONS,
}

PositionManager.propTypes = {
  /** Child render function. */
  children: PropTypes.func.isRequired,
  /** Default options applied to `calculateBestPosition`. */
  defaultOptions: PropTypes.shape({
    /** Specifies a fixed height for the menu (px) * defaults to calculated menu height */
    height: PropTypes.number,
    /** Space between menu and target (px) */
    spaceBetween: PropTypes.number,
    /** Minimum space between menu and top of viewport (px) */
    paddingTop: PropTypes.number,
    /** Minimum space between menu and bottom of viewport (px) */
    paddingBottom: PropTypes.number,
    /** Minimum space between menu and right of viewport (px) */
    paddingRight: PropTypes.number,
    /** Minimum space between menu and left of viewport (px) */
    paddingLeft: PropTypes.number,
    /** Extra space required for calculating vertical offset (px) */
    offsetTop: PropTypes.number,
    /** Extra space required for calculating horizontal offset (px) */
    offsetLeft: PropTypes.number,
  }),
}

export default PositionManager
