import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import rangy from 'rangy'
import { getBookAnnotations } from '../../../api/media'
import { DELETE_SYNC_CODE } from '../../../api/constants'
import { debounce } from '../../../utils/base'
import { nodeToBlr, getHighlightTextNode } from '../../../utils/media'
import { elementsFromPoint } from '../../../utils/polyfills'
import { ANNOTATION_DEFAULT_ACTION } from '../../../utils/constants'

import Annotation from './Annotation'
import AnnotationGutter from './AnnotationGutter'
import AnnotationActionMenu from './AnnotationActionMenu'


/**
 * HOC that adds annotation support to wrapped component.
 *
 * There are two main tools that we're using to render annotations in the eBooks.
 *
 * - Range API (we use a wrapper called rangy that provides some additional features)
 * - ClientRect API
 *
 * Basically with `Range` we can grab all of the nodes between the start and end BLR
 * values of an annotation, we can even specify an offset for the start and end as well.
 *
 * Then with a `Range` object we can break it down into all of the `textNode`'s that
 * are inside it. Every `node` in the dom has a `ClientRect`, which is basically like
 * an invisible box that surrounds it, and a `ClientRect` provides its dimensions and
 * position.
 *
 * So once we have all of the `textNode`s inside of the `Range` we can get all of the
 * `ClientRect`s for those `textNode`s. Once we have those we know exactly where on
 * the document our annotation belongs. So with that we can simple position a new
 * node that matches the position and dimensions of the `ClientRect` and give it the
 * proper styling.
 *
 * TODO debounce the resize event listener.
 * @param {Component} WrappedComponent - Component to wrap
 * @returns {function} HOC Component
 */
function WithAnnotations(WrappedComponent) {
  /**
   * Manages annotations.
   */
  class AnnotationsManager extends Component {
    /**
     * Component constructor.
     */
    constructor() {
      super()

      this.state = {
        annotations: {},
        activeAnnotation: null,
        startingAction: ANNOTATION_DEFAULT_ACTION,
      }

      this.mousedownPosition = {
        x: 0,
        y: 0,
      }

      this.touchstartPosition = {
        x: 0,
        y: 0,
      }

      this.isBlurred = true
      this.isTouching = false
      this.stopClick = false
      this.isRestoringSelection = false


      this.refreshAnnotations = this.refreshAnnotations.bind(this)
      this.getRange = this.getRange.bind(this)
      this.getHighlightNodes = this.getHighlightNodes.bind(this)
      this.getFirstVisibleBlr = this.getFirstVisibleBlr.bind(this)
      this.initializeAnnotations = this.initializeAnnotations.bind(this)
      this.annotationOnClickHandler = this.annotationOnClickHandler.bind(this)
      this.windowBlurHandler = this.windowBlurHandler.bind(this)
      this.windowFocusHandler = this.windowFocusHandler.bind(this)
      this.touchstartHandler = this.touchstartHandler.bind(this)
      this.touchendHandler = this.touchendHandler.bind(this)
      this.mousedownHandler = this.mousedownHandler.bind(this)
      this.mouseupHandler = this.mouseupHandler.bind(this)
      this.windowClickHandler = this.windowClickHandler.bind(this)
      this.clickAnnotationAtCoordinates = this.clickAnnotationAtCoordinates.bind(this)
      this.selectionHandler = this.selectionHandler.bind(this)
      this.clearSelectedAnnotation = this.clearSelectedAnnotation.bind(this)
      this.clearSelection = this.clearSelection.bind(this)
      this.triggerUpdate = this.triggerUpdate.bind(this)
      this.renderAnnotations = this.renderAnnotations.bind(this)
      this.convertSelectionToAnnotation = this.convertSelectionToAnnotation.bind(this)

      this.debouncedSelectionHandler = debounce(this.selectionHandler, 300)
    }

    /**
     * Adds event listeners when component is created.
     */
    componentDidMount() {
      // Re-render annotations every time the screen changes size.
      window.addEventListener('resize', this.triggerUpdate, false)
    }

    /**
     * Re-renders annotations when things get updated.
     */
    componentDidUpdate() {
      this.renderAnnotations()
    }

    /**
     * Cleans up event listeners before the component is destroyed.
     */
    componentWillUnmount() {
      // Remove all event listeners.
      window.removeEventListener('resize', this.triggerUpdate)
    }

    /**
     * Builds a `Range` object based on the given annotation, if a `Range` is
     * unable to be created, then `null` is returned.
     *
     * @param {object} annotation - eBook annotation
     * @returns {Range|null} Given annotation's range
     */
    getRange(annotation) {
      try {
        // Grab start/end elements based on selectors
        const startElement = this.contextDocument.querySelector(annotation.start.selector.selector)
        const endElement = this.contextDocument.querySelector(annotation.end.selector.selector)
        // Grab start/end nodes based on elements and offset
        const startNode = getHighlightTextNode(startElement, annotation.start.selector.offset)
        const endNode = getHighlightTextNode(endElement, annotation.end.selector.offset)
        // Attempt to build a range based on the start/end nodes and their offsets.
        const range = rangy.createRange(this.contextDocument)

        range.setStart(startNode.node, startNode.offset)
        range.setEnd(endNode.node, endNode.offset)

        return range
      } catch (error) {
        return null
      }
    }

    /**
     * Returns a list of the dimensions and position of every clientRect that
     * makes up the given range.
     *
     * @param {Range} range - Range instance
     * @returns {array<object>} `clientRect` positions
     */
    getHighlightNodes(range) {
      const highlightRange = this.contextDocument.createRange()
      const nodes = range.getNodes([3]) // Get all text nodes in the range

      return nodes.map((node, idx) => {
        // Update the highlight range to surround this node.
        try {
          highlightRange.selectNode(node)
          // Set offset if this is ts the first/last node in the list.
          highlightRange.setStart(node, idx === 0
            ? range.startOffset
            : 0)
          highlightRange.setEnd(node, idx === nodes.length - 1
            ? range.endOffset
            : node.length)

          // Grab the rects that make up the node.
          const rects = highlightRange.getClientRects()

          // Record the dimensions and locations of the rect.
          return Array
            .from(rects)
            .map(rect => ({
              top: rect.top + this.contextDocument.documentElement.scrollTop,
              left: rect.left,
              width: rect.width,
              height: rect.height,
            }))
        } catch (error) {
          return []
        }
      })
    }

    /**
     * This method returns the BLR of the topmost visible node on the screen.
     * Basically we go through all the text nodes and compare their positions with
     * the current scroll offset. Once we find one, we build a BLR based on it.
     *
     * @returns {string|null} formatted BLR or null if something went wrong.
     */
    getFirstVisibleBlr() {
      const { bookId, documentId } = this.state

      // We can use a treeWalker to give us only the text nodes in the document.
      const treeWalker = this.contextDocument
        .createTreeWalker(this.contextDocument, NodeFilter.SHOW_TEXT)

      // Since a treeWalker isn't iteratable, we now walk through the text nodes
      // to make a list of them.
      const textNodes = []

      while (treeWalker.nextNode()) {
        textNodes.push(treeWalker.currentNode)
      }

      // Now that we have our list of nodes, we can go through each of them and
      // figure out their position on the screen. We can do that using the Range API.
      // Once we figure out the position we can compare it to the current scroll
      // offset.
      const range = rangy.createRange(this.contextDocument)
      const visibleNode = textNodes
        .find((node) => {
          range.selectNode(node)
          const rect = range.nativeRange.getBoundingClientRect()

          return rect.height && rect.bottom > window.parent.pageYOffset
        })

      if (!visibleNode) {
        return null
      }

      // Now that we have a node to base our BLR off of, we can return the formatted BLR.
      return nodeToBlr({
        node: visibleNode,
        offset: 0,
        bookId,
        documentId,
      })
    }

    /**
     * Clear the selected annotation and reset any state variables.
     */
    clearSelectedAnnotation() {
      // Reset variables and state management stuff.
      this.setState({
        selectedText: '',
        activeAnnotation: null,
        activeRect: null,
        startingAction: ANNOTATION_DEFAULT_ACTION,
      })
      this.isRestoringSelection = false
      this.stopClick = false
      this.isTouching = false
    }

    /**
     * Clears the current selection.
     */
    clearSelection() {
      // Try to clear any selected text.
      try {
        const selection = rangy.getSelection(this.contextDocument)

        selection.removeAllRanges()
      } catch (error) {
        // ¯\_(ツ)_/¯
        // Fine. We didn't want to clear it anyway.
      }
    }

    /**
     * Builds an annotation object based on the given selection.
     *
     * @param {Selection} selection - Rangy selection
     */
    convertSelectionToAnnotation(selection) {
      const { bookId, documentId } = this.state

      // Clear everything if there isn't a selection or it's collapsed.
      if (!selection || selection.isCollapsed) {
        this.clearSelectedAnnotation()

        return
      }

      // Parse the selection to find the new activeRect
      const range = selection.rangeCount
        ? selection.getRangeAt(0)
        : null
      const parts = range
        ? this.getHighlightNodes(range)
        : []
      const activeRect = parts[0][0]
      // Parse the selection into variables needed for the annotation object.
      const focusBlr = nodeToBlr({
        node: selection.focusNode,
        offset: selection.focusOffset,
        bookId,
        documentId,
      })
      const anchorBlr = nodeToBlr({
        node: selection.anchorNode,
        offset: selection.anchorOffset,
        bookId,
        documentId,
      })

      // Compile the parsed variables into an annotation object.
      const selectionAnnotation = {
        startBlr: selection.isBackwards()
          ? focusBlr
          : anchorBlr,
        endBlr: selection.isBackwards()
          ? anchorBlr
          : focusBlr,
        parts,
        bookId,
        documentId,
        range,
      }

      this.setState({
        selectedText: selection.toString(),
        activeAnnotation: selectionAnnotation,
        startingAction: ANNOTATION_DEFAULT_ACTION,
        activeRect,
      })
    }

    /**
     * Tries to find an annotation at the given x,y coordinates and simulates
     * a click event.
     *
     * @param {number} x - x coordinate
     * @param {number} y - y coordinate
     *
     * @returns {boolean} - `true` if an annotation was clicked
     */
    clickAnnotationAtCoordinates(x, y) {
      // Find all annotations at the given point.
      const annotations = elementsFromPoint(x, y, this.contextDocument)
        .filter(el => el.classList.contains('bwapp-annotation-highlight'))

      // If there are multiple annotations at the given coordinates, then we will
      // select the shortest one. This should make it so almost all annotations
      // are reachable, we might want to come up with a more advanced algorithm
      // for this, but for now this works and it's how the mobile apps do it.
      const clickableAnnotation = annotations
        .reduce((shortest, current) => {
          if (!shortest) {
            return current
          }

          return shortest.offsetWidth > current.offsetWidth
            ? current
            : shortest
        }, null)

      // If we found an annotation to click, then click it, otherwise clear the selection.
      if (clickableAnnotation) {
        clickableAnnotation.click()

        return true
      }
      this.clearSelectedAnnotation()

      return false
    }

    /**
     * Refreshes the displayed annotations. This is used as a callback for when
     * an annotation is updated, and as a generic method to refresh the annotations
     * from the server. If a `response` is given, then we assume it's a callback
     * so we will just replace annotations based on the `response`. Otherwise,
     * we'll hit up the server and see what annotations we have to display.
     *
     * @param {object} response - server response from `sdk.saveAnnotation`
     */
    refreshAnnotations(response) {
      const { bookId, annotations } = this.state

      if (response) {
        const displayActiveAnnotation = (
          !!response.updatedAnnotation
          && !!response.updatedAnnotation.range
          && !!response.updatedAnnotation.range.nativeRange
          && response.updatedAnnotation.syncCode !== DELETE_SYNC_CODE
        )

        this.setState({
          annotations: response.annotations,
          activeAnnotation: displayActiveAnnotation
            ? response.updatedAnnotation
            : null,
        }, () => {
          if (!displayActiveAnnotation) {
            this.clearSelectedAnnotation()
            this.clearSelection()
          }

          if (typeof this.refreshAnnotationsCallback === 'function') {
            this.refreshAnnotationsCallback(response.annotations)
          }
        })

        return
      }

      if (!bookId || (annotations && annotations.length)) {
        return
      }

      // Hit up the server to get the annotations we need to display.
      getBookAnnotations({ bookId })
        .then((results) => {
          this.setState({ annotations: results })

          if (typeof this.refreshAnnotationsCallback === 'function') {
            this.refreshAnnotationsCallback(results)
          }
        })
    }

    /**
     * Initialization method that must be called before annotations can work
     * properly. WrappedComponent calls initialization method and passes the
     * dom element where annotations should be stored.
     *
     * @param {object} options - annotation options and deets
     * @param {function} [callback=()=>{}] - Callback for when things are nice and initialized
     */
    initializeAnnotations({
      context,
      iframe,
      bookId,
      documentId,
    }, callback = () => {}) {
      const contextDocument = context.ownerDocument || context

      this.iframe = iframe
      this.contextDocument = contextDocument
      this.domContext = context
      this.refreshAnnotationsCallback = callback

      this.setState({
        bookId,
        documentId,
        annotations: {},
        activeAnnotation: null,
      }, () => this.refreshAnnotations())

      // Watch new resources being loaded that might affect annotation positions,
      // and force a re-render of annotations once any of these resources loads.
      const resources = contextDocument.querySelectorAll('link, img')

      Array
        .from(resources)
        .map((resource) => {
          resource.addEventListener('load', this.triggerUpdate, false)

          return resource
        })

      // iframe window event listeners.
      iframe.contentWindow.addEventListener('focus', this.windowFocusHandler, false)
      iframe.contentWindow.addEventListener('blur', this.windowBlurHandler, false)
      iframe.contentWindow.addEventListener('click', this.windowClickHandler, false)

      // iframe document event listeners.
      contextDocument.addEventListener('touchstart', this.touchstartHandler, false)
      contextDocument.addEventListener('touchend', this.touchendHandler, false)
      contextDocument.addEventListener('mousedown', this.mousedownHandler, false)
      contextDocument.addEventListener('mouseup', this.mouseupHandler, false)
      contextDocument.addEventListener('selectionchange', this.debouncedSelectionHandler, false)
    }

    /**
     * Selects the given annotation to display the context menu.
     *
     * @param {object} annotation - annotation object to select
     * @param {type} rect - rect from `annotation.parts` that was clicked.
     * @param {string} [startingAction=ANNOTATION_DEFAULT_ACTION] - Action that starts it all
     */
    annotationOnClickHandler(annotation, rect, startingAction = ANNOTATION_DEFAULT_ACTION) {
      // Try to update the text selection to be the same as the annotation that was just clicked.
      const selection = rangy.getSelection(this.contextDocument)
      const annotationRange = this.getRange(annotation)
      const selectedRanges = annotationRange
        ? { backward: false, ranges: [annotationRange] }
        : null

      this.isRestoringSelection = false

      try {
        if (selectedRanges) {
          this.isRestoringSelection = true
          selection.restoreRanges(selectedRanges)
        }
      } catch (error) {
        this.isRestoringSelection = false
      }

      // Now we can update state to let everything else know what the active annotation is.
      this.setState({
        activeAnnotation: annotation,
        activeRect: rect,
        selectedText: selection.toString(),
        startingAction,
      })
    }

    /**
     * `selectionchange` handler. Determines if based on the current text selection
     * if the active selection should be cleared, or if we should covert the
     * selection into an annotation in order to create a new annotation.
     */
    selectionHandler() {
      // iOS does this fun thing where on blur the text selection is effectively
      // cleared. So we need to ignore any changes to selections while the iframe
      // is not in focus.
      if (this.isBlurred) {
        return
      }

      const selection = rangy.getSelection(this.contextDocument)

      // Clear any active selection if there is no current selection.
      if (selection.isCollapsed) {
        this.clearSelectedAnnotation()
      // Build a new annotation from current selection
      } else if (!this.isRestoringSelection) {
        this.convertSelectionToAnnotation(selection)
      // Mark the end of the restoration process.
      } else {
        this.isRestoringSelection = false
      }
    }

    /**
     * `blur` event handler for the iframe window. It just records that the
     * window is blurred so the selection handler will be ignored.
     */
    windowBlurHandler() {
      this.isBlurred = true
    }

    /**
     * `focus` event handler for the iframe window. It just records that the
     * window is in focus so the selection handler will not be ignored.
     */
    windowFocusHandler() {
      this.isBlurred = false
    }

    /**
     * `touchstart` event handler for the iframe document. Records the current
     * coordinates so on the `touchend` event we can decide if the touch is
     * meant to be a click or if it was a drag.
     *
     * @param {Event} e - touchstart event
     */
    touchstartHandler(e) {
      const touches = e.changedTouches

      if (!e.isTrusted) {
        return
      }

      this.ignoreTouches = false

      // If this is a multi-touch event then ignore the touchend event.
      if (touches.length !== 1) {
        this.ignoreTouches = true

        return
      }
      const touch = touches[0]

      this.touchstartPosition = {
        x: touch.clientX,
        y: touch.clientY,
      }
    }

    /**
     * `touchend` event handler for the iframe document. Provides click support
     * for touch sreens. Triggers a click on any annotation at the current coordinates
     * if the touch event is determined to be a click and not a multi-touch or drag/swipe
     * gesture.
     *
     * @param {Event} e - touchend event
     */
    touchendHandler(e) {
      const touches = e.changedTouches

      // Ignore if the touchstart was multi-touch, or if the touchend is multi-touch.
      if (!e.isTrusted || touches.length !== 1 || this.ignoreTouches) {
        return
      }

      const touch = touches[0]
      const { x, y } = this.touchstartPosition

      // Ignore if this was a drag/swipe gesture and not a click.
      if (touch.clientX !== x || touch.clientY !== y) {
        return
      }

      // If there was an annotation that was clicked, record that so once the `click`
      // event gets handled it will know to just ignore it as we've already handled
      // the event by touch.
      this.isTouching = this.clickAnnotationAtCoordinates(touch.clientX, touch.clientY)
    }

    /**
     * `mousedown` event handler for the iframe document. Records the current
     * coordinates so on the `mouseup` event we can decide if this was a click
     * or a drag.
     *
     * @param {Event} e - mousedown event
     */
    mousedownHandler(e) {
      this.stopClick = false
      this.mousedownPosition = {
        x: e.clientX,
        y: e.clientY,
      }
    }

    /**
     * `mouseup` event handler for the iframe document. Checks if there is
     * any difference between the `mousedown` and `mouseup` events. If there
     * are differences, then it probably isn't a click, so we can tell the
     * `click` event handler to just ignore it.
     *
     * @param {Event} e - `mouseup` event
     */
    mouseupHandler(e) {
      const { x, y } = this.mousedownPosition

      if (x !== e.clientX || y !== e.clientY) {
        this.stopClick = true
      }
    }

    /**
     * `click` event handler for the iframe window. Looks for an annotation
     * at the clicked coordinates to click it. No annotation is clicked if
     * any prior event handler decides the click event shouldn't happen.
     *
     * @param {Event} e - click event
     */
    windowClickHandler(e) {
      // Ignore the click if we can't trust the event, or if the touch version
      // is already handling the click, or if the click isn't really a click but
      // more like a drag.
      if (!e.isTrusted || this.isTouching || this.stopClick) {
        this.stopClick = false
        this.isTouching = false

        return
      }
      this.clickAnnotationAtCoordinates(e.clientX, e.clientY)
    }

    /**
     * Triggers an update
     */
    triggerUpdate() {
      this.forceUpdate()
    }

    /**
     * Iterates known `annotations` in state and renders `<Annotation />` components
     * in the current `context`.
     */
    renderAnnotations() {
      const {
        annotations, activeAnnotation, documentId,
      } = this.state

      if (!this.domContext || !annotations) {
        return
      }

      const documentAnnotations = documentId in annotations
        ? annotations[documentId]
        : []

      // Add position/dimension info to each annotation before rendering.
      const annotationParts = documentAnnotations
        .map((annotation) => {
          const range = this.getRange(annotation)
          const parts = range
            ? this.getHighlightNodes(range)
            : []

          return {
            ...annotation,
            parts,
            range,
          }
        })

      ReactDOM.render(
        <div>
          {annotationParts
            .map(annotation => (
              <Annotation
                key={annotation.id}
                annotation={annotation}
                isActive={annotation === activeAnnotation}
                onClick={rect => this.annotationOnClickHandler(annotation, rect)}
              />
            ))}
        </div>,
        this.domContext,
      )
    }

    /**
     * Determines how everything should render and what to pass to children.
     *
     * @returns {function} Component
     */
    render() {
      const {
        activeAnnotation,
        activeRect,
        selectedText,
        annotations,
        documentId,
        startingAction,
      } = this.state

      const { ebook, chapter } = this.props

      const bookTitle = ebook.package.metadata.title
      const chapterTitle = chapter.label

      const documentAnnotations = documentId in annotations
        ? annotations[documentId]
        : []
      const offset = this.iframe
        ? this.iframe.getBoundingClientRect()
        : { top: 0 }

      // Add position/dimension info to each annotation before rendering.
      const annotationParts = documentAnnotations
        .map((annotation) => {
          const range = this.getRange(annotation)
          const parts = range
            ? this.getHighlightNodes(range)
            : []

          return {
            ...annotation,
            parts,
            range,
          }
        })

      return (
        <div>
          <WrappedComponent
            initializeAnnotations={this.initializeAnnotations}
            getFirstVisibleBlr={this.getFirstVisibleBlr}
            updateAnnotations={this.refreshAnnotations}
            {...this.props}
          />
          <AnnotationGutter
            annotations={annotationParts}
            offset={offset}
            onClick={this.annotationOnClickHandler}
          />
          {activeAnnotation ? (
            <AnnotationActionMenu
              bookTitle={bookTitle}
              chapterTitle={chapterTitle}
              annotation={activeAnnotation}
              selectedRect={activeRect}
              selectedText={selectedText}
              onAnnotationsUpdate={this.refreshAnnotations}
              startingAction={startingAction}
              offset={offset}
            />
          ) : false}
        </div>
      )
    }
  }

  AnnotationsManager.defaultProps = {
    ebook: {},
    chapter: {},
  }

  AnnotationsManager.propTypes = {
    /** Current ebook */
    ebook: PropTypes.shape({}),
    /** Current chapter */
    chapter: PropTypes.shape({}),
  }

  return AnnotationsManager
}

export default WithAnnotations
