import React from 'react'
import { Redirect } from 'react-router-dom'
import { Columns, Button, ButtonLink } from '../../dbWebUI'
import PageLayout from '../../layouts/DefaultLayout'
import LoginForm from '../../components/users/LoginForm'
import { registerUrl } from '../../utils/routes'
import { isAuthenticated } from '../../api/users'
import ErrorComponent from '../../components/ErrorComponent'

/**
 * Login page.
 */
class LoginPage extends React.Component {
  /**
   * Component constructor
   */
  constructor() {
    super()

    this.state = {
      error: null,
    }
  }

  /**
   * Catches errors when something happens.
   * @param {object} error - Caught error
   */
  componentDidCatch(error) {
    this.setState({ error })
  }

  /**
   * Determines how the component should be rendered.
   * @returns {function} Component
   */
  render() {
    const { error } = this.state

    if (error) {
      return (
        <PageLayout>
          <ErrorComponent error={error} />
        </PageLayout>
      )
    }

    if (isAuthenticated()) {
      return <Redirect to="/library" />
    }

    return (
      <PageLayout>
        <Columns>
          <Columns.Column is6 isOffset3>
            <p className="title">Login</p>
            <LoginForm>
              <ButtonLink isPulledRight to={registerUrl()}>
                Register
              </ButtonLink>
              <Button
                isPulledRight
                isText
                onClick={() =>
                  window.open(`${
                      process.env.REACT_APP_DESERET_BOOK_BASE_URL
                    }/password/recover`)
                }
              >
                Forgot Password?
              </Button>
            </LoginForm>
          </Columns.Column>
        </Columns>
      </PageLayout>
    )
  }
}

export default LoginPage
