import URI from 'urijs'
import { getEbookContent as sdkGetEbookContent } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'
import { authenticateUrl } from '../../utils/routes'


/**
 * Retrieves the xml content of the specified portion of the eBook. Also reformats
 * links and relative paths to include proper authentication specific to BWAPP.
 *
 * @example
 * getEbookContent({ id, sku, 'chap_0.xml' })
 *   .then(content => console.log(content))
 *
 * @see https://s3.amazonaws.com/docs-linked-from-confluence/epub_content_api.swagger.html#path--media--mediaId---sku--content--filename-
 *
 * @param {object} params - ebook content request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - eBook sku
 * @param {string} params.href - location in eBook (relative file path)
 * @returns {Promise<string, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
*/
export default function getEbookContent(params = {}) {
  const { id, sku, href } = params

  return sdkGetEbookContent(params, requestOptions())
    .then((content) => {
      // Modify relative urls on static assets so they may be resolved properly.
      const base = `${process.env.REACT_APP_API_BASE}/media/${id}/${sku}/content/`

      return content
        .replace(/<([^\s>]+)([^>]*?)(src|href)=["']([^"']*?)["']([\s\S]*?)>/ig, (match, tag, before, srcAttr, srcUrl, after) => {
          const uri = new URI(srcUrl)
          let updatedSrc = srcUrl

          // Return anchor links relative to the current page.
          if (!uri.is('urn')) {
            if (uri.toString() !== uri.hash()) {
              updatedSrc = new URI(srcUrl).absoluteTo(href).toString()
            }
          }

          // Wrap asset urls with authentication.
          if (tag.toLowerCase() !== 'a') {
            updatedSrc = authenticateUrl(`${base}${updatedSrc}`)
          }

          // Open all external links in a new window.
          return `<${tag}${before}${srcAttr}="${updatedSrc}"${after}>`
        })
    })
}
