import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { Title, Box, Icon, Dropdown, ClickableContent, Text } from '@deseretbook/react-ui'
import useDropdown from '../../../hooks/useDropdown'
import getMediaUrl from '../../../utils/mediaUrl'
import { HeadphonesIcon, StarIcon, IconWrapper, PlusLogo } from '../../../dbWebUI/icons/'
import './MediaCard.css'
import { detailsUrl } from '../../../utils/routes'
import PlayerWrapper from './PlayerWrapper'

/**
 * Displays additional icons on the media card.
 *
 * @params {object} props - component props
 *
 * @returns {function} Component
 */
const MediaCardIcon = ({ isAudiobook, isFavorite, isPlus }) => (
  <>
    {isAudiobook ? (
      <div className="badge audiobook has-shadow">
        <IconWrapper hasTextWhite>
          <HeadphonesIcon className="badge-icon" />
        </IconWrapper>
      </div>
    ) : (
      false
    )}
    {isFavorite ? (
      <div className="badge favorite has-shadow">
        <IconWrapper hasTextWhite>
          <StarIcon className="badge-icon" />
        </IconWrapper>
      </div>
    ) : (
      false
    )}
    {isPlus ? (
      <div className="badge plus has-shadow has-background-plus">
        <IconWrapper hasTextWhite className="plus-wrapper">
          <PlusLogo className="plus-badge" />
        </IconWrapper>
      </div>
    ) : (
      false
    )}
  </>
)

MediaCardIcon.defaultProps = {
  isAudiobook: false,
  isPlus: false,
  isFavorite: false,
}

MediaCardIcon.propTypes = {
  isAudiobook: PropTypes.bool,
  isPlus: PropTypes.bool,
  isFavorite: PropTypes.bool,
}

/**
 * A component for displaying a media object.
 *
 * TODO write tests for this.
 * TODO Add story for this
 * @param {object} props - component props
 * @returns {function} Component
 */
function MediaCard(props) {
  const {
    className,
    media,
    isAudiobook,
    isEbook,
    isFavorite,
    isPlus,
    showOptions,
    mediaType,
    ...rest
  } = props

  const dropdown = useDropdown()

  if (!media) {
    return false
  }
  const classes = classNames('MediaCard', className)
  const titleClasses = classNames('MediaCardTitle', className)
  const linkClasses = classNames('MediaCardLink', className)

  const landingPage = detailsUrl(media.mediaId, media[mediaType] && media[mediaType].sku)

  // Create a list of authors from the media object.
  const authorList =
    (media &&
      media.authors &&
      Array.isArray(media.authors) &&
      media.authors.length &&
      media.authors.map((author) => `${author.firstName} ${author.lastName}`).join(', ')) ||
    ''

  /**
   * Truncates the title to the given character limit.
   * This allows us to have multiple lines of text with ellipsis.
   *
   * @param {string} title - The book title
   * @param {number} charLimit - The character limit
   * @returns {string} A truncated string
   */
  const getTruncatedTitle = (title, charLimit) => {
    if (title.length <= charLimit) {
      return title
    }

    return `${title.slice(0, charLimit)}...`
  }

  const figure = (
    <PlayerWrapper
      isAudiobook={isAudiobook}
      media={media}
      className={classes}
      style={{ cursor: 'pointer', marginBottom: '0.5rem' }}
      to={getMediaUrl(media)}
      {...rest}
    >
      <figure className="image has-shadow">
        <img src={media.cover.listingUrl} alt={media.title} />
        <MediaCardIcon isAudiobook={isAudiobook} isFavorite={isFavorite} isPlus={isPlus} />
      </figure>
    </PlayerWrapper>
  )

  if (!showOptions) {
    return figure
  }

  const titleInfo = (
    <Title
      title={getTruncatedTitle(media.title, 30)}
      titleProps={{
        as: 'h1',
        title: media.title,
        style: { fontSize: '0.75rem', lineHeight: 1.2, marginBottom: '0.25rem' },
      }}
      subtitleHasEllipsis
      subtitle={authorList}
      subtitleProps={{ title: authorList, as: 'p' }}
    />
  )

  /** TODO add an `Image` component and change this instance of it. */
  return (
    <>
      <div style={{ marginBottom: '1.5rem' }}>
        {figure}
        <Box alignItems="start" justifyContent="space-between">
          <PlayerWrapper
            isAudiobook={isAudiobook}
            media={media}
            className={titleClasses}
            style={{ cursor: 'pointer' }}
            to={getMediaUrl(media)}
            {...rest}
          >
            {titleInfo}
          </PlayerWrapper>
          <ClickableContent isPaddingless>
            <div {...dropdown.triggerProps}>
              <Icon name="options" color="bookshelf" />
            </div>
          </ClickableContent>
        </Box>
      </div>
      <Dropdown {...dropdown.dropdownProps}>
        <Link to={landingPage} {...rest} className={linkClasses}>
          <Text>Title Details</Text>
        </Link>
      </Dropdown>
    </>
  )
}

MediaCard.defaultProps = {
  className: '',
  isAudiobook: false,
  isEbook: false,
  isPlus: false,
  isFavorite: false,
  showOptions: true,
  mediaType: 'ebook',
}

MediaCard.propTypes = {
  /** Additional classes */
  className: PropTypes.string,
  /** Media object to be rendered */
  media: PropTypes.shape({
    mediaId: PropTypes.number,
    bookId: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    authors: PropTypes.shape([]),
    cover: PropTypes.shape({
      listingUrl: PropTypes.string,
    }),
    topics: PropTypes.shape([]),
    categories: PropTypes.shape([]),
    ebook: PropTypes.shape({
      sku: PropTypes.string,
    }),
    audiobook: PropTypes.shape({
      sku: PropTypes.string,
    }),
  }).isRequired,
  isAudiobook: PropTypes.bool,
  isEbook: PropTypes.bool,
  isPlus: PropTypes.bool,
  isFavorite: PropTypes.bool,
  showOptions: PropTypes.bool,
  mediaType: PropTypes.string,
}

export default MediaCard
