import { saveAnnotation as sdk } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'
import { formatDate, uuid } from '../../utils/base'
import { formatAnnotations } from '../../utils/media'


/**
 * Creates a new annotation.
 *
 * An annotation can contain up to one of each of the following:
 *
 * - a bookmark
 * - a link to another blr
 * - a highlight
 * - a note
 *
 * @example <caption>creating a new bookmark</caption>
 * createAnnotation({ bookId, documentId, startBlr, bookmark: { name } })
 *   .then(annotations => console.log(annotations))
 *
 * @example <caption>creating a new highlight</caption>
 * createAnnotation({ bookId, documentId, startBlr, endBlr, highlight: { color, style, content } })
 *   .then(annotations => console.log(annotations)
 *
 * @example <caption>creating a new link</caption>
 * createAnnotation({ bookId, documentId, startBlr, endBlr, link: { toBlr } })
 *   .then(annotations => console.log(annotations)
 *
 * @example <caption>creating a new note</caption>
 * createAnnotation({ bookId, documentId, startBlr, endBlr, note: { title, text } })
 *   .then(annotations => console.log(annotations)
 *
 * @param {object} params - annotation parameters
 * @param {string} params.bookId - book id
 * @param {string} params.documentId - document id
 * @param {string} params.startBlr - string in BLR format
 * @param {string} [params.endBlr] - string in BLR format
 * @param {object} [params.bookmark] - bookmark
 * @param {string} params.bookmark.name - title of bookmark
 * @param {object} [params.highlight] - highlight
 * @param {string} params.highlight.color - highlight 32bit color
 * @param {number} params.highlight.style - style of highlight
 * @param {string} params.highlight.content - text of book being highlighted
 * @param {object} [params.note] - note. Either `title` or `text` is required.
 * @param {string} [params.note.title] - note's title
 * @param {string} [params.note.text] - note's text content
 * @param {Array<string>}  [params.tagIds] - tagIds array
 * @param {object} [params.link] - link to another point in the book
 * @param {string} params.link.toBlr - BLR to another position
 * @param {object} [options={}] - additional request options
 * @returns {Promise} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function createAnnotation(params = {}) {
  // Set the `created` and `id` field as those are app specific.
  const created = formatDate(new Date())
  const id = uuid()

  return sdk({ id, created, ...params }, requestOptions())
    .then((data) => {
      const formatted = formatAnnotations(data)

      return {
        annotations: formatted[params.bookId] || {},
        updatedAnnotation: data.updatedAnnotation,
      }
    })
}
