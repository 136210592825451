/* eslint import/prefer-default-export: 0 */


/**
 * Filters an object for keys that are truthy and then converts the key names
 * beginning with the helper keywords ("is" and "has") and returns a string of
 * all these valid keywords. This is used to convert props to helper classnames on
 * any component.
 *
 * @param {object} props - props to convert to classes
 *
 * @returns {string} helper class names
 */
export function propsToHelperClasses(props = {}) {
  return Object.keys(props)
    // only work with prpos that evaluate to truthy
    .filter(prop => props[prop])
    // convert to kebab-case
    .map((prop) => {
      if (prop.search(/([a-z].)([0-9]{2,3}x[0-9]{2,3})/g) > -1) {
        return prop.replace(/([a-z].)([0-9]{2,3}x[0-9]{2,3})/g, '$1-$2').toLowerCase()
      }

      return prop
        .replace(/([a-z])([A-Z0-9])/g, '$1-$2')
        .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
        .toLowerCase()
    })
    // only return the classes with the proper prefix
    .filter(propClass => propClass.indexOf('is-') === 0 || propClass.indexOf('has-') === 0)
    // convert to string
    .join(' ')
}
