import { TOKEN_STORAGE_KEY } from '../constants'


/**
 * Returns the current user's token, or an empty string if not logged in.
 *
 * @returns {string} token
 */
export default function getToken() {
  if (!window || !window.localStorage) {
    return ''
  }

  return window.localStorage.getItem(TOKEN_STORAGE_KEY) || ''
}
