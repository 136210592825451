import React from 'react'
import PropTypes from 'prop-types'
import { Button, SelectField } from '../../../dbWebUI'
import UnorderedListIcon from '@deseretbook/icons/react/unordered-list'
import GridIcon from '@deseretbook/icons/react/grid'
import FilterIcon from '@deseretbook/icons/react/filter'
import { VIEW_OPTIONS, SORT_OPTIONS } from '../constants'

/**
 * A `ViewOptions` component with added filter and sort options.
 *
 * @todo Write tests for this
 * @todo Add story for this
 */
export const ViewOptions = ({ currentView, toggleView, currentSort, updateSort }) => {
  const handleOnChange = (value) => {
    const { target } = value

    updateSort(target.value)
  }
  return (
    <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex', alignItems: 'start' }}>
      <FilterIcon width="2rem" height="2rem" />
      <SelectField
        fieldProps={{ style: { display: 'inline-block', marginRight: '0.5rem' } }}
        value={currentSort}
        onChange={handleOnChange}
        options={SORT_OPTIONS}
      />
      <Button
        style={{ marginRight: '0.5rem' }}
        onClick={() => {
          toggleView(VIEW_OPTIONS.list)
        }}
        title="List View"
        aria-label="List View"
      >
        <UnorderedListIcon
          color={currentView === 'list' ? '#00d1b2' : 'inherit'}
          width="2rem"
          height="2rem"
        />
      </Button>
      <Button
        aria-label="Grid View"
        title="Grid View"
        onClick={() => {
          toggleView(VIEW_OPTIONS.grid)
        }}
      >
        <GridIcon
          color={currentView === 'grid' ? '#00d1b2' : 'inherit'}
          width="1.5rem"
          height="1.5rem"
        />
      </Button>
    </div>
  )
}

ViewOptions.defaultProps = {
  currentView: VIEW_OPTIONS.grid,
  toggleView: () => {},
  currentSort: SORT_OPTIONS.recentlyAdded,
  updateSort: () => {},
}

ViewOptions.propTypes = {
  /** The current media view option */
  currentView: PropTypes.string,
  /** Updates the current view method (list, grid) */
  toggleView: PropTypes.func,
  /** The current media sorting option */
  currentSort: PropTypes.string,
  /** Updates the current sorting option */
  updateSort: PropTypes.func,
}
