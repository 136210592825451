import React from 'react'
import PropTypes from 'prop-types'
import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

import './Sidebar.css'


/**
 * Basic wrapper for the <Sidebar /> component.
 */
const SidebarWrapper = glamorous.div('Sidebar', propsToHelperClasses)


/**
 * `<Sidebar />` component is the main component for building a sidebar.
 * The structure of a sidebar should follow:
 * - `<Sidebar />`
 *   - `<Sidebar.Trigger />`
 *     - Trigger content
 *   - `<Sidebar.Content />`
 *     - Sidebar content
 *   - `<Sidebar.Overlay />`
 *
 * To add an overlay between the sidebar and the regular page content, just add
 * a `<Sidebar.Overlay />` component as a direct child of the `<Sidebar />`
 *
 * If the sidebar is closed, then we automatically set the `aria-hidden` prop.
 * This will ensure screen readers don't try to access the `<Sidebar />`'s content
 * while it isn't open. We don't want to set the regular `hidden` prop because \
 * that destroys our animations.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
const Sidebar = props => <SidebarWrapper aria-hidden={!props.isOpen} {...props} />

Sidebar.defaultProps = {
  isOpen: false,
}

Sidebar.propTypes = {
  /** If the sidebar is visible or not. */
  isOpen: PropTypes.bool,
}

/**
 * `<Sidebar.Overlay />` is used as a direct child of a `<Sidebar />` to provide
 * a dark overlay inbetween the sidebar and the page's content.
 *
 * Note: An `onClick` should be added to the overlay so if a user clicks on it,
 * it will close the sidebar.
 */
Sidebar.Overlay = glamorous.div('SidebarOverlay', propsToHelperClasses)

/**
 * `<Sidebar.Trigger />` is a basic wrapper for any content that is included with
 * the regular page content. This is just a convenient place to put a `<Button />`
 * or something the user can interact with to trigger the sidebar open.
 */
Sidebar.Trigger = glamorous.div('SidebarTrigger', propsToHelperClasses)

/**
 * `<Sidebar.Content />` is the main content area of the sidebar. It is only displayed
 * when `isOpen` is set on the parent `<Sidebar />`.
 *
 * - Use the `width` prop to specify the width of the sidebar.
 * - The sidebar can be opened from the right of the screen by adding the `isRight` prop.
 */
Sidebar.Content = glamorous.div('SidebarContent', propsToHelperClasses, (props) => {
  const { width } = props
  const height = '100%'

  // Position the content to the right side.
  if (props.isRight) {
    return {
      width,
      height,
      top: 0,
      right: `-${width}`,
    }
  }

  // Position the content to the left side.
  return {
    width,
    height,
    top: 0,
    left: `-${width}`,
  }
})

Sidebar.Content.defaultProps = {
  width: '20rem',
  isRight: false,
}

Sidebar.Content.propTypes = {
  /** Width of sidebar content. */
  width: PropTypes.string,
  /** Positions the sidebar on the right side of the screen. */
  isRight: PropTypes.bool,
}


export default Sidebar
