import React from 'react'
import RCSlider from 'rc-slider'
import PropTypes from 'prop-types'
import { styled } from '@deseretbook/react-ui'
import 'rc-slider/assets/index.css'

const StyledSlider = styled('div')(({ theme }) => ({
  '.rc-slider-track': {
    backgroundColor: theme.palette.primary.main,
  },
  '.rc-slider-handle': {
    backgroundColor: theme.palette.primary.main,
  },
  '.rc-slider-step': {
    cursor: 'pointer',
  },
}))

/**
 * Slider component.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
const Slider = props => (
  <StyledSlider>
    <RCSlider {...props} />
  </StyledSlider>
)

Slider.propTypes = {
  style: PropTypes.shape({}),
}

Slider.defaultProps = {
  style: {},
}

export default Slider
