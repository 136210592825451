import React from 'react'
import propTypes from 'prop-types'
import './Progress.css'

/**
 * Displays the progress of a task from 0 to 100
 *
 * @param {number} value - The value of the progress bar
 *
 * @returns {function} Component
 */
const Progress = ({ value }) => <progress className="progress" min={0} max={100} value={value} />

Progress.defaultProps = {
  value: 0,
}

Progress.propTypes = {
  value: propTypes.number,
}

export default Progress
