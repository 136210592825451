import * as React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from '@deseretbook/react-ui'
import bookshelfTheme from './bookshelfTheme'

/**
 * Wrapper of theme provider. Not really needed but whatever.
 * @returns {function} Component
 */
export default function BookshelfThemeProvider({ children }) {
  return <ThemeProvider theme={bookshelfTheme}>{children}</ThemeProvider>
}

BookshelfThemeProvider.propTypes = {
  children: PropTypes.node,
}

BookshelfThemeProvider.defaultProps = {
  children: null,
}
