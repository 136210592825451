import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import UnorderedListIcon from '@deseretbook/icons/react/unordered-list'
import GridIcon from '@deseretbook/icons/react/grid'
import { Level, Button, Columns, InputField, Tabs } from '../../../dbWebUI'
import MediaGrid from './MediaGrid'
import MediaList from '../mediaList/MediaList'
import { ViewOptions } from '../mediaCard/ViewOptions'
import { VIEW_OPTIONS, TABS } from '../constants'

const defaultPageCount = 36

/**
 * A `MediaGrid` component with added filter options.
 *
 * @todo Write tests for this
 * @todo Add story for this
 */
const FilteredMediaGrid = ({ media, updateSortMethod, sortMethod, hasPagination, ...rest }) => {
  const defaultMediaView = localStorage.getItem('mediaView') || VIEW_OPTIONS.grid
  const defaultSearch = localStorage.getItem('search') || ''
  const defaultMediaType = localStorage.getItem('mediaType') || TABS.ebook

  const [count, setCount] = useState(defaultPageCount)
  const [view, setView] = useState(defaultMediaView)
  const [ebooks, setEbooks] = useState([])
  const [audiobooks, setAudiobooks] = useState([])
  const [tab, setTab] = useState(defaultMediaType)
  const [search, setSearch] = useState(defaultSearch)
  const [debounce, setDebounce] = useState(null)
  const [filteredMedia, setFilteredMedia] = useState([])
  const currentMedia = tab === TABS.ebook ? ebooks : audiobooks

  /**
   * Applies current filters to available media items.
   *
   * @param {array} filterMedia - media items to filter.
   *
   * @returns {array} filtered media items.
   */
  const getFilteredMedia = (filterMedia) => {
    const filtered = filterMedia.filter((mediaItem) => {
      const title = search.toLowerCase()
      // Find if any authors of this media matches the query string
      const authorMatches = mediaItem.authors.filter(
        (author) => `${author.firstName} ${author.lastName}`.toLowerCase().indexOf(title) > -1,
      )

      // Check if title matches the query string
      const titleMatch = search ? mediaItem.title.toLowerCase().indexOf(title) > -1 : true

      const isMatch = titleMatch || authorMatches.length

      return isMatch
    })

    return filtered
  }

  /**
   * Updates the total number of media items displayed to be increased by the
   * `perPage` value.
   */
  const loadMoreMedia = () => {
    setCount(count + defaultPageCount)
  }

  /**
   * Changes the current tab
   * @param {tab} newTab Tab to open
   */
  const toggleTab = (newTab) => {
    localStorage.setItem('mediaType', newTab)
    setTab(newTab)
  }

  /**
   * Changes the current view
   * @param {string} newView View to use. Can be list or grid
   */
  const toggleView = (newView) => {
    localStorage.setItem('mediaView', newView)
    setView(newView)
  }

  /**
   * Updates the filter values based on the given input change event.
   *
   * @param {element} e - input change event
   */
  const updateFilters = (e) => {
    const { value } = e.target

    // Reset the count to only display the default number of results.
    setCount(defaultPageCount)

    setSearch(value)
  }

  /**
   * Updates the filtered media based on the current search query.
   */
  const updateFilteredMedia = () => {
    const newFilteredMedia = getFilteredMedia(currentMedia)

    setFilteredMedia(newFilteredMedia)
  }

  // Set ebook and audiobook lists from media
  useEffect(() => {
    const newEbooks = []
    const newAudiobooks = []
    const ebookIds = new Set() // Set to track unique IDs for ebooks
    const audiobookIds = new Set() // Set to track unique IDs for audiobooks

    media.forEach((mediaItem) => {
      if (mediaItem.ebook) {
        const ebookItem = Object.assign({}, mediaItem)

        delete ebookItem.audiobook

        // Check for duplicates using unique ID
        if (!ebookIds.has(ebookItem.mediaId)) {
          ebookIds.add(ebookItem.mediaId)
          newEbooks.push(ebookItem)
        }
      }

      if (mediaItem.audiobook) {
        const audiobookItem = Object.assign({}, mediaItem)

        delete audiobookItem.ebook

        // Check for duplicates using unique ID
        if (!audiobookIds.has(audiobookItem.mediaId)) {
          audiobookIds.add(audiobookItem.mediaId)
          newAudiobooks.push(audiobookItem)
        }
      }
    })

    setEbooks(newEbooks)
    setAudiobooks(newAudiobooks)
  }, [media])

  useEffect(() => {
    if (currentMedia.length) {
      updateFilteredMedia()
    }
    // eslint-disable-next-line
  }, [ebooks, audiobooks, tab, currentMedia])

  useEffect(() => {
    clearTimeout(debounce)

    const timeoutId = setTimeout(() => {
      if (currentMedia.length) {
        updateFilteredMedia()
        localStorage.setItem('search', search)
      }
    }, 300)

    setDebounce(timeoutId)

    // eslint-disable-next-line
  }, [search, currentMedia])

  const paginatedMedia = hasPagination ? filteredMedia.slice(0, count) : filteredMedia
  const canLoadMore = paginatedMedia.length < filteredMedia.length
  const totalLength = filteredMedia.length

  const listViewButtons = (
    <div style={{ position: 'absolute', top: 0, right: 0 }}>
      <Button
        style={{ marginRight: '0.5rem' }}
        onClick={() => {
          toggleView(VIEW_OPTIONS.list)
        }}
        title="List View"
        aria-label="List View"
      >
        <UnorderedListIcon
          color={view === 'list' ? '#00d1b2' : 'inherit'}
          width="2rem"
          height="2rem"
        />
      </Button>
      <Button
        aria-label="Grid View"
        title="Grid View"
        onClick={() => {
          toggleView(VIEW_OPTIONS.grid)
        }}
      >
        <GridIcon color={view === 'grid' ? '#00d1b2' : 'inherit'} width="1.5rem" height="1.5rem" />
      </Button>
    </div>
  )

  return (
    <div {...rest} style={{ position: 'relative' }}>
      <ViewOptions
        currentView={view}
        toggleView={toggleView}
        currentSort={sortMethod}
        updateSort={updateSortMethod}
      />
      {listViewButtons}
      <Tabs isLeft>
        <Tabs.Tab isActive={tab === TABS.ebook}>
          <Tabs.Label onClick={() => toggleTab(TABS.ebook)} to="#">
            <Tabs.Title>Ebooks</Tabs.Title>
          </Tabs.Label>
        </Tabs.Tab>
        <Tabs.Tab isActive={tab === TABS.audiobook}>
          <Tabs.Label onClick={() => toggleTab(TABS.audiobook)} to="#">
            <Tabs.Title>Audiobooks</Tabs.Title>
          </Tabs.Label>
        </Tabs.Tab>
      </Tabs>
      <Level>
        <Level.Left>
          <Level.Item>
            <div className="is-fullwidth">
              Displaying {paginatedMedia.length} of {totalLength}
            </div>
          </Level.Item>
        </Level.Left>
        <Level.Right>
          <Level.Item>
            <InputField
              type="search"
              name="title"
              id="titleFilter"
              placeholder="Filter by title or author"
              value={search}
              onChange={updateFilters}
              fieldProps={{ isFullwidth: true }}
              style={{ minWidth: '20rem' }}
            />
          </Level.Item>
        </Level.Right>
      </Level>
      {view === 'grid' ? (
        <MediaGrid media={paginatedMedia} mediaType={tab} />
      ) : (
        <MediaList media={paginatedMedia} mediaType={tab} />
      )}

      {hasPagination && canLoadMore ? (
        <Columns isMobile>
          <Columns.Column is12Mobile isOffsetOneThirdTablet isOneThirdTablet>
            <Button isPrimary isMedium isFullwidth onClick={loadMoreMedia}>
              Load More
            </Button>
          </Columns.Column>
        </Columns>
      ) : (
        false
      )}
    </div>
  )
}

FilteredMediaGrid.defaultProps = {
  media: [],
  hasPagination: false,
  perPage: 36,
  pagination: {},
  sortMethod: '',
  updateSortMethod: () => {},
  ebooks: [],
  audiobooks: [],
}

FilteredMediaGrid.propTypes = {
  /** Media objects to display as `MediaCard`s */
  media: PropTypes.arrayOf({}),
  /** If the displayed results should be paginated. */
  hasPagination: PropTypes.bool,
  /** Number of results to display per page when paginating. */
  perPage: PropTypes.number,
  /** Pagination info for the library */
  pagination: PropTypes.shape({}),
  /** The current sorting method */
  sortMethod: PropTypes.string,
  /** Method to update the current sort method */
  updateSortMethod: PropTypes.func,
  /** Ebook media items */
  ebooks: PropTypes.arrayOf({}),
  /** Audiobook media items */
  audiobooks: PropTypes.arrayOf({}),
}

export default FilteredMediaGrid
