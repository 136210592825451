import URI from 'urijs'

import { getToken } from '../api/users'

/**
 * Route name helper that returns the homepage url
 *
 * @returns {string} homepage url
 */
export const homeUrl = () => '/'

/**
 * Route name helper that returns the library url
 *
 * @returns {string} library url
 */
export const libraryUrl = () => '/library'

/**
 * Route name helper that returns the eBook reader url
 *
 * @param {string} id - media id
 * @param {string} sku - eBook sku
 * @returns {string} eBook reader url
 */
export const readEbookUrl = (id, sku) => `/media/${id}/read/${sku}`

/**
 * Route name helper that returns the audiobook player url
 *
 * @param {string} id - media id
 * @param {string} sku - audiobook sku
 * @returns {string} audiobook player url
 */
export const listenAudiobookUrl = (id, sku) => `/media/${id}/listen/${sku}`

/**
 * Route name helper that returns the details page url
 *
 * @param {string} id - media id
 * @param {string} sku - audiobook sku
 * @returns {string} audiobook detail url
 */
export const detailsUrl = (id, sku) => `/media/${id}/details/${sku}`

/**
 * Route name helper that returns the login url
 *
 * @returns {string} login url
 */
export const loginUrl = () => '/login'

/**
 * Route name helper that returns the logout url
 *
 * @returns {string} logout url
 */
export const logoutUrl = () => '/logout'

/**
 * Route name helper that returns the registration url
 *
 * @returns {string} registration url
 */
export const registerUrl = () => '/register'

/**
 * Adds authentication to the given url.
 *
 * @param {string} url - url string to add authentication to
 * @returns {string} url with authentication.
 */
export function authenticateUrl(url) {
  return url
    ? URI(url)
        .addSearch('t', getToken())
        .toString()
    : ''
}
