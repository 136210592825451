import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AnnotationFab from './AnnotationFab'

/**
 * Groups annotations by their vertical position. Meaning if annotations are on the same line
 * they need to be grouped together. This is their story.
 *
 * @param {array} [annotations=[]] - Annotations to group by vertical position
 * @returns {object} Annotations grouped by vertical position
 */
function groupAnnotationsVertically(annotations = []) {
  return annotations
    .reduce((groups, annotation) => {
      const updatedGroups = groups

      const position = (annotation.range && annotation.range.nativeRange)
        ? annotation.range.nativeRange.getBoundingClientRect()
        : {}

      // Group FABs by the vertical position of the baseline of the annotation part.
      // We're more likely to group all the annotations properly this way because
      // some might share a baseline, but have a different height.

      // Now group the annotation based on the position.
      if (position.top && position.top in groups) {
        updatedGroups[position.top] = [...groups[position.top], annotation]
          // Sort the annotations at the position by their first appearance left-to-right.
          .sort((a, b) => {
            // Get the upper-left highlight part from A.
            const upperLeftA = (a.parts || [])
              .reduce((flattened, list) => [...flattened, ...list], [])
              .reduce((current, part) => (
                (part.top !== position.top && (current.left || 0) < part.left)
                  ? current
                  : part
              ), {})
            // Get the upper-left highlight part from B.
            const upperLeftB = (b.parts || [])
              .reduce((flattened, list) => [...flattened, ...list], [])
              .reduce((current, part) => (
                (part.top !== position.top && (current.left || 0) < part.left)
                  ? current
                  : part
              ), {})

            // Now sort the two by their leftness.
            return upperLeftA.left > upperLeftB.left
          })
      } else {
        updatedGroups[position.top] = [annotation]
      }

      return updatedGroups
    }, {})
}

/**
 * Manages annotation FABs for a set of annotations.
 * Groups together annotations by line and determines which FAB should be available.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function AnnotationGutter({
  annotations,
  offset,
  className,
  onClick,
  ...rest
}) {
  const classes = classNames('AnnotationGutter', className)
  // Figure out the vertical position of each FAB that will be displayed and group
  // them together so we don't have any overlaps.
  const leftAnnotations = annotations
    // Filter out annotations that aren't worthy of a FAB.
    .filter(annotation => (
      !!(annotation.bookmark && annotation.bookmark.id)
    ))
  const rightAnnotations = annotations
    // Filter out annotations that aren't worthy of a FAB.
    .filter(annotation => (
      !!(annotation.note && annotation.note.id)
    ))

  const leftAnnotationsGroupedByPosition = groupAnnotationsVertically(leftAnnotations)
  const rightAnnotationsGroupedByPosition = groupAnnotationsVertically(rightAnnotations)

  return (
    <div className={classes} {...rest}>
      {Object.keys(leftAnnotationsGroupedByPosition)
        .map(key => (
          <AnnotationFab
            key={key}
            annotations={leftAnnotationsGroupedByPosition[key]}
            // For some reason the offset given by Chrome negates the top value.
            offsetY={key + Math.abs(offset.top)}
            onClick={onClick}
            isLeft
          />
        ))}
      {Object.keys(rightAnnotationsGroupedByPosition)
        .map(key => (
          <AnnotationFab
            key={key}
            annotations={rightAnnotationsGroupedByPosition[key]}
            // For some reason the offset given by Chrome negates the top value.
            offsetY={key + Math.abs(offset.top)}
            onClick={onClick}
          />
        ))}
    </div>
  )
}

AnnotationGutter.defaultProps = {
  annotations: [],
  offset: { top: 0 },
  onClick: () => {},
  className: '',
}

AnnotationGutter.propTypes = {
  /** List of annotations rendered in gutter. */
  annotations: PropTypes.arrayOf(PropTypes.object),
  /** Object with offset position data. */
  offset: PropTypes.shape({}),
  /** Click handler for when an annotation FAB is clicked. */
  onClick: PropTypes.func,
  /** Additional class names. */
  className: PropTypes.string,
}

export default AnnotationGutter
