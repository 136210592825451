import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'


/**
 * A `Label` is a wrapper for the `<label>` tag. It should be used inside a `Field`
 * component alongside an input component.
 *
 * Be sure to use `htmlFor={inputName}` so that when the label is clicked, the
 input will have focus.
 *
 * If you're trying to build a form, take a look at the different field components.
 * Using those will help ensure consistency.
 *
 * Based on Bulma's `.label`
 *
 * @see https://bulma.io/documentation/form/general/#form-field
 * @todo update documentation
 */

const Label = glamorous.label('label', propsToHelperClasses)

export default Label
