import { getEbookToc as sdkGetEbookToc } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'

/**
 * Retrieves a table of contents for the given eBook.
 *
 * @example
 * getEbookToc({ id, sku })
 *   .then(toc => console.log(toc))
 *
 * @see https://s3.amazonaws.com/docs-linked-from-confluence/epub_content_api.swagger.html#path--media--mediaId---sku--toc
 *
 * @param {object} params - ebook toc request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - eBook sku
 * @returns {Promise<Array<object>, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getEbookToc(params = {}) {
  return sdkGetEbookToc(params, requestOptions())
}
