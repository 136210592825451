import React from 'react'
import Icon from '../Icon'

/**
 * Star Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function StarIcon(props) {
  const path = 'M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'

  return (
    <Icon
      icon="star"
      {...props}
    >
      <g>
        <path d={path} />
      </g>
    </Icon>
  )
}
