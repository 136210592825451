import { Link } from 'react-router-dom'
import Button from './Button'


/**
 * Adds `<Button />` styling to a `<Link />`
 * @todo add documentation
 */
const ButtonLink = Button.withComponent(Link)

export default ButtonLink
