import { getEbookToc, getEbookPackage, getEbook, flattenEbookToc } from './index'

/**
 * Finds and merges a relevant manifest item with the given table of contents
 * entry.
 *
 * @param {object} [tableOfContentsEntry={}] - Single item from the table of contents.
 * @param {Array<Object>} [manifestItems=[]] - Array of items from the package manifest.
 *
 * @returns {object} Modified table of contents entry.
 */
function mergeManifestItems(tableOfContentsEntry = {}, manifestItems = []) {
  let i
  const updatedEntry = tableOfContentsEntry

  // Go through the manifest items until we find the one that matches the table of
  // contents entry, once (if) we find it, then we'll merge it into the table of
  // contents entry.
  for (i = 0; i < manifestItems.length; i += 1) {
    if (manifestItems[i].href === tableOfContentsEntry.src) {
      updatedEntry.documentId = manifestItems[i].id
      break
    }
  }

  // Now we'll do the same to any nested table of contents entries.
  if (Array.isArray(updatedEntry.nested)) {
    updatedEntry.nested = updatedEntry.nested.map((entry) =>
      mergeManifestItems(entry, manifestItems),
    )
  }

  return updatedEntry
}

/**
 * Orders a list of manifest items by the pack TOC.
 *
 * @param {Array<object>} original - Array of manifest items.
 * (This should be filtered out by only xhtml documents.)
 *
 * @param {Array<object>} ordered - Array of items from the package spine TOC.
 *
 * @returns {Array<object>} Sorted array of manifest items by TOC.
 */
const sortByToc = (original, ordered) => {
  const sortedArray = original.sort((a, b) => {
    const aIndex = ordered.findIndex((item) => item.idref === a.id)
    const bIndex = ordered.findIndex((item) => item.idref === b.id)

    return aIndex - bIndex
  })

  return sortedArray
}

/**
 * Retrieves all of the relevant ebook information. This function combines the
 * `getEbook`, `getEbookToc`, and `getEbookPackage` API calls into a single request.
 * Once all the results are returned then they are cleaned and formatted in a way
 * that is more useful/helpful than making each request individually.
 *
 * @example
 * getAllEbookInfo({ id, sku })
 *   .then(ebookInfo => doStuff(ebookInfo))
 *
 * @param {object} params - ebook toc request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - eBook sku
 * @returns {Promise<Array<object>, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getAllEbookInfo(params = {}) {
  return Promise.all([getEbook(params), getEbookToc(params), getEbookPackage(params)]).then(
    (results) => {
      const [book, tableOfContents, pack] = results
      const manifestItems = pack.manifest.item

      const merged = tableOfContents.map((entry) => mergeManifestItems(entry, manifestItems))
      const flattened = flattenEbookToc(merged)

      // Filter out any items that are not xhtml documents.
      const filtered = manifestItems.filter((item) => item.mediaType === 'application/xhtml+xml')

      // Sort items by the order of the TOC
      const sorted = sortByToc(filtered, pack.spine.itemref)

      return {
        ...book,
        package: pack,
        manifest: {
          // Provide different ways to look up items in the manifest.
          items: {
            original: manifestItems,
            pages: sorted,
            byDocumentId: manifestItems.reduce(
              (grouped, item) => ({
                ...grouped,
                [item.id]: item,
              }),
              {},
            ),
            bySrc: manifestItems.reduce(
              (grouped, item) => ({
                ...grouped,
                [item.href]: item,
              }),
              {},
            ),
          },
        },
        tableOfContents: {
          // Provide different ways to look things up in the table of contents.
          original: merged,
          pages: sorted,
          linear: flattened,
          byDocumentId: Object.keys(flattened).reduce(
            (grouped, key) => ({
              ...grouped,
              [flattened[key].documentId]: flattened[key],
            }),
            {},
          ),
          bySrc: Object.keys(flattened).reduce(
            (grouped, key) => ({
              ...grouped,
              [flattened[key].src]: flattened[key],
            }),
            {},
          ),
        },
      }
    },
  )
}
