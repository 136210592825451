import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/layout/footer/
 * @todo add documentation
 */
const Footer = glamorous.footer('footer', propsToHelperClasses)

export default Footer
