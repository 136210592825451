import { getLibrary as sdkGetLibrary } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Retrieves a list of all media accessible by the logged in user.
 *
 * @example
 * getLibrary()
 *   .then(items => console.log(items))
 *
 * @see https://deseretbookwiki.atlassian.net/wiki/spaces/DB/pages/33030/Deseret+Bookshelf+API+Reference#DeseretBookshelfAPIReference-Getmediaownedbytheuser
 *
 * @param {object} [params={}] - Request params
 * @returns {Promise<Array<object>, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getLibrary(params = {}) {
  return sdkGetLibrary(params, requestOptions())
}
