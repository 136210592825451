import React from 'react'
import Icon from '../Icon'

/**
 * Highlight Style Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function HighlightStyleIcon(props) {
  return (
    <Icon
      icon="highlight-style"
      {...props}
    >
      <g>
        <path d="M9.62,12L12,5.67L14.37,12M11,3L5.5,17H7.75L8.87,14H15.12L16.25,17H18.5L13,3H11Z" />
      </g>
    </Icon>
  )
}
