import { USER_STORAGE_KEY } from '../constants'


/**
 * Returns the current user object, or an empty object if not logged in.
 *
 * @returns {object} user
 */
export default function getUser() {
  return JSON.parse(localStorage.getItem(USER_STORAGE_KEY)) || {}
}
