import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Divider } from '@deseretbook/react-ui'
import MediaCardFull from '../mediaCard/MediaCardFull'
import './MediaList.css'
import { BOOKSHELF_PLUS_IDS } from '../../../subscriptionIds'

/**
 * Component for displaying multiple `MediaCard`s in a grid.
 *
 * TODO Write tests for this
 * TODO Add story for this
 * @param {object} props - component props
 * @returns {function} Component
 */
function MediaGrid(props) {
  const { className, media, mediaType, ...rest } = props
  const mediaList = classNames('MediaList', className)
  const mediaListItem = classNames('MediaListItem', className)
  const plusIds = BOOKSHELF_PLUS_IDS

  /** TODO render an empty state. */
  if (!media.length) {
    return false
  }

  return (
    <div className={mediaList}>
      {media.map((mediaItem) => {
        const accessor = 'audiobook' in mediaItem ? 'audiobook' : 'ebook'
        const badgeOptions = {
          isAudiobook: !!mediaItem.audiobook,
          isFavorite: !!(mediaItem[accessor] && mediaItem[accessor].favorite),
          isPlus: !!(
            mediaItem[accessor] &&
            Array.isArray(mediaItem[accessor].subscriptionPlanIds) &&
            mediaItem[accessor].subscriptionPlanIds.find((val) => plusIds.indexOf(val) > -1)
          ),
        }

        return (
          <div className={mediaListItem} key={mediaItem.mediaId} {...rest}>
            <MediaCardFull media={mediaItem} mediaType={mediaType} {...badgeOptions} />
            <Divider />
          </div>
        )
      })}
    </div>
  )
}

MediaGrid.defaultProps = {
  className: '',
  media: [],
  mediaType: 'ebook',
}

MediaGrid.propTypes = {
  /** Additional classes */
  className: PropTypes.string,
  /** Media objects to display as `MediaCard`s */
  media: PropTypes.arrayOf([]),
  /** Type of media to display, ebook or audiobook */
  mediaType: PropTypes.string,
}

export default MediaGrid
