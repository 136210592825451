import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Sidebar from './Sidebar'


/**
 * The `<SimpleSidebar />` component provides basic state management for the
 * `<Sidebar />` component. In most cases this should be the go-to component
 * for adding a sidebar to your app.
 *
 * According to W3, this component most closely resembles a dialog/modal, and as
 * such, if the sidebar is set to closeable, then an escape key listener is added
 * so the sidebar will be closed if the user presses the escape key.
 * @see https://www.w3.org/TR/wai-aria-practices/#dialog_modal
 *
 * While basic state is managed in this component, you can provide more advanced
 * management by using the provided props. An example of a more advanced case would
 * be by automatically opening/closing the sidebar based on the `matchMedia()` api.
 *
 * Providing the `contextSelector` is experimental, but the general idea is being able
 * to add the sidebar into a subset of the the page, this hasn't really been tested
 * though and you'll probably need to change the position of the`.Sidebar` and
 * `.SidebarOverlay` to `absolute`, and then set the context's position to `relative`.
 *
 * @todo toggle the context's `overflow` style when active so the screen can't be
 * scrolled while the sidebar is open. Perhaps make this a toggle-able prop, or
 * only perform this if there is an overlay.
 */
class SimpleSidebar extends Component {
  /**
   * Component constructor
   *
   * @param {object} props - initial component props
   */
  constructor(props) {
    super(props)

    this.state = {
      isOpen: props.isOpen,
    }

    this.setupSidebar = this.setupSidebar.bind(this)
    this.closeSidebar = this.closeSidebar.bind(this)
    this.openSidebar = this.openSidebar.bind(this)
    this.escapeKeyPress = this.escapeKeyPress.bind(this)
  }

  /**
   * Initializes component when first rendered.
   */
  componentDidMount() {
    this.setupSidebar()

    if (this.state.isOpen) {
      this.openSidebar()
    }
  }

  /**
   * Determines sidebar state by evaluating new prop changes.
   *
   * @param {object} nextProps - Next props the component will receive
   */
  componentWillReceiveProps(nextProps) {
    // A change in the `isOpen` prop overrides the state's `isOpen`
    if (nextProps.isOpen !== this.props.isOpen && nextProps.isOpen !== this.state.isOpen) {
      if (nextProps.isOpen) {
        this.openSidebar()
      } else {
        this.closeSidebar()
      }
    }
  }

  /**
   * Handles tear-down of listeners before the component goes bye-bye.
   */
  componentWillUnmount() {
    // Remove the escape key listener.
    window.removeEventListener('keydown', this.escapeKeyPress)
  }

  /**
   * Stores the context object for access later.
   */
  setupSidebar() {
    const { contextSelector } = this.props

    this.context = document.querySelector(contextSelector)
  }

  /**
   * Sets the sidebar to closed. If the `isCloseable` prop is set to false
   * then this method just returns.
   */
  closeSidebar() {
    const { onClose, isCloseable } = this.props

    if (!isCloseable) {
      return
    }

    // Remove the escape key listener.
    window.removeEventListener('keydown', this.escapeKeyPress)
    this.setState({ isOpen: false })

    // Call the callback.
    if (onClose) {
      onClose()
    }
  }

  /**
   * Sets the sidebar to open.
   */
  openSidebar() {
    // Add the escape key listener so users can close the sidebar with the escape key.
    window.addEventListener('keydown', this.escapeKeyPress, false)
    this.setState({ isOpen: true })

    // Call the callback.
    if (this.props.onOpen) {
      this.props.onOpen()
    }
  }

  /**
  * Keypress Event Handler. Checks to see if the Esc key was pressed. Once the
  * Esc key is pressed, the sidebar should be closed.
  *
  * @param {event} e - key press event
  */
  escapeKeyPress(e) {
    if (e.keyCode === 27) {
      this.closeSidebar()
    }
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const {
      isOpen,
      hasOverlay,
      contextSelector,
      onOpen,
      onClose,
      children,
      ...rest
    } = this.props

    return (
      <Sidebar isOpen={this.state.isOpen} {...rest}>
        {children}
        {hasOverlay
          ? <Sidebar.Overlay onClick={this.closeSidebar} />
          : false}
      </Sidebar>
    )
  }
}


// Expose the `<Sidebar.Trigger />` and `<Sidebar.Content />` components.
SimpleSidebar.Trigger = Sidebar.Trigger
SimpleSidebar.Content = Sidebar.Content

SimpleSidebar.defaultProps = {
  contextSelector: 'body',
  children: false,
  isOpen: false,
  hasOverlay: true,
  isCloseable: true,
  onOpen: () => {},
  onClose: () => {},
}

SimpleSidebar.propTypes = {
  /** Experimental. css selector for the context of the sidebar. */
  contextSelector: PropTypes.string,
  /** Supports `<SimpleSidebar.Trigger />` and `<SimpleSidebar.Content` components. */
  children: PropTypes.node,
  /** If the sidebar should be open. If set after mounting, this will override the current state. */
  isOpen: PropTypes.bool,
  /** If an overlay should be added. */
  hasOverlay: PropTypes.bool,
  /** If basic sidebar closing mechanisms should be added */
  isCloseable: PropTypes.bool,
  /** Callback for after the sidebar is opened. */
  onOpen: PropTypes.func,
  /** Callback for after the sidebar is closed. */
  onClose: PropTypes.func,
}

export default SimpleSidebar
