import { saveAnnotation as sdk } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'
import { formatDate } from '../../utils/base'
import { formatAnnotations } from '../../utils/media'


/**
 * Deletes an existing annotation.
 *
 * @example <caption>deleting an annotation</caption>
 * deleteAnnotation({ id, bookId, documentId, ..rest })
 *   .then(annotations => console.log(annotations))
 *
 * @param {object} params - annotation parameters
 * @param {string} params.id - annotation id
 * @param {string} params.bookId - book id
 * @param {string} params.documentId - document id
 * @param {string} params.startBlr - string in BLR format
 * @param {string} [params.endBlr] - string in BLR format
 * @param {string} params.created - original timestamp of when annotation was created
 * @param {object} [params.bookmark] - bookmark
 * @param {string} params.bookmark.name - title of bookmark
 * @param {object} [params.highlight] - highlight
 * @param {string} params.highlight.color - highlight 32bit color
 * @param {number} params.highlight.style - style of highlight
 * @param {string} params.highlight.content - text of book being highlighted
 * @param {object} [params.note] - note. Either `title` or `text` is required.
 * @param {string} [params.note.title] - note's title
 * @param {string} [params.note.text] - note's text content
 * @param {Array<string>}  [params.tagIds] - tagIds array
 * @param {object} [params.link] - link to another point in the book
 * @param {string} params.link.toBlr - BLR to another position
 * @param {object} [options={}] - additional request options
 * @returns {Promise} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function deleteAnnotation(params = {}) {
  // Set the `deleted` field as it indicates this annotation
  // should be a delete and not a create.
  const deleted = formatDate(new Date())

  return sdk({ ...params, deleted }, requestOptions())
    .then((data) => {
      const formatted = formatAnnotations(data)

      return {
        annotations: formatted[params.bookId] || {},
        updatedAnnotation: data.updatedAnnotation,
      }
    })
}
