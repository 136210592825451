export const ANNOTATION_FULL_STYLE = 1
export const ANNOTATION_UNDERLINE_STYLE = 2

// View options for media content
export const VIEW_OPTIONS = {
  grid: 'grid',
  list: 'list',
}

// Sorting options for media content
export const SORT_OPTIONS = {
  recentlyAdded: 'Recently Added',
  // recentlyRead: 'Recently Read',
  title: 'Title (A-Z)',
  titleZ: 'Title (Z-A)',
  author: 'Author (A-Z)',
  authorZ: 'Author (Z-A)',
  // publicationDate: 'Publication Date',
}

// Tab options for media content
export const TABS = {
  ebook: 'ebook',
  audiobook: 'audiobook',
}
