import React from 'react'
import glamorous from 'glamorous'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { propsToHelperClasses } from '../helpers'
import { IconWrapper } from '../icons'


const TabsWrapper = glamorous.div('tabs', propsToHelperClasses)

/**
 * Bulma Tabs component.
 * @see https://bulma.io/documentation/components/tabs/
 * @todo add documentation
 * @returns {function} Component
 */
const Tabs = ({ children, ...rest }) => (
  <TabsWrapper {...rest}>
    <ul>{children}</ul>
  </TabsWrapper>
)

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
}

Tabs.Tab = glamorous.li(propsToHelperClasses)
Tabs.Label = glamorous.a(propsToHelperClasses)
Tabs.Link = Tabs.Label.withComponent(Link)
Tabs.Title = glamorous.span(propsToHelperClasses)
Tabs.Icon = ({ children }) => <span><IconWrapper>{children}</IconWrapper></span>

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
}
Tabs.Icon.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Tabs
