import React from 'react'
import glamorous from 'glamorous'
import { NavLink } from 'react-router-dom'
import { propsToHelperClasses } from '../helpers'

import './Navbar.css'

/**
 * @see https://bulma.io/documentation/components/navbar/
 * @todo add documentation
 */
const Navbar = glamorous.nav('navbar', propsToHelperClasses)

Navbar.Brand = glamorous.div('navbar-brand', propsToHelperClasses)

const Burger = glamorous.a('navbar-burger', propsToHelperClasses)

Navbar.Burger = (props) => (
  <Burger role="button" tabIndex="0" {...props}>
    <span />
    <span />
    <span />
  </Burger>
)

Navbar.Divider = glamorous.hr('navbar-divider', propsToHelperClasses)
Navbar.Menu = glamorous.div('navbar-menu', propsToHelperClasses)
Navbar.End = glamorous.div('navbar-end', propsToHelperClasses)
Navbar.Center = glamorous.div('navbar-center', propsToHelperClasses)
Navbar.Start = glamorous.div('navbar-start', propsToHelperClasses)

const NavbarItem = glamorous.a('navbar-item', propsToHelperClasses)

// Use `<Navbar.Item />` for interactive buttons or external links
Navbar.Item = (props) => <NavbarItem role="button" tabIndex="0" {...props} />
// Use `<Navbar.StaticItem />` for displaying static text/content
Navbar.StaticItem = NavbarItem.withComponent('div')
// Use `<Navbar.Link />` for an internal (relative) link or a dropdown container
Navbar.Link = NavbarItem.withComponent(NavLink).withProps({ activeClassName: 'is-active' })
Navbar.OutsideLink = NavbarItem.withComponent('a').withProps({ activeClassName: 'is-active' })
Navbar.Dropdown = Navbar.StaticItem.withProps({ hasDropdown: true })
Navbar.Dropdown.Menu = glamorous.div('navbar-dropdown', propsToHelperClasses)
Navbar.Dropdown.Label = glamorous.div('navbar-link', propsToHelperClasses)
Navbar.Dropdown.LabelLink = Navbar.Dropdown.Label.withComponent(NavLink)

export default Navbar
