import { withRouter } from 'react-router-dom'
import { logout } from '../../api/users'

/**
 * Simple container that logs out the current user and then redirects to the
 * homepage.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function LogoutPage(props) {
  const { history } = props

  logout()
    .then(() => {
      if (history) {
        history.push('/')
      }
    })

  return false
}

export default withRouter(LogoutPage)
