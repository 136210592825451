import * as React from 'react'
import propTypes from 'prop-types'
import { Box, Drawer, Toolbar, CMSContent } from '@deseretbook/react-ui'
import ChapterListing from './ChapterListing'
import SharePallette from './SharePallette'

/**
 * Popup audiobook player.
 */
export default class PopupPlayer extends React.Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      isChaptersModalVisible: false,
      isShareModalVisible: false,
      isMediaInfoVisible: false,
    }

    this.modalToggle = this.modalToggle.bind(this)
    this.renderChapterListings = this.renderChapterListings.bind(this)
  }

  /**
   * Toggles modal visibility.
   *
   * @param {string} modalStateName - Name of state
   */
  modalToggle(modalStateName) {
    this.setState(
      (prevState) => ({
        [modalStateName]: !prevState[modalStateName],
      }),
      () => {
        // Grabs and removes the extra close button from the drawer
        const drawerCloseButton = document.querySelector('button[aria-label="Close Drawer"]')

        if (drawerCloseButton) {
          drawerCloseButton.style.display = 'none'
        }
      },
    )
  }

  /**
   * How to render list of chapters.
   *
   * @param {function} [close=()=>{}] - Callback for when the chapter is clicked
   * @returns {function} Component
   */
  renderChapterListings(close = () => null) {
    const { currentChapter, chapters, onSelectChapter } = this.props

    /**
     * onClick handler.
     * @param {object} chapter - clicked chapter
     */
    const handleChapterSelect = (chapter) => {
      onSelectChapter(chapter)
      close()
    }

    return (
      <ChapterListing
        chapters={chapters}
        onSelectChapter={handleChapterSelect}
        currentChapter={currentChapter}
      />
    )
  }

  /**
   * Figures out how to render the component.
   * @returns {function} Component
   */
  render() {
    const { children, mediaInfo, currentChapter } = this.props
    const { isMediaInfoVisible, isShareModalVisible, isChaptersModalVisible } = this.state
    const toggleMediaInfo = 'isMediaInfoVisible'
    const toggleChapterName = 'isChaptersModalVisible'
    const toggleShareName = 'isShareModalVisible'
    const toggleInfoName = 'isMediaInfoVisible'

    return (
      <Box isColumn height="97vh">
        <Toolbar
          titleContent={mediaInfo.title}
          subtitleContent={currentChapter.name}
          leftActionIcon="menu"
          onLeftAction={() => this.modalToggle(toggleChapterName)}
        >
          <Toolbar.Action
            aria-label="Media Info"
            icon="info"
            onClick={() => this.modalToggle(toggleInfoName)}
          />
          <Toolbar.Action
            aria-label="Share"
            icon="share"
            onClick={() => this.modalToggle(toggleShareName)}
          />
        </Toolbar>
        <Box
          flex={1}
          justifyContent="center"
          alignItems="center"
          isColumn
          style={{ position: 'relative' }}
        >
          <img
            src={mediaInfo.cover && mediaInfo.cover.fullUrl}
            alt={mediaInfo.title}
            style={{
              width: 'auto',
              height: '100%',
              alignSelf: 'center',
            }}
          />
          <Drawer isOpen={isMediaInfoVisible} onClose={() => this.modalToggle(toggleMediaInfo)}>
            <Toolbar
              titleContent="Audiobook Info"
              leftActionIcon="close"
              onLeftAction={() => this.modalToggle(toggleMediaInfo)}
            />
            <Drawer.Content>
              <Box padding={0.5}>
                <CMSContent
                  dangerouslySetInnerHTML={{ __html: mediaInfo.description }}
                  itsNotDangerousBecause="mediaInfo.description comes directly from the Bookshelf API, and should be trustworthy"
                />
              </Box>
            </Drawer.Content>
          </Drawer>
        </Box>
        {children}
        <Drawer
          position="left"
          isOpen={isChaptersModalVisible}
          onClose={() => this.modalToggle(toggleChapterName)}
        >
          <Toolbar
            titleContent="Table of Contents"
            leftActionIcon="close"
            onLeftAction={() => this.modalToggle(toggleChapterName)}
          />
          <Drawer.Content>
            {this.renderChapterListings(() => this.modalToggle(toggleChapterName))}
          </Drawer.Content>
        </Drawer>
        <SharePallette
          isOpen={isShareModalVisible}
          content={mediaInfo}
          onClick={() => this.modalToggle(toggleShareName)}
          onClose={() => this.modalToggle(toggleShareName)}
        />
      </Box>
    )
  }
}

PopupPlayer.propTypes = {
  children: propTypes.node.isRequired,
  mediaInfo: propTypes.shape({}),
  chapters: propTypes.arrayOf(propTypes.object),
  currentChapter: propTypes.shape({}),
  onSelectChapter: propTypes.func,
}

PopupPlayer.defaultProps = {
  mediaInfo: {},
  chapters: [],
  currentChapter: {},
  onSelectChapter: () => {},
}
