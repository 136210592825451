import 'react-app-polyfill/ie11' // For IE 11 support

import React from 'react'
import ReactDOM from 'react-dom'
import '@babel/polyfill'
import registerServiceWorker from './registerServiceWorker'
import TagManager from './TagManager'
import Routes from './Routes'

ReactDOM.render(React.createElement(Routes), document.getElementById('root'))
registerServiceWorker()

// Initialize Google Tag Manager
TagManager.register({ id: 'GTM-PPMWQW' })
