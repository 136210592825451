import { readEbookUrl, detailsUrl } from './routes'

/**
 * Gets the desired URL for a media object.
 * @param {object} media - Ebook or audiobook object
 * @returns {string} URL
 */
const getMediaUrl = (media) => {
  // Default to the ebook url and fallback to the media preview url.
  let url = ''

  if (media.ebook) {
    url = readEbookUrl(media.mediaId, media.ebook.sku)
  } else if (media.audiobook) {
    url = detailsUrl(media.mediaId, media.audiobook.sku)
  } else {
    url = detailsUrl(media.mediaId, media.ebook.sku)
  }

  return url
}

export default getMediaUrl
