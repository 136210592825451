import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/elements/content/
 * @todo add documentation
 */
const Content = glamorous.div('content', propsToHelperClasses)

export default Content
