import React from 'react'
import PropTypes from 'prop-types'

import Field from './Field'

/**
 * A convenient form field component that makes it easy to add a `Select` field
 * to a form.
 *
 * This component combines the `Field`, `Control`, `FormLabel`, and `Select`
 * components together to make sure they work together properly.
 *
 * @todo Add better support for stateful colors (success, error, warning, etc.)
 * @todo update documentation
 * @param {object} props - Component props
 * @returns {function} Component
 */
function SelectField(props) {
  const {
    id,
    children,
    labelContent,
    options,
    isSmall,
    isMedium,
    isLarge,
    helpContent,
    fieldProps,
    labelProps,
    controlProps,
    helpProps,
    ...rest
  } = props

  /**
   * Helper function to render options from the `options` prop.
   * @returns {function} Component
   */
  const renderOptions = () => {
    const isList = Array.isArray(options)

    return Object.keys(options)
      .map((option) => {
        const optionValue = options[option]
        const optionLabel = isList
          ? option
          : optionValue

        return <option key={optionValue} value={optionValue}>{optionLabel}</option>
      })
  }

  return (
    <Field {...fieldProps}>
      {labelContent ? (
        <Field.Label
          htmlFor={id}
          isSmall={isSmall}
          isMedium={isMedium}
          isLarge={isLarge}
          {...labelProps}
        >
          {labelContent}
        </Field.Label>
      ) : false}
      <Field.Control {...controlProps}>
        <Field.Select
          id={id}
          containerProps={{ isSmall, isMedium, isLarge }}
          {...rest}
        >
          {renderOptions()}
          {children}
        </Field.Select>
      </Field.Control>
      {helpContent
        ? <Field.Help {...helpProps}>{helpContent}</Field.Help>
        : false}
    </Field>
  )
}

SelectField.defaultProps = {
  children: false,
  options: [],
  labelContent: false,
  helpContent: false,
  isSmall: false,
  isMedium: false,
  isLarge: false,
  fieldProps: {},
  labelProps: {},
  controlProps: {},
  helpProps: {},
}

SelectField.propTypes = {
  /** Select options */
  children: PropTypes.node,
  /** Input id */
  id: PropTypes.string.isRequired,
  /** List of select options to render. */
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.shape({}),
  ]),
  /** Field size */
  isSmall: PropTypes.bool,
  isMedium: PropTypes.bool,
  isLarge: PropTypes.bool,
  /** Label display value */
  labelContent: PropTypes.node,
  /** Help display value */
  helpContent: PropTypes.node,
  /** Additional props for `Field` component */
  fieldProps: PropTypes.shape({}),
  /** Additional props for `Label` component */
  labelProps: PropTypes.shape({}),
  /** Additional props for `Control` component */
  controlProps: PropTypes.shape({}),
  /** Additional props for `HelpText` component */
  helpProps: PropTypes.shape({}),
}

export default SelectField
