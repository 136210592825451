import React from 'react'
import Icon from '../Icon'

/**
 * Check Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function CheckIcon(props) {
  return (
    <Icon
      icon="check"
      {...props}
    >
      <g>
        <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
      </g>
    </Icon>
  )
}
