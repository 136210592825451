import React, { Component } from 'react'
import PageLayout from '../layouts/DefaultLayout'
import { getLibrary } from '../api/media'
import { FilteredMediaGrid } from '../components/media'
import LoadingIndicator from '../components/LoadingIndicator'
import ErrorComponent from '../components/ErrorComponent'
import { SORT_OPTIONS } from '../components/media/constants'

const defaultMediaSort = localStorage.getItem('mediaSort') || SORT_OPTIONS.recentlyAdded

/**
 * Default state
 * @returns {object} default state
 */
const cleanLibraryState = () => ({
  media: [],
  sortedMedia: [],
  loading: true,
  error: null,
  sortMethod: defaultMediaSort,
})

const FIRST_PAGE_SIZE = 64

/**
 * Library Page.
 */
class LibraryPage extends Component {
  /**
   * Component constructor
   */
  constructor() {
    super()

    this.retryInterval = null
    this.retryCount = 0

    this.state = cleanLibraryState()

    this.handleReload = this.handleReload.bind(this)
    this.updateSortMethod = this.updateSortMethod.bind(this)
  }

  /**
   * Fetches data and does initialization once component has been mounted to the DOM.
   */
  componentDidMount() {
    /**
     * Make two calls here to avoid slow loading of large libraries.
     * Initial call will load the first 64 books then all books will
     * be loaded after success.
     */
    getLibrary({
      page: 0,
      perPage: FIRST_PAGE_SIZE,
      orderBy: 'added', // Can be: added, title, author, favorite
      orderDirection: 'desc', // Can be: asc, desc
    })
      .then((media) => {
        const totalCount = media && media.pagination && media.pagination.total

        // Set media state if there are less than 64 results
        if (!totalCount || (typeof totalCount === 'number' && totalCount < FIRST_PAGE_SIZE)) {
          this.setState({ media, loading: false })

          if (defaultMediaSort !== SORT_OPTIONS.recentlyAdded) {
            this.updateSortMethod(defaultMediaSort)
          }

          return
        }

        // Grab the rest of the users library
        getLibrary({
          orderBy: 'added',
          orderDirection: 'desc',
          perPage: totalCount,
        }).then((mediaRest) => {
          this.setState({ media: mediaRest, loading: false })

          if (defaultMediaSort !== SORT_OPTIONS.recentlyAdded) {
            this.updateSortMethod(defaultMediaSort)
          }
        })
      })
      .catch((error) => {
        this.setState({
          error,
          loading: false,
        })
      })
  }

  /**
   * A generic function to sort media based on a given key and optional subkey.
   *
   * @param {string} key - The main key to sort by.
   * @param {string|null} subkey - An optional subkey to sort by.
   * @param {boolean} isReversed - Whether the sorting should be in reverse order.
   */
  sortBy(key, subkey = null, isReversed = false) {
    const { media } = this.state
    const mediaList = [...media.results]

    const sortedMedia = mediaList.sort((a, b) => {
      let valA = a[key]
      let valB = b[key]

      // If a subkey is provided, use it for sorting
      if (subkey && key === 'authors') {
        // eslint-disable-next-line
        valA = Array.isArray(valA) && valA.length ? valA[0][subkey] : ''
        // eslint-disable-next-line
        valB = Array.isArray(valB) && valB.length ? valB[0][subkey] : ''
      }

      // Convert to uppercase for case-insensitive comparison
      valA = (valA || '').toString().toUpperCase()
      valB = (valB || '').toString().toUpperCase()

      if (valA < valB) {
        // eslint-disable-next-line
        return isReversed ? 1 : -1
      }

      if (valA > valB) {
        // eslint-disable-next-line
        return isReversed ? -1 : 1
      }

      return 0
    })

    this.setState({ sortedMedia })
  }

  /**
   * Sorts media by title
   * @param {boolean} isReversed - Whether the sorting should be in reverse order.
   */
  sortByTitle(isReversed = false) {
    this.sortBy('title', null, isReversed)
  }

  /**
   * Sorts media by the first author's last name
   * @param {boolean} isReversed - Whether the sorting should be in reverse order.
   */
  sortByAuthor(isReversed = false) {
    this.sortBy('authors', 'lastName', isReversed)
  }

  /**
   * Sorts media by recently added to library. This is the default search
   */
  sortByAdded() {
    this.setState({ sortedMedia: this.state.media.results })
  }

  /**
   * Updates library sorting methods. The user library search does not have
   * the ability to search by recently read, or by publication date.
   * Call another method to manually sort this way until the API is updated.
   *
   * @param {string} sortMethod - Method matching the SORT_OPTIONS constant.
   */
  updateSortMethod(sortMethod) {
    localStorage.setItem('mediaSort', sortMethod)

    if (sortMethod === SORT_OPTIONS.title) {
      this.sortByTitle()
    } else if (sortMethod === SORT_OPTIONS.titleZ) {
      this.sortByTitle(true)
    } else if (sortMethod === SORT_OPTIONS.author) {
      this.sortByAuthor()
    } else if (sortMethod === SORT_OPTIONS.authorZ) {
      this.sortByAuthor(true)
    } else if (sortMethod === SORT_OPTIONS.recentlyAdded) {
      this.sortByAdded()
    }

    this.setState({ sortMethod })
  }

  /**
   * Simulates page reload.
   */
  handleReload() {
    this.setState(cleanLibraryState(), this.componentDidMount)
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const { media, loading, error, sortMethod, sortedMedia } = this.state

    if (loading) {
      return <LoadingIndicator />
    }

    if (error) {
      return (
        <PageLayout>
          <ErrorComponent error={error} onReload={this.handleReload} />
        </PageLayout>
      )
    }

    // eslint-disable-next-line
    const mediaResults = sortedMedia.length ? sortedMedia : media.results

    return (
      <PageLayout className="LibraryPage" isMarginless={loading}>
        <p className="title">Library</p>
        <FilteredMediaGrid
          media={mediaResults}
          updateSortMethod={this.updateSortMethod}
          sortMethod={sortMethod}
          hasPagination
          pagination={media.pagination}
        />
      </PageLayout>
    )
  }
}

export default LibraryPage
