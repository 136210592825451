import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/layout/hero/
 * @todo add documentation
 */
const Hero = glamorous.div('hero', propsToHelperClasses)

Hero.Head = glamorous.header('hero-head', propsToHelperClasses)
Hero.Body = glamorous.div('hero-body', propsToHelperClasses)
Hero.Foot = glamorous.footer('hero-foot', propsToHelperClasses)

export default Hero
