import { getEbook as sdkGetEbook } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Retrieves information about a specific SKU. The item is not required to be in the users library.
 *
 * This is a wrapper for {@link getProduct} that automatically returns just the ebook data.
 *
 * @example
 * getEbook({ id, sku })
 *   .then(ebook => console.log(ebook))
 *
 * @see https://deseretbookwiki.atlassian.net/wiki/spaces/DB/pages/33030/Deseret+Bookshelf+API+Reference#DeseretBookshelfAPIReference-GetinformationaboutaspecificSKU
 *
 * @param {object} params - eBook request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - eBook sku
 * @returns {Promise<object, BookshelfSdkError>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function getEbook(params = {}) {
  return sdkGetEbook(params, requestOptions())
}
