import React from 'react'
import Icon from '../Icon'

/**
 * Trash Icon
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
export default function TrashIcon(props) {
  return (
    <Icon
      icon="trash"
      {...props}
    >
      <g>
        <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
      </g>
    </Icon>
  )
}
