import React from 'react'
import glamorous from 'glamorous'
import { Link } from 'react-router-dom'
import { propsToHelperClasses } from '../helpers'

/**
 * @see https://bulma.io/documentation/components/menu/
 * @todo this doesn't currently support nested menus.
 * @todo add documentation
 */

const MenuWrapper = glamorous.div('menu', propsToHelperClasses)

/**
 * Menu component
 * @param {object} props - menu props
 * @returns {function} Component
 */
const Menu = props => <MenuWrapper role="menu" {...props} />

Menu.Label = glamorous.p('menu-label', propsToHelperClasses)
Menu.List = glamorous.ul('menu-list', propsToHelperClasses)
Menu.ListItem = glamorous.li(propsToHelperClasses)

const ButtonWrapper = glamorous.a(propsToHelperClasses)

Menu.ListItem.Button = props => <ButtonWrapper role="button" tabIndex="0" {...props} />
Menu.ListItem.Link = glamorous(Link)(propsToHelperClasses)

export default Menu
