import glamorous from 'glamorous'
import { propsToHelperClasses } from '../helpers'
import Button from './Button'
import ButtonLink from './ButtonLink'


/**
 * @see https://bulma.io/documentation/elements/button/#list-of-buttons
 * @todo add documentation
 */
const Buttons = glamorous.div('buttons', propsToHelperClasses)

Buttons.Button = Button
Buttons.ButtonLink = ButtonLink

export default Buttons
