import React from 'react'
import PropTypes from 'prop-types'
import { Menu, Box, Button } from '../../../dbWebUI'

/**
 * Bookmark list component.
 *
 * @returns {function} Component
 */
function BookmarkList({
  bookmarks,
  tableOfContents,
  onBookmarkClick,
  onEmptyListClick,
  ...rest
}) {
  if (!bookmarks.length) {
    return (
      <Box isShadowless className="no-content">
        <p className="title is-5 has-text-centered">Looks like you don&apos;t have any bookmarks.</p>
        <div className="has-text-centered"><Button isPrimary isFullwidth onClick={onEmptyListClick}>Create Your First Bookmark</Button></div>
      </Box>
    )
  }

  return (
    <Menu hasVerticalScroll {...rest}>
      <Menu.List>
        {bookmarks.map(({ bookmark, start, documentId: bookmarkDocId }) => {
          const chapter = tableOfContents.byDocumentId[bookmarkDocId]

          return (
            <Menu.ListItem key={bookmark.id}>
              <Menu.ListItem.Button
                onClick={() => onBookmarkClick(start, bookmarkDocId)}
              >
                <p className="title is-6 has-ellipsis">{bookmark.name}</p>
                <p className="subtitle is-7 has-ellipsis">
                  {chapter && chapter.label
                    ? chapter.label
                    : ''}
                </p>
              </Menu.ListItem.Button>
            </Menu.ListItem>
          )
        })}
      </Menu.List>
    </Menu>
  )
}

BookmarkList.defaultProps = {
  onBookmarkClick: () => {},
  onEmptyListClick: () => {},
}

BookmarkList.propTypes = {
  bookmarks: PropTypes.arrayOf(PropTypes.object).isRequired,
  tableOfContents: PropTypes.shape({}).isRequired,
  onBookmarkClick: PropTypes.func,
  onEmptyListClick: PropTypes.func,
}

export default BookmarkList
