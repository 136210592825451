import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { throttle } from '../../../utils/base'

/**
 * Wrapper for the `<iframe />` element. This adds support for writing an html
 * string to be the content of the iframe. Also adds support for injected styles.
 *
 * This component is only really meant for use with the `content` prop, if the `src`
 * is going to be external, it's probably a better idea to just use a regular iframe.
 *
 * @todo write tests for this.
 * @todo add better documentation and a story for this.
 */
class Iframe extends Component {
  /**
   * Component constructor
   */
  constructor() {
    super()

    this.state = {
      height: 0,
    }

    this.setRef = this.setRef.bind(this)
    this.setup = this.setup.bind(this)
    this.watchHeightChanges = this.watchHeightChanges.bind(this)
    this.heightChangeListener = this.heightChangeListener.bind(this)
    this.throttledHeightListener = throttle(this.heightChangeListener.bind(this), 200)
  }

  /**
   * Initializes stuff when the component is first mounted.
   */
  componentDidMount() {
    if (this.props.content) {
      this.setup()
    }
  }

  /**
   * Resets iframe if the right stuff has changed.
   *
   * @param {object} prevProps - Previous component props
   */
  componentDidUpdate(prevProps) {
    const { content } = this.props

    // Only run the setup if the iframe's contents has changed.
    // @question is there ever a time we will want to set the content to `''`?
    // we don't currently support that.
    if (content && prevProps.content !== content) {
      this.setup()
    }
  }

  /**
   * Stores the iframe's ref and document elements to instance variables. If
   * an `innerRef` prop is set, then the iframe is passed there so the parent
   * can also have access to the iframe for whatever reason.
   *
   * @param {element} iframe - iframe element
   */
  setRef(iframe) {
    if (!iframe) {
      return
    }

    this.iframe = iframe
    this.iframeDocument = (this.iframe.contentWindow || this.iframe.contentDocument).document

    // Send the iframe up to the parent if needed.
    if (this.props.innerRef) {
      this.props.innerRef(iframe)
    }
  }

  /**
   * Writes content to the iframe and injects custom style sheets. Once the
   * iframe's contents have been updated, it calls the `onSetup` callback if
   * one is given.
   */
  setup() {
    const { content, onSetup } = this.props

    if (!this.iframeDocument) {
      return
    }

    // write the contents to the iframe
    this.iframeDocument.open()
    this.iframeDocument.write(content)
    this.iframeDocument.close()

    // add style container
    const styleContainer = this.iframeDocument.createElement('style')

    styleContainer.type = 'text/css'
    this.iframeDocument.head.appendChild(styleContainer)
    this.sheet = styleContainer.sheet
    this.updateStyles()

    this.watchHeightChanges()

    // trigger the callback prop if one is present
    onSetup(this.iframe, this.iframeDocument)
  }

  /**
   * We need to keep the iframe's height matching the width of its content so a
   * scroll bar isn't needed. So we add event listeners to all the different
   * ways we can think of the content of the Iframe changing height, and then
   * adjust as needed.
   */
  watchHeightChanges() {
    // Set the initial height.
    this.setState({ height: this.iframeDocument.body.clientHeight })

    // Watch any changes in static assets like images and css.
    const resources = this.iframeDocument.querySelectorAll('link, img')

    Array.from(resources).map((resource) => {
      resource.addEventListener('load', this.throttledHeightListener, false)

      return resource
    })

    // Watch resizing of the window.
    window.addEventListener('resize', this.throttledHeightListener, false)
  }

  /**
   * Callback for when a change in height might've been detected. Compares the
   * previous known height of the content to the current height, if it's different
   * then we update state so we know how to re-render the contents.
   */
  heightChangeListener() {
    if (!this.iframeDocument) {
      return
    }

    if (this.state.height !== this.iframeDocument.body.clientHeight) {
      this.setState({ height: this.iframeDocument.body.clientHeight })
    }
  }

  /**
   * Converts the `innerCSS` prop to css rules and then injects them into the
   * iframe.
   *
   * @todo remove existing rules from the stylesheet so this can support updating
   * styles dynamically.
   */
  updateStyles() {
    const { innerCSS } = this.props

    if (!this.sheet) {
      return
    }

    Object.keys(innerCSS).map((selector) => {
      const selectorStyles = Object.keys(innerCSS[selector]).reduce((styles, rule) => {
        // convert rules to kebab case.
        const ruleSelector = rule.replace(/([a-z])([A-Z])/g, (_, a, b) => `${a}-${b.toLowerCase()}`)

        return `${styles} ${ruleSelector}: ${innerCSS[selector][rule]};`
      }, '')

      this.sheet.insertRule(`${selector} { ${selectorStyles} }`)

      return selector
    })
  }

  /**
   * Figures out how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const { innerCSS, innerRef, onSetup, title, style, content, ...rest } = this.props
    const { height } = this.state

    const styles = {
      ...style,
      height: `${height}px`,
      width: '100%',
    }

    return <iframe ref={this.setRef} title={title} style={styles} {...rest} />
  }
}

Iframe.defaultProps = {
  content: '',
  innerCSS: {},
  innerRef: () => {},
  onSetup: () => {},
  style: {},
}

Iframe.propTypes = {
  /** html string of contents to display inside iframe */
  content: PropTypes.string,
  /** JSS styling to inject into the iframe's content */
  innerCSS: PropTypes.shape({}),
  /** function to access the `ref` of the iframe */
  innerRef: PropTypes.func,
  /** callback for when the iframe's content has been set */
  onSetup: PropTypes.func,
  /** Title of iframe. <iframe> elements must have a unique title property */
  title: PropTypes.string.isRequired,
  /** Element style prop. */
  style: PropTypes.shape({}),
}

export default Iframe
