import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Box, Columns, Navbar, IconWrapper, CheckIcon, ArrowIcon, HighlightStyleIcon, TrashIcon } from '../../../../dbWebUI'
import { HIGHLIGHT_STYLE_OPTIONS, HIGHLIGHT_COLOR_OPTIONS } from '../../../../utils/constants'


/**
 * `AnnotationActionMenu` view for displaying and managing an annotation's highlight.
 * This component handles editing, and deleting a highlight. It should
 * be used as a child of `AnnotationActionMenu`.
 */
class HighlightView extends Component {
  /**
   * Component constructor.
   */
  constructor() {
    super()

    this.state = {
      /** The value of the button being saved so we know how to display loading state. */
      activeButton: null,
    }

    this.onClickHandler = this.onClickHandler.bind(this)
    this.deleteHighlight = this.deleteHighlight.bind(this)
    this.renderColors = this.renderColors.bind(this)
    this.renderStyles = this.renderStyles.bind(this)
  }

  /**
   * `onClick` handler for buttons that change style and color of the highlight.
   *
   * @param {string} key - key of highlight value to be changed.
   * @param {string} value - changed value for highlight
   */
  onClickHandler(key, value) {
    const { highlight, onSave, isLoading } = this.props

    // Don't make the change if we're in limbo or if the new value is the current value.
    if (isLoading || highlight[key] === value) {
      return
    }

    this.setState({
      activeButton: value,
    }, () => onSave({ [key]: value }))
  }

  /**
   * Removes the highlight data from the annotation and then
   * moves back to the default view.
   */
  deleteHighlight() {
    const {
      onSave, isLoading, highlight, onBack,
    } = this.props
    const exists = !!(highlight.color || highlight.style)

    if (isLoading || !exists) {
      return
    }

    // Clear the active button so we don't show the loading icon in the wrong
    // place, then delete the highlight.
    this.setState({
      activeButton: null,
    }, () => onSave({}, true, onBack))
  }

  /**
   * Renders color picker options for editing the highlight.
   *
   * @returns {function} Component
   */
  renderColors() {
    const { highlight, isLoading } = this.props
    const { activeButton } = this.state

    return HIGHLIGHT_COLOR_OPTIONS
      .map((color) => {
        const isActive = color.value === highlight.color
        const showLoading = isLoading && color.value === activeButton

        return (
          <Columns.Column key={color.label} hasTextCentered>
            <Button
              isDark
              isMedium
              isCircular
              isStatic={isActive || showLoading}
              isLoading={showLoading}
              title={color.label}
              style={{ background: color.hex }}
              onClick={() => this.onClickHandler('color', color.value)}
            >
              <IconWrapper hasTextWhite>
                {!!isActive && <CheckIcon />}
              </IconWrapper>
            </Button>
          </Columns.Column>
        )
      })
  }

  /**
   * Renders style picker options for editing the highlight.
   *
   * @returns {function} Component
   */
  renderStyles() {
    const { highlight, isLoading } = this.props
    const { activeButton } = this.state

    // Figure out which color to use for the style buttons.
    const displayColor = HIGHLIGHT_COLOR_OPTIONS
      .reduce((active, color) => (
        // Pick either the first color, or the one that is selected.
        (!active || (highlight.color === color.value))
          ? color
          : active
      ))

    return HIGHLIGHT_STYLE_OPTIONS
      .map((style) => {
        const isActive = style.value === highlight.style
        const showLoading = isLoading && style.value === activeButton

        return (
          <Columns.Column key={style.label} hasTextCentered>
            <Button
              isWhite
              isMedium
              isStatic={isActive || showLoading}
              isLoading={showLoading}
              title={style.label}
              onClick={() => this.onClickHandler('style', style.value)}
            >
              <IconWrapper
                className={`style-${style.label.toLowerCase()}`}
                style={{ color: displayColor.hex }}
              >
                <HighlightStyleIcon className="has-text-dark" />
              </IconWrapper>
            </Button>
          </Columns.Column>
        )
      })
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const {
      highlight,
      onSave,
      onBack,
      className,
      isLoading,
      ...rest
    } = this.props
    const classes = classNames('HighlightView', className)
    const exists = !!(highlight.style && highlight.color)

    return (
      <div className={classes} {...rest}>
        <Navbar hasShadow isMobile>
          <Navbar.Menu>
            <Navbar.Item onClick={onBack} title="Back">
              <IconWrapper hasTextDark>
                <ArrowIcon isBack />
              </IconWrapper>
            </Navbar.Item>
            <Navbar.StaticItem>
              Highlight
            </Navbar.StaticItem>
          </Navbar.Menu>
          {/** Only display the delete button if there is something to delete. */}
          {exists ? (
            <Navbar.End>
              <Navbar.Item
                onClick={this.deleteHighlight}
                title="Remove Highlight"
              >
                <IconWrapper hasTextDark>
                  <TrashIcon />
                </IconWrapper>
              </Navbar.Item>
            </Navbar.End>
          ) : false}
        </Navbar>
        <Box isShadowless className="view-content">
          <p className="heading">Color</p>
          <Columns isMobile isMultiline isVariable is0 className="colors">
            {this.renderColors()}
          </Columns>
          <p className="heading">Style</p>
          <Columns isMobile isMultiline className="styles">
            {this.renderStyles()}
          </Columns>
        </Box>
      </div>
    )
  }
}

HighlightView.defaultProps = {
  isLoading: false,
  highlight: {},
  onSave: () => {},
  onBack: () => {},
  className: '',
}

HighlightView.propTypes = {
  /** True if an action is taking place. */
  isLoading: PropTypes.bool,
  /** Annotation highlight object. */
  highlight: PropTypes.shape({}),
  /** Callback for when a note should be saved. */
  onSave: PropTypes.func,
  /** Callback for when the view should be changed back to the previous view. */
  onBack: PropTypes.func,
  /** Additional class name. */
  className: PropTypes.string,
}

export default HighlightView
