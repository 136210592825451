import React from 'react'
import Icon from '../Icon'


/**
 * Deseret Bookshelf Headphones Logo.
 * @param {object} props - BookshelfLogo props
 *
 * @returns {function} Component
 */
function BookshelfLogo(props) {
  return (
    <Icon
      icon="bookshelf"
      title="Deseret Bookshelf"
      role="img"
      {...props}
    >
      <g transform="matrix(1,0,0,1,0,3.33193)">
        <path id="headphones" d="M20.111,12.85l-0.756,0l0,-2.913c0,-1.867 -0.83,-3.581 -2.34,-4.83c-1.361,-1.128 -3.191,-1.775 -5.016,-1.775c-1.825,0 -3.656,0.647 -5.019,1.775c-1.51,1.246 -2.34,2.963 -2.34,4.83l0,2.913l-0.756,0c-2.141,0 -3.884,1.743 -3.884,3.887c0,2.143 1.743,3.886 3.886,3.886l2.972,0l0,-10.686c0,-2.711 2.668,-4.39 5.141,-4.39c3.175,0 5.143,2.279 5.143,4.39l0,10.686l2.972,0c2.143,0 3.886,-1.743 3.886,-3.886c-0.003,-2.144 -1.746,-3.887 -3.889,-3.887Zm-15.471,5.555l-0.756,0c-0.921,0 -1.669,-0.748 -1.669,-1.668c0,-0.921 0.748,-1.669 1.669,-1.669l0.756,0l0,3.337Zm13.357,-8.468c0,-3.239 -3.115,-5.244 -5.998,-5.244c-2.884,0 -5.998,2.005 -5.998,5.244l-0.504,0c0,-3.552 3.374,-5.748 6.502,-5.748c3.127,0 6.502,2.196 6.502,5.748l-0.504,0Zm2.114,8.468l-0.756,0l0,-3.337l0.756,0c0.921,0 1.669,0.748 1.669,1.669c0,0.92 -0.748,1.668 -1.669,1.668Z" />
        <path id="book3" d="M16.503,20.344l-1.671,0.324l-2.112,-11.293l1.672,-0.326l2.111,11.295Z" />
        <rect id="book2" x="10.659" y="7.216" width="1.942" height="13.407" />
        <rect id="book1" x="8.29" y="9.131" width="1.289" height="11.489" />
      </g>
    </Icon>
  )
}


export default BookshelfLogo
