import React from 'react'
import PropTypes from 'prop-types'
import { Button, InputField, TextareaField } from '../../../../dbWebUI'


/**
 * Annotation note edit form. This should be used as a child of `NoteView` when
 * in edit mode.
 *
 * @param {object} props - component props
 * @returns {function} Component
 */
function NoteForm({
  onSubmit,
  onChange,
  note,
  isSubmitting,
  isValid,
  ...rest
}) {
  return (
    <form onSubmit={onSubmit} {...rest}>
      <InputField
        id="title"
        name="title"
        labelContent="Title"
        onChange={onChange}
        defaultValue={note.title}
        autoComplete="off"
        autoFocus
      />
      <TextareaField
        id="text"
        name="text"
        labelContent="Note"
        onChange={onChange}
        defaultValue={note.text}
        autoComplete="off"
        rows="3"
      />
      <Button
        type="submit"
        isPrimary
        isLoading={isSubmitting}
        disabled={!isValid}
      >
        Save Note
      </Button>
    </form>
  )
}

NoteForm.defaultProps = {
  onSubmit: () => {},
  onChange: () => {},
  note: {},
  isSubmitting: false,
  isValid: false,
}

NoteForm.propTypes = {
  /** On form submit handler. */
  onSubmit: PropTypes.func,
  /** On inpit field change handler. */
  onChange: PropTypes.func,
  /** Initial form data. */
  note: PropTypes.shape({}),
  /** True if the form is in the process of submitting. */
  isSubmitting: PropTypes.bool,
  /** True if the form is valid and able to be submitted. */
  isValid: PropTypes.bool,
}

export default NoteForm
