import moment from 'moment'
import uuidv4 from 'uuid/v4'
import {
  IconManager,
  coreIconPack,
  mediaControlsIconPack,
  iconGenerator,
  subscriptionsLogoPack,
} from '@deseretbook/react-ui'
import _throttle from 'lodash.throttle'
import _debounce from 'lodash.debounce'

/**
 * Formats a string/number of seconds into "HH:mm:ss" format while removing leading 0's.
 *
 * @example
 * formatDuration(124) // '2:04'
 * formatDuration(25000) // '6:56:40'
 *
 * @param {number} time - time in seconds
 * @returns {string} formatted duration string
 */
export function formatDuration(time) {
  return moment
    .utc(moment.duration(time, 'seconds').asMilliseconds())
    .format('HH:mm:ss')
    .replace(/^(00:)(0)?|^(0)/, '')
}

/**
 * Formats a date object to 'YYYY-MM-DD HH:mm:ss ZZ' in UTC.
 *
 * @example
 * formatCurrentDate(new Date()) // 2018-01-18 16:28:53 +0000
 *
 * @param {Date} date - the date we want to format.
 * @returns {string} formatted date
 */
export function formatDate(date = new Date()) {
  return moment(date)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss ZZ')
}

/**
 * Uses UUID package to generate a random uuid and prepends the uuid with the BWAPP namespace.
 *
 * @example
 * uuid()  // 'BWAPP-110ec58a-a0f2-4ac4-8393-c866d813b8d1'
 *
 * @see https://www.npmjs.com/package/uuid
 *
 * @returns {string} generated uuid
 */
export function uuid() {
  return `BWAPP-${uuidv4()}`
}

/**
 * Reduces an array of objects into an object of arrays where each object in
 * the child array has the same value for the given key.
 *
 * @param {array<object>} data - objects to be grouped.
 * @param {string} key - key name to group each object by.
 * @returns {object} object grouped by key value.
 */
export function groupByKey(data, key) {
  return data.reduce((groups, datum) => {
    const updatedGroups = groups

    if (datum[key] in updatedGroups) {
      updatedGroups[datum[key]].push(datum)
    } else {
      updatedGroups[datum[key]] = [datum]
    }

    return updatedGroups
  }, {})
}

/**
 * Creates a throttled function that only invokes func at most once per every wait
 * milliseconds. The throttled function comes with a cancel method to cancel delayed
 * func invocations and a flush method to immediately invoke them. Provide options
 * to indicate whether func should be invoked on the leading and/or trailing edge of
 * the wait timeout. The func is invoked with the last arguments provided to the
 * throttled function. Subsequent calls to the throttled function return the result
 * of the last func invocation.
 *
 * Note: If leading and trailing options are true, func is invoked on the trailing
 * edge of the timeout only if the throttled function is invoked more than once
 * during the wait timeout.
 *
 * If wait is 0 and leading is false, func invocation is deferred until to the
 * next tick, similar to setTimeout with a timeout of 0.
 *
 * @see https://lodash.com/docs/4.17.5#throttle
 *
 * @param {function} func - function to debounce
 * @param {number} [wait=0] - time to wait in milliseconds
 * @param {object} [options={}] - throttle options.
 * @returns {function} throttled function
 */
export function throttle(...props) {
  return _throttle(...props)
}

/**
 * Creates a debounced function that delays invoking func until after wait
 * milliseconds have elapsed since the last time the debounced function was
 * invoked. The debounced function comes with a cancel method to cancel delayed
 * func invocations and a flush method to immediately invoke them. Provide options
 * to indicate whether func should be invoked on the leading and/or trailing edge
 * of the wait timeout. The func is invoked with the last arguments provided to
 * the debounced function. Subsequent calls to the debounced function return the
 * result of the last func invocation.
 *
 * Note: If leading and trailing options are true, func is invoked on the
 * trailing edge of the timeout only if the debounced
 * function is invoked more than once during the wait timeout.
 *
 * If wait is 0 and leading is false, func invocation is deferred until
 * to the next tick, similar to setTimeout with a timeout of 0.
 *
 * @see https://lodash.com/docs/4.17.5#debounce
 *
 * @param {function} func - function to debounce
 * @param {number} [wait=0] - time to wait in milliseconds
 * @param {object} [options={}] - debounce options.
 * @returns {function} debounced function
 */
export function debounce(func, wait = 0, options = {}) {
  return _debounce(func, wait, options)
}

/**
 * Returns detailed info regarding the current viewport. Provides the current
 * width/height of the screen, the offset of the viewport from the tpo left of
 * the page, as well as x/y coordinates for all corners of the viewport.
 *
 * @returns {object} viewport data
 */
export function getViewportInfo() {
  // Grab useful data from the window.
  const { scrollY, scrollX, innerHeight: viewportHeight, innerWidth: viewportWidth } = window

  return {
    width: viewportWidth,
    height: viewportHeight,
    // offset from scrolling.
    offsetTop: scrollY,
    offsetLeft: scrollX,
    // Coordinates for top left of viewport.
    topLeftX: scrollX,
    topLeftY: scrollY,
    // Coordinates for top right of viewport.
    topRightX: scrollX + viewportWidth,
    topRightY: scrollY,
    // Coordinates for bottom left of viewport.
    bottomLeftX: scrollX,
    bottomLeftY: scrollY + viewportHeight,
    // Coordinates for bottom right of viewport.
    bottomRightX: scrollX + viewportWidth,
    bottomRightY: scrollY + viewportHeight,
  }
}

/**
 * Removes any keys with null or undefined values. Returns a new object.
 * @param {object} object - object to sanitize
 * @returns {object} - object with no falsey values
 */
export function removeFalseyKeys(object) {
  const newObj = Object.assign({}, object)

  Object.keys(newObj).forEach((k) => {
    if (!newObj[k]) {
      delete newObj[k]
    }
  })

  return newObj
}

/**
 * Converts a number of minutes to an equivalent number of millisecconds
 * @param {number} numberOfMinutes - number of minutes
 * @returns {number} number of milliseconds
 */
export function minutesToMilliseconds(numberOfMinutes = 0) {
  return numberOfMinutes * 1000 * 60
}

/**
 * Scrolls the document
 * @param {event} event - scroll event
 * @param {object} options - optional options for configuration
 * @param {boolean} [options.isDown = false] should the page scroll down or up
 * @param {number} options.offset the number of pixels to scroll
 * 106px is the height of the combined headers in the reader view.
 * Using this number ensures that the page is scrolled no more than one viewport.
 * the mediaquery against 1088px checks if the browser is mobile or not.
 * For screen sizes < 1088 px, the navbars disappear,
 * so the padding in the calculation is also removed
 */
export function handlePageScroll(event, { isDown, offset }) {
  event.preventDefault()

  if (isDown === undefined && offset === undefined) {
    return
  }
  let scrollDistance = offset || window.innerHeight

  if (!isDown && offset === undefined) {
    scrollDistance = Math.abs(scrollDistance) * -1
  }
  window.scrollTo({ top: window.scrollY + scrollDistance, behavior: 'smooth' })
}

/**
 * Returns seconds as formatted time mm:ss
 * @param {number} timeInSeconds time in seconds to format
 * @returns {string} formatted time string mm:ss
 */
export function formatSecondsToTimeString(timeInSeconds) {
  let sanitizedTime

  try {
    if (typeof timeInSeconds === 'string') {
      sanitizedTime = parseInt(timeInSeconds, 10)
    }

    if (typeof timeInSeconds === 'number') {
      sanitizedTime = timeInSeconds
    }
  } catch (e) {
    throw TypeError('timeInSeconds must be a number')
  }
  const hours = Math.floor(sanitizedTime / 60 / 60)
  const hourString = hours ? `${hours}:` : ''
  const minutes = Math.floor((sanitizedTime / 60) % 60)
  const minutesString = minutes ? `${hours ? minutes.toString().padStart(2, '0') : minutes}:` : '0:'
  const seconds = Math.floor(sanitizedTime % 60)
  const secondsString = seconds.toString().padStart(2, '0')

  return `${hourString}${minutesString}${secondsString}`
}

/**
 * Adds custom Icons
 *
 * @returns {object} icons
 */
export function addCustomIcons() {
  return {
    facebook: iconGenerator([
      'M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-1 2v3h-2c-.55 0-1 .45-1 1v2h3v3h-3v7h-3v-7h-2v-3h2v-2.5c0-1.93 1.57-3.5 3.5-3.5h2.5z',
    ]),
    pinterest: iconGenerator([
      'M20 2h-16c-1.1 0-1.99.9-1.99 2l-.01 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-16c0-1.1-.9-2-2-2zm-7 14.2c-.8 0-1.57-.34-2.12-.92l-.95 3.2-.07.21-.03-.01c-.19.32-.54.52-.93.52-.61 0-1.1-.49-1.1-1.1l.01-.15h-.01l.05-.18 1.85-5.56s-.2-.61-.2-1.47c0-1.72.92-2.23 1.66-2.23.74 0 1.42.27 1.42 1.31 0 1.34-.89 2.03-.89 3 0 .74.6 1.34 1.34 1.34 2.33 0 3.16-1.76 3.16-3.41 0-2.18-1.88-3.95-4.2-3.95-2.32 0-4.2 1.77-4.2 3.95 0 .67.19 1.34.54 1.94.09.16.14.33.14.51 0 .55-.45 1-1 1-.36 0-.69-.19-.87-.5-.53-.9-.82-1.92-.82-2.96.02-3.27 2.8-5.94 6.22-5.94s6.2 2.67 6.2 5.95c0 2.63-1.63 5.45-5.2 5.45z',
    ]),
    email: iconGenerator([
      'M20 4h-16c-1.1 0-1.99.9-1.99 2l-.01 12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-12c0-1.1-.9-2-2-2zm0 4l-8 5-8-5v-2l8 5 8-5v2z',
    ]),
  }
}

/**
 * Registers the default iconpacks, with any additional packs
 * @param  {Array<object>} iconPacks A list of additional icon packs
 */
export function registerIcons(...iconPacks) {
  try {
    IconManager.register(
      coreIconPack,
      mediaControlsIconPack,
      subscriptionsLogoPack,
      addCustomIcons(),
      ...iconPacks,
    )
  } catch (error) {
    throw TypeError(error)
  }
}
