import { reportUsage as sdkReportUsage } from '@deseretbook/media-sdk'
import { requestOptions } from '../base'


/**
 * Reports usage statistics for open media
 *
 * @example
 * reportUsage({id, sku, duration, opened })
 *   .then(success => console.log(success))
 *
 * @see http://docs-linked-from-confluence.s3.amazonaws.com/usage_api.swagger.html
 *
 * @param {object} params - user last position request parameters
 * @param {string} params.id - media id
 * @param {string} params.sku - product sku
 * @param {number} params.duration - duration in seconds on media
 * @param {number} params.opened - number of times the media has been opened
 * @param {boolean} params.subscribed - is the user subscribed to the media
 * @returns {Promise<object, Error>} Data from api
 * @throws {PropertyRequiredError} if required params are `null`
 */
export default function reportUsage(params = {}) {
  return sdkReportUsage(params, requestOptions())
}
