export default {
  name: 'bookshelf-web',
  description: 'Default Bookshelf Web Theme',
  palette: {
    primary: {
      main: '#00d1b2',
      mainText: '#ffffff',
    },
  },
}
