import React from 'react'
import { Button, Level, Heading } from '@deseretbook/react-ui'
import PropTypes from 'prop-types'
import { AUDIOBOOK_CONTROL_VIEWS } from './AudiobookControls'

const PLAYBACK_SPEEDS = [0.8, 0.9, 1, 1.25, 1.5, 1.75, 2]

/**
 * View for when changing playback rate.
 * @returns {function} Component
 */
export default function PlaybackRateView({ onChangeView, onChangeRate, rate }) {
  /**
   * Closes current view.
   * @returns {undefined} Nothing
   */
  const close = () => onChangeView(AUDIOBOOK_CONTROL_VIEWS.control)

  /**
   * Sets the new playback rate.
   * @param {number} speed - playback rate
   */
  const changeRate = (speed) => {
    onChangeRate(speed)
    close()
  }

  return (
    <React.Fragment>
      <Heading>Change Playback Rate</Heading>
      <Level>
        {PLAYBACK_SPEEDS.map(speed => (
          <Level.Item key={speed}>
            <Button
              type="button"
              isPrimary={rate === speed}
              onClick={() => changeRate(speed)}
              isFullwidth
            >
              {speed}
            </Button>
          </Level.Item>
        ))}
      </Level>
    </React.Fragment>
  )
}

PlaybackRateView.propTypes = {
  onChangeView: PropTypes.func,
  onChangeRate: PropTypes.func,
  rate: PropTypes.number,
}

PlaybackRateView.defaultProps = {
  onChangeRate: () => {},
  onChangeView: () => {},
  rate: 1,
}
