import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Box, Content, Navbar, IconWrapper, ArrowIcon, EditIcon, TrashIcon } from '../../../../dbWebUI'
import BookmarkForm from './BookmarkForm'


/**
 * `AnnotationActionMenu` view for displaying and managing an annotation's note.
 * This component handles viewing, editing, and deleting of a note. It should
 * be used as a child of `AnnotationActionMenu`.
 */
class BookmarkView extends Component {
  /**
   * Component constructor
   *
   * @param {object} props - initial component props
   */
  constructor(props) {
    super(props)

    this.state = {
      /** If we should be displaying the edit form or not. */
      isEditing: !props.bookmark.name,
      /** If the note is currently being saved. */
      isSaving: false,
      /** Editable note data. */
      bookmark: {
        name: props.defaultName,
        ...props.bookmark,
      },
    }

    this.onChangeHandler = this.onChangeHandler.bind(this)
    this.onSubmitHandler = this.onSubmitHandler.bind(this)
    this.toggleEdit = this.toggleEdit.bind(this)
    this.deleteBookmark = this.deleteBookmark.bind(this)
  }

  /**
   * `onChange` handler for `<NoteForm />` inputs. This method parses an input
   * change event and applies the changes to the editable note data stored in state.
   *
   * @param {Event} e - input change event
   */
  onChangeHandler(e) {
    const { isLoading } = this.props
    const { name, value } = e.target

    // Don't do anything if we're in limbo.
    if (isLoading) {
      return
    }

    // Store the changes.
    this.setState(prevState => ({
      bookmark: {
        ...prevState.bookmark,
        [name]: value,
      },
    }))
  }

  /**
   * `onSubmit` handler for `<NoteForm />`. This method validates the current
   * note data and then sends the changes to the server for saving.
   *
   * @param {Event} e - Form submit event
   */
  onSubmitHandler(e) {
    e.preventDefault()

    const { onSave, onBack, isLoading } = this.props
    const { bookmark } = this.state
    const isValid = bookmark.name

    if (isLoading || !isValid) {
      return
    }

    // Set a limbo state so we can indicate the form is being submitted, then
    // run the callback so we actually save the changes.
    this.setState({
      isSaving: true,
    }, () => onSave(bookmark, false, onBack))
  }

  /**
   * Toggles the display of the edit form. Also resets any changes to be the
   * initial note values.
   */
  toggleEdit() {
    this.setState((prevState, props) => ({
      isEditing: !prevState.isEditing,
      bookmark: props.bookmark,
    }))
  }

  /**
   * Deletes the current note from the annotation. If the note doesn't exist
   * or the action menu is in limbo, then it just returns early.
   */
  deleteBookmark() {
    const {
      onSave, isLoading, bookmark, onBack,
    } = this.props
    const exists = bookmark.id

    if (isLoading || !exists) {
      return
    }

    // Set a limbo state so we can indicate the note is being deleted, then
    // run the callback so we actually delete it.
    this.setState({
      isSaving: true,
    }, () => {
      onSave({}, true, onBack)
    })
  }

  /**
   * Determines how to render the component.
   *
   * @returns {function} Component
   */
  render() {
    const {
      bookmark: initialBookmark,
      onSave,
      onBack,
      className,
      isLoading,
      ...rest
    } = this.props
    const { isEditing, isSaving, bookmark } = this.state
    const hasContent = !!(initialBookmark.name)
    const canSave = !!(bookmark.name) && !isSaving

    const classes = classNames('BookmarkView', className)

    return (
      <div className={classes} {...rest}>
        <Navbar hasShadow isMobile>
          <Navbar.Menu>
            {/**
              If editing and the note already exists, the back button goes back to
              displaying the note, otherwise we want to back out to the main view.
            */}
            <Navbar.Item
              onClick={isEditing && hasContent
                ? this.toggleEdit
                : onBack}
              title="Back"
            >
              <IconWrapper hasTextDark>
                <ArrowIcon isBack />
              </IconWrapper>
            </Navbar.Item>
            <Navbar.StaticItem>
              Bookmark
            </Navbar.StaticItem>
          </Navbar.Menu>
          {/** Only display the edit/delete button if there is something to edit/delete. */}
          {hasContent ? (
            <Navbar.End>
              <Navbar.Item
                onClick={isEditing
                  ? this.deleteBookmark
                  : this.toggleEdit}
                title={isEditing
                  ? 'Remove Bookmark'
                  : 'Edit Bookmark'}
              >
                <IconWrapper hasTextDark>
                  {isEditing
                    ? <TrashIcon />
                    : <EditIcon />}
                </IconWrapper>
              </Navbar.Item>
            </Navbar.End>
          ) : false}
        </Navbar>
        <Box isShadowless className="view-content">
          {/** Display the edit form or just display the content. */}
          {isEditing ? (
            <BookmarkForm
              onSubmit={this.onSubmitHandler}
              onChange={this.onChangeHandler}
              bookmark={bookmark}
              isSubmitting={isSaving}
              isValid={!isSaving && canSave}
            />
          ) : (
            <Content>
              <strong>{bookmark.name}</strong>
            </Content>
          )}
        </Box>
      </div>
    )
  }
}

BookmarkView.defaultProps = {
  defaultName: '',
  isLoading: false,
  bookmark: {},
  onSave: () => {},
  onBack: () => {},
  className: '',
}

BookmarkView.propTypes = {
  /** Initial value for the `name` field. */
  defaultName: PropTypes.string,
  /** True if an action is taking place. */
  isLoading: PropTypes.bool,
  /** Annotation note object. */
  bookmark: PropTypes.shape({
    name: PropTypes.string,
  }),
  /** Callback for when a note should be saved. */
  onSave: PropTypes.func,
  /** Callback for when the view should be changed back to the previous view. */
  onBack: PropTypes.func,
  /** Additional class name. */
  className: PropTypes.string,
}

export default BookmarkView
