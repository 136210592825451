import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { BookshelfLogo } from '../dbWebUI/icons'

import './LoadingIndicator.css'


/**
 * Component that indicates something is loading/happening.
 *
 * @todo make the styles a little more generic so it can be placed anywhere
 * and not just as a fullpage indicator.
 *
 * @returns {function} Component
 */
function LoadingIndicator({ className, ...rest }) {
  const classes = classNames('LoadingIndicator', className)

  return (
    <div className={classes} {...rest}>
      <div>
        <BookshelfLogo />
      </div>
    </div>
  )
}

LoadingIndicator.defaultProps = {
  className: '',
}

LoadingIndicator.propTypes = {
  /** Additional className. */
  className: PropTypes.string,
}

export default LoadingIndicator
